



























import Vue from 'vue';
import UIRuleSelectorModalIcon from './icon/UIRuleSelectorModalIcon.vue';

/**
 * The RuleSelectorModal is a generic modal that can be used to show any sort of content. It has a sidebar slot that can be used to add a grey sidebar to the left of the content.
 */
const UIRuleSelectorModal = Vue.extend({
  name: 'UIRuleSelectorModal',
  components: {
    UIRuleSelectorModalIcon
  },
  props: {
    icon: {
      type: String,
      default: ''
    }
  },
  computed: {
    titleId(): string {
      return `title-${this._uid}`;
    }
  },
  methods: {
    dismiss() {
      // TODO: type this any better
      (this as any).$hideModal();
    }
  }
});

export default UIRuleSelectorModal;
