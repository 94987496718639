import formatNumber from './format-number';

export default (number: number) => {
  const hundred = parseFloat(`${number * 100}`).toFixed(1);
  const str = hundred.toString();
  const split = str.split('.');
  if (split[1] === '0') {
    const num = parseInt(split[0], 10);
    const formatted = formatNumber(Math.abs(num));

    if (number < 0) {
      return `-${formatted}%`;
    }

    return `${formatted}%`;
  }

  const formatted = formatNumber(Math.abs(parseInt(hundred, 10)));
  if (number < 0) {
    return `-${formatted}%`;
  }

  return `${formatted}%`;
};
