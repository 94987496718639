/**
 * Creates a enum of strings
 */
export class Enum<I extends string, T extends readonly [...I[]]> {
  public constructor(public readonly values: T) {}

  /**
   * Verifies if the provided value is part of the enum
   */
  has(value: string): value is T[number] {
    return this.values.includes(value as any);
  }
}
