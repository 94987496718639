import _ from 'lodash';
import axios from 'axios';
import { ActionContext } from 'vuex';
import ilyAxios from '../../../sdk/request';

/**
 * Fetches "all" list
 * @param {Store} store
 * @param {any[]} payload
 */
export const fetchAllList = (
  store: ActionContext<any, any>,
  // TODO: what should this be??
  payload: any[]
) => {
  const endpointKey = 'possible_values.api_endpoint';

  return new Promise((resolve, reject) => {
    const inputs = _.chain(payload)
      .map((p) => p.inputs)
      .flatten()
      .filter(
        (p) =>
          _.get(p, endpointKey, '') &&
          _.get(p, 'possible_values.type', '') !== 'autosuggest'
      )
      .uniqBy((p) => _.get(p, endpointKey))
      .map((p) => {
        const apiEndpoint = _.get(p, endpointKey);
        const endpoint = /https:\/\//i.test(apiEndpoint)
          ? apiEndpoint
          : `${process.env.API_BASE}${apiEndpoint}`;
        return ilyAxios
          .get(endpoint)
          .then((response) => {
            const { data } = response;

            store.commit('cacheList', {
              key: apiEndpoint,
              list: data
            });

            return data;
          })
          .catch((e) => e);
      })
      .value();

    Promise.all(inputs)
      .then((x) => resolve(x))
      .catch(() => reject());
  });
};

/**
 * Fetches "autosuggest" list
 * @param {Store} store
 * @param {object} payload
 */
export const fetchAutosuggestList = (
  store: ActionContext<any, any>,
  // TODO: what should this be??
  payload: { [key: string]: any }
) =>
  new Promise((resolve, reject) => {
    const endpoint = _.get(payload, 'endpoint', '');
    axios
      .post(
        endpoint,
        {
          _source: [
            'id',
            'name',
            'primary_service_category',
            'report.spend',
            'cid',
            'provider',
            'suggest',
            'num_products',
            'counts.atomic_customers',
            'revenue'
          ],
          suggest: {
            suggest: {
              prefix: payload.prefix,
              completion: {
                field: 'suggest',
                size: 10,
                contexts: {
                  company_type: ['display_as_product']
                }
              }
            }
          }
        },
        {
          headers: {
            'Content-Type': 'text/plain;charset=UTF-8'
          }
        }
      )
      .then((response) => {
        const list = _.get(response, 'data.suggest.suggest[0].options', []);
        const result = _.chain(list)
          .map((item) => ({
            label: _.get(item, '_source.name', ''),
            value: _.get(item, '_id', '')
          }))
          .value();

        resolve(result);

        return null;
      })
      .catch((error) => {
        reject(error);
      });
  });

/**
 * Fetches a list
 * @param {Store} store
 * @param {object} payload
 */
export const fetchList = (
  store: ActionContext<any, any>,
  // TODO: what should this be??
  payload: { [key: string]: any }
) =>
  new Promise<any>((resolve, reject) => {
    const endpoint = _.get(payload, 'possible_values.api_endpoint', '');

    if (payload.possible_values.type === 'autosuggest') {
      return axios
        .post(
          endpoint,
          {
            _source: [
              'id',
              'name',
              'primary_service_category',
              'report.spend',
              'cid',
              'provider',
              'suggest',
              'num_products',
              'counts.atomic_customers',
              'revenue'
            ],
            suggest: {
              suggest: {
                prefix: payload.prefix,
                completion: {
                  field: 'suggest',
                  size: 10,
                  contexts: {
                    company_type: ['display_as_product']
                  }
                }
              }
            }
          },
          {
            headers: {
              'Content-Type': 'text/plain;charset=UTF-8'
            }
          }
        )
        .then((response) => {
          const list = _.get(response, 'data.suggest.suggest[0].options', []);
          const result = _.chain(list)
            .map((item) => ({
              label: _.get(item, '_source.name', ''),
              value: _.get(item, '_id', '')
            }))
            .value();

          resolve(result);

          return null;
        })
        .catch(reject);
    }

    const queryKey = payload.query_block_key || payload.template_key;

    if (Boolean(queryKey) && Boolean(endpoint)) {
      if (Boolean(store.state.listCache[endpoint]))
        return resolve(store.state.listCache[endpoint]);

      ilyAxios
        .get(endpoint)
        .then((response) => {
          store.commit('cacheList', {
            key: endpoint,
            list: response.data
          });

          resolve(response.data);

          return null;
        })
        .catch(reject);
    }

    return undefined;
  });
