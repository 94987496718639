import { request, convertQueriesToString } from '../request';

// Originaly generated by:
// - https://www.npmjs.com/package/openapi-client-axios#generating-type-files-dts
// With the open api spec:
// https://bitbucket.org/cloudrupt/openapi-specs/raw/master/user_administration.yml
import { Components } from '../../../typings/openapi/user_administration';

// ----------------------------------------
// Variables

// ----------------------------------------
// Functions

/**
 * Gets a list of users from a team
 */
export const getUsersList = (
  query?: { sort?: Components.Parameters.UserSortParam.Sort },
  queryFilter?: Components.Schemas.UserFilters,
  pageFilter?: { number?: number; limit?: number }
) => {
  return request<Components.Schemas.GetUsers>({
    url: `/explorer/users?${convertQueriesToString(
      query,
      queryFilter,
      pageFilter
    )}`,
    method: 'GET'
  }).then((response) => response.data);
};

/**
 * Retrieves the user
 */
export const getUser = (params: { userId: string }) => {
  return request<{
    meta: { [key: string]: any };
    type: string;
    user: {
      account: {
        access: boolean;
        status: any;
        entitlements: any[];
        ce_view_id: any;
      };
      billing_plan: any;
      billing_subscription: any;
      completed_onboarding: boolean;
      created_at: number;
      customer: {
        id: any;
        slug: any;
        name: any;
        logo: any;
        qualified_account: any;
        [key: string]: any;
      };
      email: string;
      email_verification: {
        id: number;
        user_id: number;
        email: string;
        status: string;
        verification_hash: string;
        [key: string]: any;
      };
      first_name: string;
      has_previous_subscriptions: boolean;
      id: number;
      intercom_user_hash: string;
      last_name: string;
      linkedin_picture_url: string;
      linkedin_url: any;
      login_type: string;
      message: any;
      name: string;
      plan_id: any;
      plan_name: any;
      preferences: {
        url_download_remaining: number;
        smart_list_downloads_remaining: number;
        service_order: { [key: string]: any };
        preferred_services: any[];
        employer: string;
        [key: string]: any;
      };
      team_id: any;
      trial: boolean;
      trial_days_remaining: any;
      user_role: any;
      user_type: string;
      valid_email: boolean;
    };
  }>({
    url: `/users/${params.userId}`,
    method: 'GET'
  }).then((response) => response.data);
};
