import * as t from 'io-ts';
import Model from '../../classes/model';

/**
 * Gets the schema of a json API response or request body of the corresponding type and model.
 *
 */
export function jsonApiBody<
  T extends 'array' | 'object',
  M extends Model,
  I extends [t.Mixed] | [t.Mixed, t.Mixed, ...t.Mixed[]],
  MT extends t.Mixed
>(options: { type: T; model: M; included?: I; meta?: MT }) {
  const base = options.model.base as M['base'];
  const dataSchema = {
    /**
     * Resources or list of resources returned by the endpoint.
     */
    data: (options.type === 'array' ? t.array(base) : base) as T extends 'array'
      ? t.ArrayC<typeof base>
      : typeof base
  };

  const metaSchema = {
    meta: (options.meta || t.any) as MT
  };

  const included = (options.included || [t.any]) as I;

  const includedSchema = {
    included: t.array(
      included.length > 1
        ? t.union(options.included as any)
        : included[0]
        ? included[0]
        : t.any
    ) as I extends [infer A]
      ? A extends t.Mixed
        ? t.ArrayC<A>
        : never
      : I extends [infer A, infer B]
      ? A extends t.Mixed
        ? B extends t.Mixed
          ? t.ArrayC<t.UnionC<[A, B]>>
          : never
        : never
      : I extends [infer A, infer B, ...(infer C)[]]
      ? A extends t.Mixed
        ? B extends t.Mixed
          ? C extends t.Mixed
            ? t.ArrayC<t.UnionC<[A, B, ...C[]]>>
            : never
          : never
        : never
      : never
  };

  return new Model(
    t.intersection([
      t.interface(dataSchema),
      t.partial(includedSchema),
      t.partial(metaSchema)
    ])
  );
}
