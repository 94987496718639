

































































































































































































import Vue from 'vue';
import { tokenify } from '../../sdk/request';
import { UIInlineSearchInput, UITablePagination } from '@intricately/ui-lib';
import UIModalFrame from '../UIModalFrame.vue';
import TheReportRequestForm from './TheReportRequestForm.vue';
import UIEmptyIcon from '../UIEmptyIcon.vue';
import UITextInput from '../UITextInput.vue';
import UISelectDropdown from '../UISelectDropdown.vue';
import UIBarLoader from '../UIBarLoader.vue';
import { fetchReports } from '../../store/modules/reports/actions';
import { UsersState } from '../../store/modules/users/state';
import { ReportsState } from '../../store/modules/reports/state';
import formatNumber from '../../filters/format-number';
import formatDate from '../../filters/format-date';
import { downloadFile } from '../../utils/file';
import { storeApi } from '../../store/index';

export default Vue.extend({
  components: {
    UIModalFrame,
    UISelectDropdown,
    UITextInput,
    UIEmptyIcon,
    UIBarLoader,
    UIInlineSearchInput: UIInlineSearchInput as any,
    UITablePagination: UITablePagination as any,
    TheReportRequestForm
  },
  filters: {
    formatNumber,
    formatDate
  },

  data() {
    return {
      isHistoryLoading: false,
      pageLimit: 5,
      currentPage: 1,
      totalPages: 1,
      cachedTotalEntries: 0,
      searchKey: '',
      itemEditId: '' as string | number,
      itemEditName: '',
      filterBySelection: '',
      debounceTime: null as any
    };
  },

  computed: {
    reportCounter(): string {
      if (
        this.cachedTotalEntries == null ||
        this.cachedTotalEntries === 0 ||
        this.isHistoryLoading
      ) {
        return '';
      }

      if (this.searchKey != null && this.searchKey.length > 0) {
        return this.cachedTotalEntries === 1
          ? '1 report found'
          : `${this.totalEntries} reports found`;
      }

      if (this.cachedTotalEntries === 1) {
        return 'Displaying 1 report';
      }

      return `Displaying all ${this.totalEntries} reports`;
    },
    authUserId(): string {
      return storeApi.appSettings.getters.authUserId;
    },
    totalEntries(): string {
      return formatNumber(
        this.cachedTotalEntries == null ? 0 : this.cachedTotalEntries
      );
    },
    reportsList(): ReportsState['reportsList'] {
      const list: ReportsState['reportsList'] =
        storeApi.reports.getters.reportsList;
      const limit = list.length > this.pageLimit ? this.pageLimit : list.length;
      return list.slice(0, limit);
    },
    filterByOptions(): {
      value: string;
      label: string;
      sticky?: boolean;
      checked: boolean;
    }[] {
      const users: UsersState['usersList'] = storeApi.users.getters.usersList;

      return [
        {
          value: 'all-users',
          label: 'All users',
          checked: this.filterBySelection === 'all-users',
          sticky: true
        }
      ].concat(
        users.map((user) => {
          return {
            value: `${user.id == null ? '' : user.id}`,
            label: user.name as string,
            checked:
              `${this.filterBySelection}` ===
              `${user.id == null ? '' : user.id}`,
            sticky: false
          };
        })
      );
    }
  },
  mounted() {
    this.loadUsersList();

    // We want to default it to be the auth user
    if (this.filterBySelection == null || this.filterBySelection.length === 0) {
      this.filterBySelection = `${this.authUserId}`;
    }

    this.loadReports();
  },
  methods: {
    tokenify(url: string): string {
      return tokenify(url);
    },
    getReportProgress(prog: number): string {
      let p = Math.ceil(prog);
      p = p > 99 ? 99 : p;
      return `${p}%`;
    },
    getReportType(id: number | string): string {
      const sets: ReportsState['reportFieldSets'] =
        storeApi.reports.getters.reportFieldSets;
      const rawS = sets.find((s) => `${s.id}` === `${id}`);
      return rawS == null ? '' : rawS.name;
    },
    onSelectPage(page: number): void {
      this.currentPage = page;
      this.loadReports();
    },
    onDownload(evt: any, url: string) {
      evt.preventDefault();
      evt.stopPropagation();

      return downloadFile(url, 'report');
    },
    onEditReport(id: string | number): void {
      this.itemEditId = id;

      const item = this.reportsList.find((r) => r.id === id);
      if (item == null) {
        return;
      }

      this.itemEditName =
        item.attributes.name == null ? '' : item.attributes.name;
    },
    onReportNameChange() {
      const itemId = this.itemEditId;
      const newItemName = this.itemEditName;

      this.itemEditId = '';
      this.itemEditName = '';

      if (newItemName == null || newItemName.length === 0) {
        return;
      }

      storeApi.reports.actions
        .updateReport({
          reportId: itemId + '',
          name: newItemName
        })
        .then(() => {
          // We need to force the history list to update with the new "name"
          this.$forceUpdate();
          return '';
        })
        .catch(() => {
          this.$toast('There was an error updating the report name', 'error');

          return '';
        });
    },
    onFilterBy(
      data: { value: string; label: string; checked: boolean }[]
    ): void {
      if (data == null || data[0] == null) {
        return;
      }

      this.filterBySelection = data[0].value;
      this.loadReports();
    },
    getUserName(userId: string | number): string {
      const users: UsersState['usersList'] = storeApi.users.getters.usersList;
      const res = users.find((u) => u.id != null && `${u.id}` === `${userId}`);

      if (res != null && res.name != null) {
        return res.name as string;
      }

      return `${userId}`;
    },
    loadReports(): void {
      if (this.debounceTime != null) {
        clearTimeout(this.debounceTime);
      }

      this.isHistoryLoading = true;

      this.debounceTime = setTimeout(() => {
        const filterBy =
          this.filterBySelection === 'all-users'
            ? undefined
            : this.filterBySelection;
        const limit = this.pageLimit;

        (storeApi.reports.actions.fetchReports({
          userId: filterBy,
          search: this.searchKey,
          pageNumber: this.currentPage,
          pageLimit: limit
        }) as ReturnType<typeof fetchReports>)
          .then((data) => {
            this.isHistoryLoading = false;

            let count = data.meta != null ? data.meta.count : 1;
            if (data.meta != null) {
              count =
                data.meta.total_entries != null
                  ? data.meta.total_entries
                  : data.meta.count;
            }
            this.cachedTotalEntries = count == null ? 0 : count;

            this.totalPages =
              count == null || count === 0
                ? 1
                : Math.ceil((count as number) / limit);
            return data;
          })
          .catch(() => {
            this.isHistoryLoading = false;

            this.$toast('There was an error requesting your reports', 'error');

            return null;
          });
      }, 250);
    },
    loadUsersList(): void {
      storeApi.users.actions.fetchUsersList().catch(() => {
        this.$toast('There was an error requesting your user list', 'error');
        return null;
      });
    }
  }
});
