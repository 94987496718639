import { Rule } from '../../../API/models/rule/rule';
import { RuleCategory } from '../../../API/models/ruleCategory/ruleCategory';
import { IOldRule } from './actions';

export const getDefaultState = () => ({
  templates: [] as IOldRule[],
  templatesAreReady: false,
  categories: [] as RuleCategory[],
  rules: [] as Rule[]
});

export type QueryTemplatesState = ReturnType<typeof getDefaultState>;
