import { State } from './state';

export const isProduction = (state: State) => state.isProduction;
export const isReqProduction = (state: State) => state.isReqProduction;

export const isLoading = (state: State) => state.isLoading;
export const fillRates = (state: State) => state.fillRates;
export const lastQueryOrAggAddedId = (state: State) =>
  state.lastQueryOrAggAddedId;
export const fetchedStateId = (state: State) => state.fetchedStateId;
export const oldState = (state: State) => state.oldState;
export const id = (state: State) => state.id;
