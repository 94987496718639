




















































































import _ from 'lodash';
import draggable from 'vuedraggable';
import { EsState } from '../../store/modules/state/es/state';
import { UITooltip } from '@intricately/ui-lib';
import formatNumber from '../../filters/format-number';
import { copyToClipboard } from '../../utils/clipboard';
import TheFeatureRangesManager from './TheFeatureRangesManager.vue';
import TheFeatureCardView from './TheFeatureCardView.vue';
import Vue from 'vue';
import { storeApi } from '../../store/index';
import Logger from '../../logger/Logger.class';

const { prodError } = new Logger('TheFeatureChain');

type Feature = EsState['aggsUi'][0] & {
  isEdit?: boolean;
  isBeingFiltered?: boolean;
  // TODO: need to check this any better
  changes?: any;
};

const TheFeatureChain = Vue.extend({
  name: 'TheFeatureChain',
  components: {
    draggable,
    UITooltip,
    TheFeatureCardView,
    TheFeatureRangesManager
  },
  filters: {
    handleDecimals(num): number {
      return num | 0;
    }
  },
  data() {
    return {
      parsedFeatures: [] as Feature[]
    };
  },
  computed: {
    features(): Feature[] {
      const featuresUi = storeApi.state.es.state.aggsUi;
      return featuresUi || [];
    },
    hitsTotal(): number | undefined {
      const { total } = storeApi.state.es.getters.hits;
      return total;
    }
  },
  watch: {
    features: {
      deep: true,
      handler() {
        this.updateFeatures();
      }
    }
  },
  mounted() {
    this.updateFeatures();
  },
  methods: {
    updateFeatures() {
      this.parsedFeatures = this.features.map((feature) => {
        // Check if the old feature has extra data
        const found = this.parsedFeatures.find(
          (parsedFeature) => parsedFeature.identity === feature.identity
        );

        if (!found) {
          return feature;
        }

        feature.isEdit = found.isEdit;
        feature.changes = found.changes;

        return feature;
      });
    },
    bucketsTotal(feature: Feature): number {
      const { hitsTotal } = this as any;
      const buckets = feature.buckets || [];
      if (!buckets.length) {
        return 0;
      }

      const total = buckets.reduce(
        (acc, bucket) => (acc += bucket.count || 0),
        0
      );

      return total >= hitsTotal ? 100 : (total * 100) / hitsTotal;
    },
    onBucketsCopy(feature: Feature) {
      const buckets = feature.buckets || [];

      const docCount = buckets.map((bucket) => {
        const count = bucket.doc_count || bucket.count;
        return formatNumber(count || 0);
      });

      copyToClipboard(docCount.join('\t'), () =>
        this.$toast('Copied to clipboard', 'success', { timeout: 1000 })
      );
    },
    onEdit(feature: Feature) {
      if (feature == null) {
        return;
      }

      feature.isEdit = true;
      feature.changes = null;

      this.$forceUpdate();
    },

    onEditCancel(feature: Feature) {
      if (feature == null) {
        return;
      }

      feature.isEdit = false;
      feature.changes = null;

      this.$forceUpdate();
    },

    onEditSave(feature: Feature) {
      feature.isEdit = false;

      // Check if there are changes
      if (feature.changes != null) {
        storeApi.state.es.actions
          .updateRanges({
            identity: feature.identity,
            agg: _.assign({}, feature, {
              segments: feature.changes
            })
          })
          .catch(prodError);
      }

      feature.changes = null;

      this.$forceUpdate();
    },

    onEditUpdate(evt: any, feature: Feature) {
      const found = this.parsedFeatures.find(
        (parsedFeature) =>
          parsedFeature.identity === feature.identity && parsedFeature.isEdit
      );

      if (found) {
        found.changes = evt;
      }
    },

    onFeatureRemove(feature: Feature) {
      const featureIdentity = feature.identity;
      const disabled = feature.isBeingFiltered;

      if (!disabled) {
        storeApi.state.es.actions
          .removeAggs({
            agg: featureIdentity
          })
          .catch(prodError);

        // Check if there is an active bucket, we want to remove its query
        const p = (feature.buckets == null ? [] : feature.buckets).map(
          (bucket) => {
            if (bucket.queryIdentityActive == null) {
              return Promise.resolve();
            }

            return storeApi.state.es.actions.removeQuery({
              identity: bucket.queryIdentityActive
            });
          }
        );

        Promise.all(p).catch(prodError);
      }
    },

    /**
     * Handles the order change
     */
    onOrderChange(evt: any) {
      // We only want the move event
      if (evt == null || evt.moved == null) {
        return;
      }

      const aggs = this.parsedFeatures.map((agg) => agg.identity);
      storeApi.state.es.actions
        .updateAggsOrder({ order: aggs })
        .catch(prodError);
    },
    pluralize(word: string, count: number): string {
      return count === 1 ? word : word + 's';
    }
  }
});

export default TheFeatureChain;
