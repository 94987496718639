import { Components } from '../../../../typings/openapi/explorer_download_history';

export type ReportsState = {
  reportsList: Components.Schemas.Report[];
  userEnrichments: Components.Schemas.Enrichment[];
  reportFieldSets: { id: number; name: string }[];
};

export const getDefaultState = (): ReportsState => ({
  reportsList: [],
  userEnrichments: [],
  reportFieldSets: []
});
