import { IUser } from '../users/state';

export type AppSettingsState = {
  // TODO: should this be?
  settings: any[];
  loading: boolean;
  settingsAreReady: boolean;
  authUserId: string;
  authUser: IUser;
};

export const getDefaultState = (): AppSettingsState => ({
  settings: [],
  loading: true,
  settingsAreReady: false,
  authUserId: '',
  authUser: null as any
});
