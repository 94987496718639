






































import { UITooltip, UIHelpIcon } from '@intricately/ui-lib';
import TheFeatureSelector from './TheFeaturesSelector.vue';
import TheFeatureChain from './TheFeatureChain.vue';
import Vue from 'vue';
import { storeApi } from '../../store/index';

const TheMarketSegmenter = Vue.extend({
  components: {
    UITooltip,
    UIHelpIcon,
    TheFeatureChain
  },
  computed: {
    title(): string {
      const title = storeApi.appSettings.getters.marketSegmenterTitle;
      return title == null || title.length === 0
        ? 'Market Segmentation'
        : title;
    },
    textTooltip(): string {
      return storeApi.appSettings.getters.marketSegmenterTooltip;
    },
    showLoader(): boolean {
      return storeApi.state.getters.isLoading;
    }
  },
  methods: {
    showFeatures() {
      this.$showModal(TheFeatureSelector, {
        parentId: null,
        addToTop: true
      });
    }
  }
});

export default TheMarketSegmenter;
