

















export default {
  data() {
    return {
      showing: false,
      hiding: true,
      slidingIn: false,
      slidingOut: false,
      properties: {},
      componentMethods: {},
      component: null
    };
  },
  methods: {
    show(component, properties = {}, componentMethods = {}) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this as any;

      if (component) {
        vm.properties = properties;
        vm.componentMethods = componentMethods;
        vm.component = component;
        vm.slidingIn = true;
        vm.slidingOut = false;
        setTimeout(() => {
          vm.slidingIn = false;
          vm.showing = true;
          vm.hiding = false;
        }, 300);
      }
    },
    hide() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this as any;

      vm.slidingIn = false;
      vm.slidingOut = true;
      setTimeout(() => {
        vm.slidingOut = false;
        vm.showing = false;
        vm.hiding = true;
        vm.properties = {};
        vm.componentMethods = {};
        vm.component = null;
      }, 300);
    }
  }
};
