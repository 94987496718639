import { rulesetPostRequestBodySchema as post } from './ruleset.post';
import { jsonApiResource } from '../../jsonApi/models/resource';
import Model from '../../classes/model';
import * as t from 'io-ts';
import { ResourceType } from '../../clients/resourceTypes.enum';

/**
 * Schema of the payload of a patch request for the ruleset resource.
 */
export const rulesetsPatchBodySchema = jsonApiResource({
  type: ResourceType.explorer_rule_set,
  attributes: post.attributes
});

/**
 * Model of the payload of a patch request for the ruleset resource.
 */
export const rulesetsPathBodyModel = new Model(
  t.interface(rulesetsPatchBodySchema)
);
