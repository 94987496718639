import { isEmpty } from 'lodash';
import nullifyValues from './nullify-values';
import {
  IOldRule,
  IParsedOldRule
} from '../../modules/query-templates/actions';
import {
  FeatureTemplate,
  Input,
  QueryEsTemplate,
  QueryRawTree,
  FeatureRawTree
} from './types';

export default class TemplatesManager {
  private myQueries: IOldRule[] = [];
  private myFeatures: FeatureTemplate[] = [];

  /**
   * Initializes the templates manager
   */
  public constructor({
    queries = [] as IOldRule[],
    features = [] as FeatureTemplate[]
  }) {
    this.myQueries = queries
      .map((q) => this.cloneQuery(q))
      .filter((q) => q != null) as IOldRule[];
    this.myFeatures = features
      .map((f) => this.cloneFeature(f))
      .filter((f) => f != null) as FeatureTemplate[];
  }

  /**
   * Gets query inputs from id
   * @param {string} id
   * @returns {Input[]}
   */
  private getQueryInputs(id: string): Input[] | null | undefined {
    const res = this.myQueries.find((q) => q.id === id);
    return res == null ? null : res.inputs;
  }

  /**
   * Gets a new random identity
   */
  private getRandomIdentity() {
    return `_${Math.random()
      .toString(36)
      .substr(2, 9)}`;
  }

  /**
   * Gets a query template by id
   */
  private getQueryEsTemplate(id: string): QueryEsTemplate | undefined | null {
    const query = this.myQueries.find((q) => q.id === id);
    return query != null ? query.elasticsearch_template : {};
  }

  /**
   * Clones a query template so there are no changes by reference
   */
  private cloneQuery(
    query: IParsedOldRule,
    maintainId = false
  ): IParsedOldRule | null {
    if (query == null) {
      return query;
    }

    return {
      ...query,
      identity: maintainId ? query.identity : this.getRandomIdentity()
    };
  }

  /**
   * Clones a feature template so there are no changes by reference
   */
  private cloneFeature(
    feature: FeatureTemplate,
    maintainId = false
  ): FeatureTemplate | null {
    if (feature == null) {
      return feature;
    }

    const listBasedQueriesId = this.myQueries
      .filter((q) => q.inputs && q.inputs[0].type === 'multi-select')
      .map((q) => q.id);

    const listBased =
      listBasedQueriesId.indexOf(feature.query_template_id) > -1;
    const queryInputs =
      listBasedQueriesId.indexOf(feature.query_template_id) > -1
        ? this.getQueryInputs(feature.query_template_id)
        : [];

    const tmpl = this.getQueryEsTemplate(feature.query_template_id);
    const arrQueryInputs = queryInputs == null ? [] : queryInputs;

    return {
      ...feature,
      default_segments: (feature.default_segments == null
        ? []
        : feature.default_segments
      ).map((seg) => {
        // We want to clone the segment so that the basic isn't changed as a reference
        return {
          ...seg
        };
      }),
      list_based: listBased,
      query_inputs: [...arrQueryInputs],
      elasticsearch_template: { ...tmpl },
      identity: maintainId ? feature.identity : this.getRandomIdentity()
    };
  }

  /**
   * Gets the template by identity or id
   */
  private getTemplateByRawId(
    type: 'query' | 'aggs' | '' = '',
    id = ''
  ): FeatureTemplate | IParsedOldRule | null | undefined {
    const isQuery = type === 'query';
    const arr = isQuery ? this.myQueries : this.myFeatures;
    const arrToCheck: (FeatureTemplate | IOldRule)[] = arr == null ? [] : arr;

    const res = arrToCheck.find((val: FeatureTemplate | IOldRule) => {
      if (val.id != null && id.length > 0 && val.id === id) {
        return true;
      }

      if (!('elasticsearch_aggs_block' in val)) return false;

      if (!val.elasticsearch_aggs_block) {
        return false;
      }

      const keys = Object.keys(val.elasticsearch_aggs_block);
      const found = keys.filter((key) => key === id);

      return found.length > 0;
    });

    return isQuery
      ? this.cloneQuery(res as IParsedOldRule, true)
      : this.cloneFeature(res as FeatureTemplate, true);
  }

  /**
   * Gets the template by identity or id
   * @param {string} type
   * @param {object} rawTemplate
   * @returns {Template|undefined}
   */
  private getTemplateByRawObject(
    type: 'query' | 'aggs' | '' = '',
    // TODO: need to type this
    rawTemplate: QueryRawTree | FeatureRawTree = {}
  ): IOldRule | FeatureTemplate | null | undefined {
    const safeKeys = ['field'];
    const removeKeys = ['gte', 'lt'];

    const isQuery = type === 'query';
    const arr = isQuery ? this.myQueries : this.myFeatures;
    const arrToCheck: (FeatureTemplate | IOldRule)[] = arr == null ? [] : arr;

    /*
    FROM UI:
      range: Object
        record_density_score: Object
          gte: "min"
          lt: "max"

    FROM BACKEND:
      bool: Object
        must: Array(1)
          0:
            bool: Object
              must: Array(1)
                0:
                  range: Object
                    record_density_score: Object
                      gte: 3
                      lt: 7

    FROM TEMPLATE: (1)
      range: Object
        record_density_score: Object
          gte: "min"
          lt: "max"
    */

    const rawTmpl = nullifyValues(rawTemplate, safeKeys, removeKeys);
    const rawTmplStr = JSON.stringify(rawTmpl).replace(/ /g, '');

    const res = arrToCheck.find((val) => {
      let baseTmpl = val.elasticsearch_template;
      let tmpl = nullifyValues(baseTmpl, safeKeys, removeKeys);
      let tmplStr = JSON.stringify(tmpl).replace(/ /g, '');

      if (tmplStr === rawTmplStr) {
        return true;
      }

      if (baseTmpl != null && baseTmpl.nested != null) {
        baseTmpl = baseTmpl.nested;
        tmpl = nullifyValues(baseTmpl, safeKeys, removeKeys);
        tmplStr = JSON.stringify(tmpl).replace(/ /g, '');

        if (tmplStr === rawTmplStr) {
          return true;
        }
      }

      if (baseTmpl != null && baseTmpl.query != null) {
        baseTmpl = baseTmpl.query;
      }

      tmpl = nullifyValues(baseTmpl, safeKeys, removeKeys);
      tmplStr = JSON.stringify(tmpl).replace(/ /g, '');

      return tmplStr === rawTmplStr;
    });

    return isQuery
      ? this.cloneQuery(res as IParsedOldRule, true)
      : this.cloneFeature(res as FeatureTemplate, true);
  }

  /**
   * Gets the template by identity or id
   */
  private getTemplate(
    type: 'query' | 'aggs' | '' = '',
    identity = ''
  ): IParsedOldRule | FeatureTemplate | null | undefined {
    const isQuery = type === 'query';
    const arr = isQuery ? this.myQueries : this.myFeatures;
    const arrToCheck: (FeatureTemplate | IParsedOldRule)[] =
      arr == null ? [] : arr;

    const res = arrToCheck.find((val) => {
      if (identity != null && identity.length > 0) {
        return val.identity === identity;
      }

      return false;
    });

    return isQuery
      ? this.cloneQuery(res as IParsedOldRule, true)
      : this.cloneFeature(res as FeatureTemplate, true);
  }

  /**
   * Get a template / templates
   */
  public get(
    type: 'query',
    params: { identity?: string; templateId?: string }
  ): IParsedOldRule;
  public get(
    type: 'aggs',
    params: { identity?: string; templateId?: string }
  ): FeatureTemplate;
  public get(
    type: 'query',
    params: { rawTemplate: QueryRawTree }
  ): IParsedOldRule;
  public get(
    type: 'aggs',
    params: { rawTemplate: FeatureRawTree }
  ): FeatureTemplate;
  public get(type: 'query', params?: Record<string, any>): IParsedOldRule[];
  public get(type: 'aggs', params?: Record<string, any>): FeatureTemplate[];
  public get(
    type: 'query' | 'aggs' | '' = '',
    params: {
      templateId?: string;
      identity?: string;
      rawTemplate?: QueryRawTree | FeatureRawTree;
    } = {}
  ) {
    if (isEmpty(params)) {
      if (type === 'query') {
        return this.myQueries;
      }
      if (type === 'aggs') {
        return this.myFeatures;
      }
    } else if (params.identity != null && params.identity.length > 0) {
      return this.getTemplate(type, params.identity);
    }

    if (params.templateId != null && params.templateId.length > 0) {
      return this.getTemplateByRawId(type, params.templateId);
    }

    if (params.rawTemplate) {
      return this.getTemplateByRawObject(type, params.rawTemplate);
    }

    return null;
  }

  /**
   * Update an update
   */
  public update() {
    // TODO: ...
  }
}
