/**
 * Apply the include param to the path search following the JSON API specs.
 */
export function applyIncluded(
  params: URLSearchParams,
  include?: (string | number | symbol)[]
) {
  if (include) {
    params.set('include', include.join(','));
  }
}
