import * as t from 'io-ts';
import Model from '../../classes/model';
import { ModelInterface } from '../interfaceGetters/modelInterface';
import { queryBlockModel, IQueryBlock } from './queryBlock';
import { ruleSetTemplateModel } from './ruleSetTemplate';

export interface IQueryBlockGroup {
  id?: string;
  type: 'query-block-group';
  /**
   * List of query blocks and query blocks groups (that may represent a rule set)
   */
  children?: (ModelInterface<typeof queryBlockModel> | IQueryBlockGroup)[];
  /**
   * Logical operator that describes the relationship between the children.
   */
  operator?: 'must' | 'should' | 'must_not';
  /**
   * Attributes of a saved rule set related to this block.
   */
  template?: ModelInterface<typeof ruleSetTemplateModel>;
}

/**
 * The query block group after being parsed by the front-end.
 */
export interface IParsedQueryBlockGroup extends IQueryBlockGroup {
  /**
   * Random generated id for the query block group.
   */
  identity?: string;
  /**
   * query blocks and groups that compose this group
   */
  children?: (IQueryBlock | IParsedQueryBlockGroup)[];
  /**
   * if this group is the main one at the root.
   */
  isRoot?: boolean;
}

export const queryBlockGroupBase: t.Type<IQueryBlockGroup> = t.recursion(
  'queryBlockGroupModel',
  () =>
    t.intersection([
      t.interface({
        type: t.literal('query-block-group')
      }),
      t.partial({
        children: t.array(t.union([queryBlockModel.base, queryBlockGroupBase])),
        operator: t.union([
          t.literal('must'),
          t.literal('must_not'),
          t.literal('should'),
          t.undefined
        ]),
        template: t.union([t.undefined, ruleSetTemplateModel.base])
      })
    ])
);

/**
 * Object that describes the UI for a query block group.
 *
 * if a template is present it will describe a saved rule set.
 */
export const queryBlockGroupModel = new Model(queryBlockGroupBase);
