import Axios, { AxiosError } from 'axios';
import Route from './Route.class';
import { ResourceConfig, RequestMethodType } from './ResourceConfig';
import Logger from '../../logger/Logger.class';
import { ModelInterface } from '../models/interfaceGetters/modelInterface';
import Cookie from 'js-cookie';

const { dev } = new Logger('API');

/**
 * Describes a api resource object.
 * Because of the type acquisition all routes are required.
 *
 */
type ApiResources<
  GET extends Route = any,
  POST extends Route = any,
  PUT extends Route = any,
  PATCH extends Route = any,
  DELETE extends Route = any
> = {
  /**
   * Name of the resource to got from the API.
   *
   * Use the ResourceConfig class to set it up.
   */
  [key: string]: ResourceConfig<GET, POST, PUT, PATCH, DELETE>;
};

interface IRequestConfig<
  T extends ApiResources = any,
  REQUEST extends keyof T = any,
  METHOD extends RequestMethodType = any
> {
  /**
   * Type of the request.
   * @example
   *  post, get, etc...
   */
  method: METHOD;
  /**
   * Name of the resource to get
   * @example
   *  'companies'
   */
  resource: REQUEST;
  /**
   * Options requested by the endpoint to construct the path and payload
   */
  options: T[REQUEST][METHOD]['options'];
}

/**
 * Creates a fully typed api client using a configuration object.
 * each key inside the configuration object should be declared using the ResourceConfig class.
 * They will be converted to a API endpoint with request methods.
 *
 * you can configure how each ResourceConfig will make requests for each route with the Route class.
 *
 * Provide a object to the ResourceConfig class with the keys being the possible request methods (get, post, put, etc)
 * Each method is a new Route() with a lot of options to provide.
 */
export default class IntricatelyAPI<
  GET extends Route,
  POST extends Route,
  PUT extends Route,
  PATCH extends Route,
  DELETE extends Route,
  RESOURCE extends ApiResources<GET, POST, PUT, PATCH, DELETE>,
  RESOURCE_KEY extends keyof RESOURCE
> {
  public axios = Axios.create();

  public constructor(base: string, public resources: Readonly<RESOURCE>) {
    this.resources = resources;
    this.axios.defaults.baseURL = base;
    this.setupTokenGetter();
  }

  public setBase(base: string) {
    this.axios.defaults.baseURL = base;
  }

  private setupTokenGetter() {
    this.axios.interceptors.request.use((config) => {
      const sessionString = Cookie.get('session');

      if (sessionString) {
        const sessionJson = JSON.parse(sessionString);
        config.params = {
          ...config.params,
          token: sessionJson.token
        };
      }

      return config;
    });
  }

  /**
   * Get the api request path for given resource
   */
  private getRequestPath<M extends RequestMethodType>(
    config: IRequestConfig<RESOURCE, RESOURCE_KEY, M>
  ) {
    const { resource, method, options } = config;
    dev('Request path options: ', config);

    const resourceConfig = this.resources[resource];
    const routeConfig = this.resources[resource][method];

    dev({ resourceConfig, routeConfig });
    const basePath = resourceConfig.namespaced ? `/${resource}` : '/';
    const constructedPath = routeConfig.path?.(options);
    const requestPath = constructedPath || basePath;

    dev('Request path: ', requestPath);

    return requestPath;
  }

  public get = <M extends 'get', R extends RESOURCE_KEY>(
    resource: IRequestConfig<RESOURCE, R, M>['resource'],
    options: IRequestConfig<RESOURCE, R, M>['options']
  ) => this.request({ method: 'get', resource, options });

  public post = <M extends 'post', R extends RESOURCE_KEY>(
    resource: IRequestConfig<RESOURCE, R, M>['resource'],
    options: IRequestConfig<RESOURCE, R, M>['options']
  ) => this.request({ method: 'post', resource, options });

  public put = <M extends 'put', R extends RESOURCE_KEY>(
    resource: IRequestConfig<RESOURCE, R, M>['resource'],
    options: IRequestConfig<RESOURCE, R, M>['options']
  ) => this.request({ method: 'put', resource, options });

  public patch = <M extends 'patch', R extends RESOURCE_KEY>(
    resource: IRequestConfig<RESOURCE, R, M>['resource'],
    options: IRequestConfig<RESOURCE, R, M>['options']
  ) => this.request({ method: 'patch', resource, options });

  public delete = <M extends 'delete', R extends RESOURCE_KEY>(
    resource: IRequestConfig<RESOURCE, R, M>['resource'],
    options: IRequestConfig<RESOURCE, R, M>['options']
  ) => this.request({ method: 'delete', resource, options });

  /**
   * Request a resource from the api with provided method.
   */
  public async request<M extends RequestMethodType, R extends RESOURCE_KEY>(
    config: IRequestConfig<RESOURCE, R, M>
  ): Promise<ModelInterface<RESOURCE[R][M]['response']>> {
    const { method, options, resource } = config;

    dev(`making ${method} request for resource ${resource} with: `, {
      options
    });

    const routeConfig = this.resources[resource][method] as Route;
    const requestPath = this.getRequestPath(config);

    let payload = {} as any;

    if (routeConfig.payloadConstructor) {
      payload = routeConfig.payloadConstructor(options);
      dev('Created custom payload', payload);
    }

    if (routeConfig.request) {
      dev('validating payload...');
      routeConfig.request.validate(payload);
    }

    dev('making request', {
      url: requestPath,
      payload
    });

    const res = await (this.axios[method] as any)(requestPath, payload).catch(
      (e: AxiosError) => {
        throw e.response;
      }
    );

    dev('response received: ', res);

    dev('validating response...');
    if (routeConfig.response) {
      routeConfig.response.validate(res.data);
    }

    dev('returning');
    return res.data;
  }
}
