type PatternList = Record<
  string,
  {
    /**
     * What color to use in the message
     */
    color: string;
    /**
     * Which regex to test against
     */
    match: (string | RegExp)[];
  }
>;

class LoggerColorPatterns {
  public patterns: PatternList = {
    operationCanceled: {
      color: 'rgb(200, 100, 100)',
      match: [' not ', /ignoring\.\.\.$/]
    },
    operationStarting: {
      color: 'rgb(150, 0, 150)',
      match: ['Get']
    },
    warning: {
      color: 'yellow',
      match: [' yet']
    },
    info: {
      color: 'rgb(100, 100, 200)',
      match: ['got ', 'Setup ', 'Setting', ' activated', 'set to', 'detected']
    },
    success: {
      color: 'rgb(100, 200, 100)',
      match: ['success', 'Installed']
    }
  };

  public getColorFor = (message: string) => {
    const pattern = Object.keys(this.patterns).find((patternKey) => {
      const currentPattern = this.patterns[patternKey];

      try {
        return currentPattern.match.some((patternMatch) => {
          if (typeof patternMatch === 'string') {
            return message.includes(patternMatch);
          } else {
            return patternMatch.test(message);
          }
        });
      } catch (e) {
        return 'white';
      }
    });

    if (!pattern) {
      return 'white';
    }

    return this.patterns[pattern].color;
  };
}

export const loggerColors = new LoggerColorPatterns();
