import { ActionContext } from 'vuex';
import request, { tokenify } from '../../../sdk/request';
import { updateState } from './utils';
import { downloadFile } from '../../../utils/file';
import Logger from '../../../logger/Logger.class';
import { storeApi } from '../../index';
import { FillRate, State } from './state';

const { prodError } = new Logger('state-actions');

let loadingCount = 0;
let dontSaveNext: any = null;

/**
 * Sets a timer for not saving the next request
 * for a time
 */
const setDontSaveNext = (time: number) => {
  if (dontSaveNext != null) {
    clearTimeout(dontSaveNext);
  }
  dontSaveNext = setTimeout(
    () => {
      dontSaveNext = null;
    },
    time == null ? 1000 : time
  );
};

/**
 * Resets the state emptying it out to the defaults
 */
export const reset = async (store: ActionContext<any, any>): Promise<State> => {
  return storeApi.state.mutations.resetState();
};

/**
 * Sets the loading
 */
export const setLoading = async (
  store: ActionContext<any, any>,
  payload: { value: boolean } = { value: false }
) => {
  if (payload.value) {
    loadingCount += 1;
    storeApi.state.mutations.setLoading(true);
  } else {
    loadingCount -= 1;
    loadingCount = loadingCount < 0 ? 0 : loadingCount;

    if (loadingCount === 0) {
      storeApi.state.mutations.setLoading(false);
    }
  }
};

/**
 * Sets the "is production" state
 */
export const setIsProduction = async (
  _store: ActionContext<any, any>,
  payload: { value: boolean } = { value: false }
) => {
  storeApi.state.mutations.setIsProduction(payload.value);
};

/**
 * Sets the "is request production" state
 */
export const setIsReqProduction = async (
  _store: ActionContext<any, any>,
  payload: { value: boolean } = { value: false }
) => {
  storeApi.state.mutations.setIsReqProduction(payload.value);
  storeApi.state.es.actions.queryMarket().catch(prodError);
};

/**
 * Updates the id to be used
 */
export const updateId = async (
  store: ActionContext<any, any>,
  idToBe: string
) => {
  const { id } = store.getters;
  if (id === idToBe) {
    return null;
  }

  storeApi.state.mutations.resetState();
  storeApi.state.mutations.setStateId(idToBe);

  return null;
};

/**
 * Updates the last added query
 */
export const updateLastQueryOrAggAddedId = async (
  _store: ActionContext<any, any>,
  id: string
) => {
  storeApi.state.mutations.setLastQueryOrAggAddedId(id);
};

/**
 * Updates the fill rates
 */
export const updateFillRates = async (
  store: ActionContext<any, any>,
  // TODO: need to type this
  fillRates?: any[]
) => {
  const newFillRatesPayload = fillRates || [];
  const oldFillRates = storeApi.state.getters.fillRates || [];
  const queryTemplates = storeApi.state.es.getters.queryTemplates;

  const rawMarket = storeApi.state.es.getters.rawMarket;
  const aggregations = rawMarket?.aggregations || {};
  const hits = rawMarket?.hits || {};
  const hitsTotal = hits.total || 0;

  // We should also make sure that all the features are in
  // So we iterate per feature
  const newFillRates = queryTemplates
    .map((template) => {
      // We don't want any rate with inputs
      if (template.inputs) {
        return null;
      }

      const rateToBe = {
        id: template.id,
        value: 0,
        label: template.label,
        description: template.description,
        starred: false
      };
      let value = 0;

      // We want to use the raw market to try and find
      // The right values for each fill rate
      if (aggregations[template.id]) {
        const count = aggregations[template.id].doc_count;
        value = count || 0;
      }

      rateToBe.value = hitsTotal === 0 ? 0 : value / hitsTotal;

      const foundNew = newFillRatesPayload.find(
        (fillRate) => fillRate.id === template.id
      );

      if (foundNew) {
        rateToBe.id = foundNew.id;
        rateToBe.starred = foundNew.starred;
        return rateToBe;
      }

      const foundOld = oldFillRates.find(
        (fillRate) => fillRate.id === template.id
      );

      if (foundOld != null) {
        rateToBe.id = foundOld.id;
        rateToBe.starred = foundOld.starred || false;
        return rateToBe;
      }

      return rateToBe;
    })
    .filter((fillRate) => fillRate?.id?.length || 0 > 0)
    .sort((fillRate) => (!fillRate?.starred ? 1 : -1));

  storeApi.state.mutations.setFillRates(
    newFillRates.filter((fillRate) => fillRate) as FillRate[]
  );
};

/**
 * Downloads the state report
 */
export const downloadReport = async (
  store: ActionContext<any, any>,
  payload: string
) => {
  const url = tokenify(`${process.env.API_BASE}/explorer/downloads/${payload}`);
  return downloadFile(url, 'report');
};

/**
 * Retrieves the state id on the store
 */
export const fetchState = async (store: ActionContext<any, any>) => {
  const { id } = store.getters;

  if (id == null || id.length === 0 || store.getters.fetchedStateId === id) {
    return null;
  }

  // Cache the fetched state so we don't refetch
  store.commit('setFetchedStateId', id);

  // Since it is a fetch, don't let it create in the next
  // X time
  setDontSaveNext(1000);

  await storeApi.state.actions.setLoading({ value: true });

  const state: { [key: string]: any } = await new Promise((resolve, reject) => {
    request
      .get(`/explorer/states/${id}`)
      .then((response) => {
        const state = response.data != null ? response.data : {};
        return resolve(state);
      })
      .catch(reject);
  });

  // Save the old state so we can diff later
  store.commit('setOldState', state);

  const stateStore = state.store != null ? state.store : {};

  await storeApi.state.es.actions.updateState(stateStore);
  await storeApi.rulesets.actions.fetchReportRulesets();
  await storeApi.state.actions.updateFillRates(state.fill_rates);
  await storeApi.state.es.actions.queryMarket();
  // DEV: used to update the raw from Q so that the market builder is updated
  await storeApi.state.es.actions.getQRaw();
  await storeApi.state.actions.setLoading({ value: false });

  return null;
};

let queryStateDebounce: any = null;
let queryStateResolves: Array<(...args: any) => any> = [];
let queryStateRejects: Array<(...args: any) => any> = [];
/**
 * Queries the state and updates or creates a state depending on the id being present
 */
export const queryState = (
  store: ActionContext<any, any>,
  payload: {
    force?: boolean;
    debounceTime?: number;
    dontSaveNextTime?: number;
    dontQueryMarket?: boolean;
  }
): Promise<any> =>
  new Promise((resolve, reject) => {
    const hasDontSaveNext = dontSaveNext != null;

    if (payload != null && payload.dontSaveNextTime != null) {
      setDontSaveNext(payload.dontSaveNextTime);
    }

    if (hasDontSaveNext) {
      resolve();
      return;
    }

    // Check the right time for the debouncer
    let time =
      payload != null && payload.debounceTime != null
        ? payload.debounceTime
        : 500;
    time = payload != null && payload.force ? 1 : time;

    queryStateResolves.push(resolve);
    queryStateRejects.push(reject);

    // We want the most up to date data and because of that
    // We debounce
    if (queryStateDebounce != null) {
      clearTimeout(queryStateDebounce);
    }

    // Set the timer for the debounce
    queryStateDebounce = setTimeout(async () => {
      queryStateDebounce = null;

      try {
        await store.dispatch('updateFillRates');
        const data = await updateState(payload);

        for (let i = 0; i < queryStateResolves.length; i += 1) {
          queryStateResolves[i](data);
        }
      } catch (err) {
        for (let i = 0; i < queryStateRejects.length; i += 1) {
          queryStateRejects[i](err);
        }
      }

      queryStateResolves = [];
      queryStateRejects = [];
    }, time);
  });
