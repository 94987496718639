import * as mutations from './mutations';
import { esStateActions } from './actions';
import * as getters from './getters';
import { getDefaultState } from './state';

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  actions: esStateActions,
  getters
};
