import { TimeUnit } from './timeUnits';
export function relativeDay(target: Date) {
  const interval = target.getTime() - Date.now();
  const daysDifference =
    Math.floor(Math.abs(interval) / TimeUnit.DAY) * Math.sign(interval);
  return new Intl.RelativeTimeFormat('en-US', { numeric: 'auto' }).format(
    daysDifference,
    'day'
  );
}
