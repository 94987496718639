

















import _ from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: String
    },
    manual: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    immediate: {
      type: Boolean,
      default: false
    },
    enableEmpty: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: 200
    },
    focusOnMount: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    formatNumbers: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      myValue: '' as string | number
    };
  },
  computed: {
    valueIsValid(): boolean {
      // Ignore the first character if "-" because it might be a
      // Negative number
      let val = `${this.myValue}`;
      if (val[0] === '-') {
        val = val.substr(1);
      }

      if (this.formatNumbers) {
        return /^-?\d+$|^\d+(\.|,)\d+$/.test(val);
      }

      return /^\d+$/.test(val);
    }
  },
  mounted() {
    if (!_.isNil(this.value)) {
      this.myValue = this.value;
      this.validate();
    }

    if (this.focusOnMount) {
      this.focus();
    }
  },
  methods: {
    checkValue() {
      if (this.maxLength > 0) {
        const stringified = this.myValue.toString();
        if (this.myValue != null && stringified.length > this.maxLength) {
          this.myValue = `${this.myValue}`.slice(0, this.maxLength);
        }
      }
    },
    evaluate() {
      _.debounce(() => {
        if (this.immediate) {
          const theValue = this.myValue.toString();
          let data;
          if (theValue.indexOf('.') > -1) {
            const str = theValue.replace(/[,]+/g, '').trim();
            data = parseFloat(str);
          } else {
            const string = theValue.replace(/[,]+/g, '').trim();
            data = parseInt(string, 10);
          }
          this.emit(data);
        }
      }, 100);
    },
    emit(data: string | number) {
      this.$emit('input', data);
    },
    validate() {
      if (!this.valueIsValid) {
        this.myValue = this.enableEmpty ? '' : 0;
        this.$emit('input', this.myValue);
        return;
      }

      if (!this.formatNumbers) {
        this.$emit('input', parseInt(`${this.myValue}`, 10));
        return;
      }

      const num = this.myValue.toString();
      const isNegative = `${num}`[0] === '-';
      const cleaned = num
        .split('')
        .reduce((memo: string[], current) => {
          if (current !== ',') {
            memo = memo.concat(current);
          }
          return memo;
        }, [])
        .join('');

      if (num.indexOf('.') > -1) {
        const parts = num.split('.');
        const left = parseInt(parts[0], 10).toString();
        const formattedLeft = left
          .split('')
          .reverse()
          .reduce((memo: string[], current, index) => {
            if (index && index % 3 === 0) {
              memo = memo.concat(',', current);
            } else {
              memo = memo.concat(current);
            }
            return memo;
          }, [])
          .reverse()
          .join('');

        this.myValue = `${isNegative ? '-' : ''}${formattedLeft}.${parts[1]}`;
        this.$emit('input', parseFloat(cleaned));
        return;
      }

      const numbers = num
        .split('')
        .reverse()
        .reduce((memo: string[], current, index) => {
          if (index && index % 3 === 0 && current !== '-') {
            memo = memo.concat(',');
          }
          memo = memo.concat(current);
          return memo;
        }, [])
        .reverse()
        .join('');

      if (numbers.indexOf(',') > -1) {
        this.myValue = numbers
          .split(',')
          .reduce((memo: string[], current, index) => {
            if (index === 0) {
              return memo.concat(parseInt(current, 10).toString());
            }
            return memo.concat(current);
          }, [])
          .join(',');
      } else {
        this.myValue = parseInt(numbers, 10).toString();
      }

      this.$emit('input', parseInt(cleaned, 10));
    },
    onBlur() {
      if (
        !this.manual &&
        (this.enableEmpty ||
          (!_.isNil(this.myValue) && !_.isEmpty(this.myValue)))
      ) {
        this.validate();
      }
    },
    onFocus() {
      if (this.myValue == null) {
        return;
      }

      this.myValue = this.myValue
        .toString()
        .split('')
        .reduce((memo: string[], current) => {
          if (current !== ',') {
            memo = memo.concat(current);
          }

          return memo;
        }, [])
        .join('');
    },

    focus() {
      // Force the browser to focus after redraw
      setTimeout(() => {
        // TODO: find a better way to setup this any
        (this.$refs['my-input'] as any).focus();
      }, 20);
    }
  }
});
