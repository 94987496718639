import { EsState } from './state';

// TODO: should setup vuex types here
export function isReqProduction(
  state: EsState,
  getters: any,
  rootState: any,
  rootGetters: any
) {
  return rootGetters['state/isReqProduction'];
}

export const isLoading = (state: EsState) => state.isLoading;

export const oldRaw = (state: EsState) => state.oldRaw;
export const raw = (state: EsState) => state.raw;
export const rawMarket = (state: EsState) => state.rawMarket;
export const hasMarket = (state: EsState) => {
  if (state.rawMarket == null) {
    return false;
  }

  const keys = Object.keys(state.rawMarket).filter(
    (k) => k.indexOf('__') === -1
  );
  return keys.length > 0;
};

// These are generated from the es-query instance
export const queryUi = (state: EsState) => state.queryUi;
export const aggsUi = (state: EsState) => state.aggsUi || [];
export const queryTemplates = (state: EsState) => state.queryTemplates;
export const featureTemplates = (state: EsState) => state.featureTemplates;

export const previews = (state: EsState) => state.previews;
export const hits = (state: EsState) => state.hits;
