export type FillRate = {
  id: string;
  starred?: boolean;
  label: string;
  description?: string;
  value: number;
};

export type State = {
  isLoading: boolean;
  isProduction: boolean;
  isReqProduction: boolean;

  oldState: {
    // TODO: need to set this right
    [key: string]: any;
  };
  fillRates: FillRate[];
  id: string;
  fetchedStateId: string;
  lastQueryOrAggAddedId: string;
};

export const getDefaultState = (): State => ({
  isLoading: true,
  isProduction: true,
  isReqProduction: true,

  oldState: {},
  fillRates: [],
  id: '',
  fetchedStateId: '',
  lastQueryOrAggAddedId: ''
  // Segmenter: {
  //   Aggs: {},
  //   Labels: [],
  // },
  // Builder: {},
  // Selector: {},
});
