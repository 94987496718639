




import _ from 'lodash';
import Vue from 'vue';
import Logger from '../logger/Logger.class';
import { storeApi } from '../store/index';

const { prodError } = new Logger('TheMarketExplorerDownloadView');

const TheMarketExplorerDownloadView = Vue.extend({
  name: 'TheMarketExplorerDownloadView',
  async created(): Promise<void> {
    const reportId = this.$route?.params.reportId || '';
    if (reportId) {
      await storeApi.state.actions
        .downloadReport(reportId)
        .then(() => this.returnHome())
        .catch(() => {
          this.$toast(
            'There was an error when processing your download, please try again.',
            'error'
          );

          return this.returnHome();
        });
    } else {
      this.returnHome().catch(prodError);
    }
  },
  methods: {
    returnHome(): Promise<any> {
      return this.$router.replace({
        name: 'home'
      });
    }
  }
});

export default TheMarketExplorerDownloadView;
