import moment from 'moment';

/**
 * Formats supplied timestamp as the request format
 * @param {Date|string|number} timestamp - the timestamp to format
 * @returns {string}
 */
export default (
  timestamp?: string | number | Date,
  formatDate = 'MM/DD/YY'
) => {
  if (typeof timestamp === 'string' && timestamp === '') {
    return '';
  }

  if (timestamp != null) {
    return moment(timestamp).format(formatDate);
  }

  return '';
};
