import Vue from 'vue';
import Vuex from 'vuex';

import marketInputs from './modules/market-inputs';
import queryTemplates from './modules/query-templates';
import featureSegments from './modules/feature-segments';
import appSettings from './modules/app-settings';
import users from './modules/users';
import reports from './modules/reports';
import state from './modules/state';
import { rulesetModule } from './modules/rule-sets/module';
import { TypedVuexStore } from 'typed-vuex-store';

Vue.use(Vuex);

export const storeApi = new TypedVuexStore({
  modules: {
    queryTemplates,
    featureSegments,
    marketInputs,
    appSettings,
    users,
    reports,
    state,
    rulesets: rulesetModule
  }
});

export const store = storeApi.store;
