




































import _ from 'lodash';
import { hasValidValue } from '../../utils/value';
import UIQueryInput from './UIQueryInput.vue';
import Vue from 'vue';
import { storeApi } from '../../store/index';
import Logger from '../../logger/Logger.class';
import TheRuleSelectorModal from '../modal/rule-selector/TheRuleSelectorModal.vue';

const { prodError } = new Logger('TheQueryChainNode');

const TheQueryChainNode = Vue.extend({
  name: 'TheQueryChainNode',
  components: {
    UIQueryInput
  },
  props: {
    rule: {
      type: Object
    },
    rootIdentity: {
      type: String,
      default: 'b'
    },
    ruleIndex: {
      type: Number,
      default: 0
    },
    enableFocusOnMount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      focusIndex: null as null | number,
      inputData: [] as any[]
    };
  },
  computed: {
    inputs(): any[] {
      return this.rule == null || this.rule.inputs == null
        ? []
        : this.rule.inputs;
    }
  },
  watch: {
    inputData: {
      deep: true,
      handler(val) {
        const vals = _.isArray(val) ? val : [val];

        // Update right away but as a reference
        for (let i = 0; i < vals.length; i += 1) {
          const v = vals[i];
          const vKeys = Object.keys(v);

          for (let c = 0; c < this.inputs.length; c += 1) {
            const input = this.inputs[c];

            if (input.query_block_key !== val.query_block_key) {
              continue;
            }

            for (let d = 0; d < vKeys.length; d += 1) {
              const key = vKeys[d];
              input[key] = val[key];
            }
          }
        }

        storeApi.state.es.actions
          .editQueryValues({
            identity: this.rule.identity,
            query: this.rule,
            inputs: this.inputs,
            isFromBucket: this.rule.isFromBucket
          })
          .catch(prodError);
      }
    }
  },
  mounted() {
    this.findInputFocus();
  },
  methods: {
    findInputFocus() {
      this.focusIndex = null;

      for (let i = 0; i < this.inputs.length; i += 1) {
        const input = this.inputs[i];

        // Check if we should input...
        const hasValue = hasValidValue(input.value);
        if (!hasValue) {
          this.focusIndex = i;
          break;
        }
      }
    },
    changeRule() {
      if (this.rule.isFromBucket) {
        return;
      }

      // TODO: figure a better way for this any
      this.$showModal(TheRuleSelectorModal, {
        identity: this.rule.identity
      });
    },
    removeRule() {
      storeApi.state.es.actions
        .removeQuery({
          identity: this.rule.identity
        })
        .catch(prodError);
    }
  }
});

export default TheQueryChainNode;
