





























import Vue from 'vue';
import UILink from './UILink.vue';
import UIBlockBanner from './UIBlockBanner.vue';

/**
 * Banner for prompting the user to delete a rule set.
 */
const TheRulesetDeleteBanner = Vue.extend({
  components: {
    UILink,
    UIBlockBanner
  }
});

export default TheRulesetDeleteBanner;
