





































































































import Vue from 'vue';
import Popper from 'vue-popperjs';
// DEV: we use this form so that the tests don't complain
import 'epic-spinners/dist/lib/epic-spinners.min.css';
import { SemipolarSpinner } from 'epic-spinners/dist/lib/epic-spinners.min.js';
import UITextInput from './UITextInput.vue';
import UICheckbox from './UICheckbox.vue';
import ClickOutside from '../directives/click-outside';
import OnKeypress from '../directives/on-keypress';
import { deepEqual, flattenData } from '../utils/object';
import Logger from '../logger/Logger.class';

const { prodError } = new Logger('Model');

type IAsyncOptionsFn = (keyword: string) => Promise<undefined | any[]>;

export default Vue.extend({
  components: {
    UITextInput,
    Popper,
    UICheckbox,
    SemipolarSpinner
  },
  directives: {
    ClickOutside,
    OnKeypress
  },
  props: {
    value: {
      type: null as any
    },
    placeholder: {
      type: String,
      default: 'Select an item'
    },
    // On cases where we have an overflow for example
    // We want to change this strategy to "fixed"
    // If that happens though, you also need to account
    // For the width changes on "dropdown-items-wrap"
    positionStrategy: {
      type: String,
      default: 'absolute'
    },
    itemMinWidth: {
      type: String
    },
    searchable: {
      type: Boolean,
      default: true
    },
    sort: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => [] as any[]
    },
    focusOnMount: {
      type: Boolean,
      default: false
    },

    isAutosuggest: {
      type: Boolean,
      default: true
    },
    asyncOptionsFn: {
      type: Function
    }
  },
  data() {
    return {
      isLoading: false,

      keyword: '',
      fetchedKeyword: '',
      selectedOptions: [] as any[],
      showDropdown: false,
      searching: true,

      popperOptions: {
        placement: 'bottom-start',
        strategy: this.positionStrategy,
        positionFixed: this.positionStrategy === 'fixed',
        arrow: {
          enabled: false
        },
        modifiers: {
          preventOverflow: {
            boundariesElement: 'viewport',
            escapeWithReference: true
          },
          flip: {
            behavior: ['bottom', 'bottom-start', 'bottom-end'],
            enabled: false,
            boundariesElement: 'viewport'
          }
        }
      },

      asyncItems: [] as any[],
      oldItemsEmission: null as any[] | null,
      cachedItems: [] as any[],
      timerInformChanges: null as any
    };
  },
  computed: {
    displayItems(): any[] {
      let items = this.asyncItems;

      const regex = new RegExp(this.keyword, 'i');
      items = items.filter(
        (option) => regex.test(option.label) || regex.test(option.value)
      );

      // Sort them
      if (this.sort) {
        items = items.sort((a) => (!a.checked ? 1 : -1));
      }

      return items;
    },
    displaySearchInput(): boolean {
      return this.searching || !this.header;
    },
    header(): string {
      let placeholderToBe = this.placeholder == null ? '' : this.placeholder;

      const cached = this.cachedItems;
      if (cached.length > 0) {
        const label =
          cached[0].label == null ? cached[0].value : cached[0].label;
        placeholderToBe = label;

        if (cached.length > 1) {
          placeholderToBe = `${label} (and ${cached.length - 1} more)`;
        }
      }

      return placeholderToBe;
    },
    hasSelectedItems(): boolean {
      return this.cachedItems.length > 0;
    }
  },
  watch: {
    value: {
      deep: true,
      handler(val: any) {
        this.updateValue(val);
      }
    },
    keyword(val: string) {
      this.fetchKeyword(val).catch(prodError);
    }
  },
  mounted(): void {
    this.updateValue(this.value);

    if (!this.focusOnMount) {
      return;
    }

    setTimeout(() => this.edit(), 500);
  },
  methods: {
    updateValue(val: any) {
      this.selectedOptions = flattenData(val);
      this.cachedItems = flattenData(val || []).filter((v: any) => v.checked);

      this.updateAsyncItems(this.asyncItems);

      // Changing from the top, we don't want the focus
      setTimeout(() => {
        const ref = this.$refs['search-input'];
        if (ref != null) {
          // TODO: improve this any by setting the type
          (ref as any).$el.blur();
        }

        this.onClickOutside(null, true);
      }, 20);
    },

    getCurrentValues() {
      const internalOptions = this.cachedItems == null ? [] : this.cachedItems;

      return internalOptions
        .filter((v) => {
          if (v == null) {
            return false;
          }

          if (typeof v !== 'object' || !this.multiple) {
            return true;
          }

          return v.checked;
        })
        .map((item) => {
          const newItem = { ...item };
          delete newItem.checked;

          return newItem;
        });
    },

    informChanges(): void {
      if (this.timerInformChanges != null) {
        clearTimeout(this.timerInformChanges);
      }

      this.timerInformChanges = setTimeout(() => {
        this.timerInformChanges = null;

        const finalValues = this.getCurrentValues();

        // Already emitted the same items, no need to do it again
        if (deepEqual(this.oldItemsEmission, finalValues)) {
          return;
        }

        this.oldItemsEmission = finalValues;
        this.$emit('change', finalValues);
      }, 100);
    },

    closeDropdown(dontEmit = false): void {
      this.doneEditing();
      this.showDropdown = false;
      this.keyword = '';

      // Resort per checked, this way when it opens again it is sorted
      this.updateAsyncItems(this.asyncItems);

      if (!dontEmit) {
        this.informChanges();
      }
    },

    /**
     * Updates dropdown items
     * @param {*[]} data
     */
    updateAsyncItems(data?: any[]): void {
      const cachedItems = this.cachedItems == null ? [] : this.cachedItems;

      const asyncItems = (data == null ? [] : data).map((item) => {
        const newItem = {
          ...item
        };

        const found = cachedItems.filter((i) => i.value === item.value)[0];
        if (found != null) {
          newItem.checked = newItem.checked || found.checked;
        }

        return newItem;
      });

      // Iterate cached items and see if they exist on the async items
      // We want to cache them if not
      for (let i = 0; i < this.cachedItems.length; i += 1) {
        const single = this.cachedItems[i];
        const found = asyncItems.filter((it) => it.value === single.value)[0];
        if (found != null) {
          continue;
        }

        asyncItems.push(single);
      }

      // Cache the async items
      this.asyncItems = asyncItems;
    },

    toggleMultiSelectOption(option: {
      checkend?: boolean;
      [key: string]: any;
    }): void {
      option.checked = !option.checked;

      let found = false;
      const oldCachedItems = this.cachedItems == null ? [] : this.cachedItems;
      const newCachedItems = oldCachedItems.map((it) => {
        // Lets try and find the same option coming in
        // If there is one, we know we should uncheck it
        if (it.value === option.value) {
          it.checked = false;
          found = true;
        }

        return it;
      });

      // Lengths are different so we know it was set as false
      if (!found) {
        newCachedItems.push({
          ...option,
          checked: true
        });
      }

      this.cachedItems = newCachedItems;
    },

    start(): void {
      this.showDropdown = this.asyncItems.length > 0;
    },

    edit(): void {
      this.keyword = '';
      this.searching = true;
      this.start();

      setTimeout(() => {
        // TODO: improve this any by typing it
        (this.$refs['search-input'] as any).$el.focus();
      }, 50);
    },

    doneEditing(): void {
      this.searching = false;
    },

    onClickOutside(evt: any, dontEmit = false): void {
      this.doneEditing();

      // Nothing to do, no close was emitted
      if (!this.showDropdown) {
        return;
      }

      // Handle multiples only
      if (!this.multiple) {
        this.closeDropdown(dontEmit);
        return;
      }

      // Handle selected options
      const oldSelectedOptions = this.asyncItems == null ? [] : this.asyncItems;
      const newSelectedOptions = [] as typeof oldSelectedOptions;
      for (let i = 0; i < oldSelectedOptions.length; i += 1) {
        const item = oldSelectedOptions[i];
        if (item == null || !item.checked) {
          continue;
        }

        const found = newSelectedOptions.filter(
          (it) => it.value === item.value
        )[0];
        if (found != null) {
          continue;
        }

        newSelectedOptions.push({
          ...item,
          label: item.label,
          value: item.value
        });
      }
      this.selectedOptions = newSelectedOptions;

      // Handle cached items
      const oldCachedItems = this.cachedItems == null ? [] : this.cachedItems;
      const newCachedItems = [] as typeof oldCachedItems;
      for (let i = 0; i < oldCachedItems.length; i += 1) {
        const item = oldCachedItems[i];
        if (item == null || !item.checked) {
          continue;
        }

        const found = newCachedItems.filter((it) => it.value === item.value)[0];
        if (found != null) {
          continue;
        }

        newCachedItems.push(item);
      }

      // Join selected options to the cached items
      for (let i = 0; i < this.selectedOptions.length; i += 1) {
        const item = this.selectedOptions[i];
        if (item == null || !item.checked) {
          continue;
        }

        const found = newCachedItems.filter((it) => it.value === item.value)[0];
        if (found != null) {
          continue;
        }

        newCachedItems.push(item);
      }

      this.cachedItems = newCachedItems;

      this.closeDropdown(dontEmit);
    },

    select(option: { checkend?: boolean; [key: string]: any }): void {
      option.checked = true;
      this.cachedItems = [option];

      this.closeDropdown();
    },

    /**
     * Fetches the keyword data for the auto suggest
     */
    async fetchKeywordAutoSuggest(val?: string, shouldntOpen = false) {
      if (
        val == null ||
        val.length === 0 ||
        this.fetchedKeyword === val ||
        this.asyncOptionsFn == null
      ) {
        return;
      }

      // Cache the keyword so we know it has been fetched
      this.fetchedKeyword = val;

      try {
        this.isLoading = true;
        const data = await (this.asyncOptionsFn as IAsyncOptionsFn)(val);
        this.isLoading = false;
        this.updateAsyncItems(data);

        // An initial shouldn't open or emit any change
        // DEV: we don't want to check the equality of keyword
        //      And initial keyword because it might've changed
        //      Meanwhile and get back to the initial
        if (!shouldntOpen) {
          this.showDropdown = this.asyncItems.length > 0;
        }
      } catch (err) {
        this.isLoading = false;
      }
    },

    /**
     * Fetches the keyword data
     */
    async fetchKeyword(val?: string, shouldntOpen = false) {
      if (val == null || val.length === 0 || this.asyncOptionsFn == null) {
        return;
      }

      if (this.isAutosuggest) {
        return this.fetchKeywordAutoSuggest(val, shouldntOpen);
      }

      this.isLoading = false;
      try {
        const data = await (this.asyncOptionsFn as IAsyncOptionsFn)(val);
        if (data == null) {
          return;
        }

        const items = data.filter(
          (v) => v.label.toLowerCase().indexOf(val) > -1
        );
        this.updateAsyncItems(items);

        if (!shouldntOpen) {
          this.showDropdown = this.asyncItems.length > 0;
        }
      } catch (err) {
        // DEV: just ignore for now
      }
    }
  }
});
