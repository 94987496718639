import * as t from 'io-ts';
import Model from '../../classes/model';
import { jsonApiResource } from '../../jsonApi/models/resource';
import { ResourceType } from '../../clients/resourceTypes.enum';
import { timestampSchema } from '../generic/timestamp';
import { requiredJsonApiRelationshipSchema } from '../../jsonApi/models/relationship';
import { ModelInterface } from '../interfaceGetters/modelInterface';
import { queryBlockGroupModel } from '../queryUI/queryBlockGroup';

/**
 * Attributes of a ruleset.
 */
export const rulesetAttributesSchema = {
  /**
   * The rule set title.
   */
  title: t.string,
  /**
   * The rule set visibility
   *
   * "personal": only the owner can see it.
   * "available": all team members can see it.
   */
  visibility: t.union([t.literal('personal'), t.literal('available')]),
  /**
   * A description for the rule set.
   *
   * if the rule is from a preset, this is provided by us, otherwise it is provided by the user during the rule creation.
   */
  description: t.string,
  /**
   * The Query ui used to build this rule set.
   */
  query_template: queryBlockGroupModel.base,
  /**
   * Returns true if a rule set should be enabled by default when mounting the explorer page. Otherwise, false.
   */
  default: t.boolean,
  ...timestampSchema
};

export const rulesetRelationshipsSchema = {
  /**
   * The user this ruleset belongs to.
   */
  user: t.interface(requiredJsonApiRelationshipSchema(ResourceType.user))
};

/**
 * JSON api resource schema for a ruleset.
 */
export const rulesetBase = jsonApiResource({
  type: ResourceType.explorer_rule_set,
  attributes: t.interface(rulesetAttributesSchema),
  relationships: t.interface(rulesetRelationshipsSchema),
  meta: t.partial({
    match: t.boolean
  })
});

/**
 * Rule set is a piece of a query used for searching elastic search.
 */
export const RulesetModel = new Model(t.interface(rulesetBase));

/**
 * A Rule (aka: Query Template) is a building block for rule groups and sets in the market builder.
 */
export type Ruleset = ModelInterface<typeof RulesetModel>;
