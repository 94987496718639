import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookie from 'js-cookie';

// ----------------------------------------
// Variables

const instance = axios.create({
  baseURL: process.env.API_URL
});

// ----------------------------------------
// Functions

/**
 * Sets a token on a string
 * @param {string} url
 * @returns {string}
 */
export const tokenify = (url: string): string => {
  const sessionString: string = Cookie.get('session');

  if (sessionString) {
    const sessionJson = JSON.parse(sessionString);

    if (sessionJson.token) {
      if (url.indexOf('?') < 0) {
        return `${url}?token=${sessionJson.token}`;
      }

      return `${url}&token=${sessionJson.token}`.replace('?&', '?');
    }
  }

  return '';
};

/**
 * Converts queries objects to strings for the url
 * @param {object} query
 * @param {object} queryFilter
 * @returns {string}
 */
export const convertQueriesToString = (
  query: { [key: string]: any } = {},
  queryFilter: { [key: string]: any } = {},
  pageFilter: { [key: string]: any } = {}
): string => {
  const queryToBe: string[] = Object.keys(
    query == null ? {} : query
  ).map((key) =>
    query[key] == null || query[key] === '' ? '' : `${key}=${query[key]}`
  );

  const queryFilterToBe = Object.keys(
    queryFilter == null ? {} : queryFilter
  ).map((key) =>
    queryFilter[key] == null || queryFilter[key] === ''
      ? ''
      : `filter[${key}]=${queryFilter[key]}`
  );

  const pageFilterToBe = Object.keys(
    pageFilter == null ? {} : pageFilter
  ).map((key) =>
    pageFilter[key] == null || pageFilter[key] === ''
      ? ''
      : `page[${key}]=${pageFilter[key]}`
  );

  const finalQuery = ([] as string[])
    .concat(queryToBe, queryFilterToBe, pageFilterToBe)
    .filter((val) => val != null && val.length > 0);

  return finalQuery.join('&');
};

// ----------------------------------------
// Runtime

instance.interceptors.request.use((config) => {
  return {
    ...config,
    url: tokenify(config.url as string)
  };
});

export const request = <T>(req: AxiosRequestConfig) => {
  return new Promise<AxiosResponse<T>>((resolve, reject) => {
    instance(req)
      .then((response: AxiosResponse<T>) => resolve(response))
      .catch(reject);
  });
};

export default instance;
