import { FeatureTemplate } from '../../es-query/libs/types';

export type FeaturesTemplatesState = {
  templates: FeatureTemplate[];
  templatesAreReady: boolean;
};

export const getDefaultState = (): FeaturesTemplatesState => ({
  templates: [],
  templatesAreReady: false
});
