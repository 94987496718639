import { IQueryBlock } from '../../../API/models/queryUI/queryBlock';
import { IParsedQueryBlockGroup } from '../../../API/models/queryUI/queryBlockGroup';

/**
 * Finds a query block or query block group by identity using the provided QueryBlockGroup
 *
 * @param currentQueryBlock - Base query block to use for the search
 * @param identity - Identity of the query block that we want to find.
 */
export function getQueryBlock(
  currentQueryBlock: IParsedQueryBlockGroup | IQueryBlock,
  identity: string
): IParsedQueryBlockGroup | IQueryBlock | undefined {
  const queryBlock = currentQueryBlock;

  if (queryBlock.identity === identity) {
    return queryBlock;
  }

  if ('children' in queryBlock && queryBlock.children) {
    return queryBlock.children.find((block) => getQueryBlock(block, identity));
  }

  return undefined;
}
