export default function pick<T extends Record<string, any>, K extends keyof T>(
  from: T,
  keys: K[]
) {
  const result: Partial<Pick<T, K>> = {};

  if (!from) {
    throw new Error('From is undefined');
  }

  for (const key of keys) {
    if (!key) {
      throw new Error('Key is not valid');
    }

    result[key] = from[key];
  }

  return result as Pick<T, K>;
}
