import { getDefaultState, State } from './state';

export const resetState = () => getDefaultState();

export const setIsProduction = (state: State, payload: boolean) => {
  state.isProduction = payload;
  state.isReqProduction = payload;
};

export const setIsReqProduction = (
  state: State,
  payload: typeof state['isReqProduction']
) => {
  state.isReqProduction = payload;
};

export const setLoading = (
  state: State,
  payload: typeof state['isLoading']
) => {
  state.isLoading = payload;
};

export const setFetchedStateId = (
  state: State,
  payload: typeof state['fetchedStateId']
) => {
  state.fetchedStateId = payload;
};

export const setLastQueryOrAggAddedId = (
  state: State,
  payload: typeof state['lastQueryOrAggAddedId']
) => {
  state.lastQueryOrAggAddedId = payload;
};

// TODO: need to set this right
export const setFillRates = (
  state: State,
  payload: typeof state['fillRates']
) => {
  state.fillRates = payload == null ? [] : payload;
};

export const setOldState = (
  state: State,
  payload: typeof state['oldState']
) => {
  state.oldState = payload == null ? {} : payload;
};

export const setStateId = (state: State, payload: typeof state['id']) => {
  state.id = payload == null ? '' : payload;
};
