




































import Vue from 'vue';
import UILink from './UILink.vue';
import UIBlockBanner from './UIBlockBanner.vue';

/**
 * A banner/block for confirming a ruleset update or deletion.
 */
const TheConfirmationBanner = Vue.extend({
  components: {
    UILink,
    UIBlockBanner
  },
  props: {
    /**
     * Title of the updated/deleted rule set.
     */
    title: {
      type: String,
      required: true
    },
    /**
     * Defines if the message is displayed in a banner like block or a plain block.
     */
    banner: {
      type: Boolean,
      default: true
    },
    /**
     * Defines if it's an update or deletion confirmation
     */
    type: {
      type: String,
      required: true
    }
  }
});

export default TheConfirmationBanner;
