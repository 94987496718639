import { FeaturesTemplatesState } from './state';

/**
 * Gets templates
 * @param {FeaturesTemplatesState} state
 * @returns {FeatureTemplate[]}
 */
export const features = (state: FeaturesTemplatesState) => state.templates;

/**
 * Gets templates state
 * @param {FeaturesTemplatesState} state
 * @returns {boolean}
 */
export const featuresAreReady = (state: FeaturesTemplatesState) =>
  state.templatesAreReady;
