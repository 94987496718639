






















































































































































































import Vue, { PropOptions } from 'vue';
import {
  TheSaveButton,
  TheDeleteButton,
  RenderList
} from '@intricately/ui-lib';
import UISelectDropdown from '../UISelectDropdown.vue';
import TheQueryChainNode from './TheQueryChainNode.vue';
import TheRulesetTooltip from '../TheRulesetTooltip.vue';
import TheRulesetFooter from '../TheRulesetFooter.vue';
import TheSaveModeForm from '../TheSaveModeForm.vue';
import TheRulesetSaveBanner from '../TheRulesetSaveBanner.vue';
import TheRulesetDeleteBanner from '../TheRulesetDeleteBanner.vue';
import TheConfirmationBanner from '../TheConfirmationBanner.vue';
import { State } from '../../store/modules/state/state';
import { storeApi } from '../../store';
import Logger from '../../logger/Logger.class';
import { IQueryBlock } from '../../API/models/queryUI/queryBlock';
import {
  RuleSetUpdateOptions,
  RulesetCreateOptions
} from '../../store/modules/rule-sets/actions';
import { IParsedQueryBlockGroup } from '../../API/models/queryUI/queryBlockGroup';
import TheRuleSelectorModal from '../modal/rule-selector/TheRuleSelectorModal.vue';
const { prodError } = new Logger('TheQueryChain');
export default Vue.extend({
  name: 'TheQueryChain',
  components: {
    RenderList,
    UISelectDropdown,
    TheQueryChainNode,
    TheSaveButton,
    TheDeleteButton,
    TheRulesetTooltip,
    TheRulesetFooter,
    TheSaveModeForm,
    TheRulesetSaveBanner,
    TheRulesetDeleteBanner,
    TheConfirmationBanner
  },
  props: {
    node: {
      type: Object
    } as PropOptions<IQueryBlock | IParsedQueryBlockGroup>,
    enableFocusOnMount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let queryBlockGroupAttributes = {
      title: '',
      description: '',
      author: ''
    };
    if ('template' in this.node) {
      const template = this.node.template;
      const { getters } = storeApi.rulesets;
      if (template) {
        const ruleset = getters.rulesetById(template.rulesetId);
        queryBlockGroupAttributes = {
          title: template.title || '',
          description: template.description || '',
          author: ruleset
            ? getters.flattenedRuleset(ruleset).user.attributes.name
            : ''
        };
      }
    }
    return {
      oldRules: JSON.stringify(this.node),
      publicRule: true,
      saveMode: false,
      creatingNew: false,
      editingAttrOnly: false,
      askingToUpdate: false,
      askingToDelete: false,
      confirmTitle: '',
      confirmType: '',
      ...queryBlockGroupAttributes
    };
  },
  computed: {
    operatorItems(): { checked: boolean; label: string; value: string }[] {
      const operator =
        'operator' in this.node ? this.node.operator ?? 'must' : 'must';
      return [
        {
          label: 'AND',
          value: 'must',
          checked: operator === 'must'
        },
        {
          label: 'OR',
          value: 'should',
          checked: operator === 'should'
        },
        {
          label: 'AND NOT',
          value: 'must_not',
          checked: operator === 'must_not'
        }
      ];
    },
    lastQueryOrAggAddedId(): State['lastQueryOrAggAddedId'] {
      return storeApi.state.getters.lastQueryOrAggAddedId;
    },
    canSelectOperator(): boolean {
      return !!this.rules.length;
    },
    showReset(): boolean {
      return this.isRoot && !!this.rules.length;
    },
    isRoot(): boolean {
      // TODO: investigate this. Seems like it is always false and the
      // Query block group already has a isRoot property
      const node: any = this.node;
      if (node == null || node.bool == null) {
        return false;
      }
      return node.bool._identity === 'b';
    },
    canAddGroup(): boolean {
      const rulesLength = (this.rules == null ? [] : this.rules).length;
      return rulesLength > 0 && !this.isRoot;
    },
    rules(): any[] {
      return 'children' in this.node ? this.node.children || [] : [];
    },
    isCustom(): boolean {
      return !!this.node['template']?.rulesetId;
    },
    isDirty(): boolean {
      return this.oldRules !== JSON.stringify(this.node);
    },
    isEmpty(): boolean {
      return !!(
        'isRoot' in this.node &&
        this.node.isRoot &&
        !this.node.children?.length
      );
    },
    isSaveable(): boolean {
      let attrChanged = true;
      if ('template' in this.node) {
        attrChanged =
          this.title.trim() !== this.node.template?.title ||
          this.description.trim() !== this.node.template?.description;
      }
      return !!this.title.trim() && !!this.description.trim() && attrChanged;
    },
    isBannerUp(): boolean {
      return this.askingToUpdate || this.askingToDelete;
    }
  },
  methods: {
    /**
     * Toggle a form where the user edit title, description and public availability of a custom Rule Set and saves it
     */
    async toggleSaveMode(mode?: 'editingAttrOnly' | 'creatingNew') {
      if (mode) this[mode] = true;
      if (mode === 'creatingNew') {
        this.title = '';
        this.description = '';
        this.creatingNew = true;
      }
      this.editingAttrOnly = false;
      this.askingToUpdate = false;
      this.saveMode = true;
      await this.$nextTick();
      const title = this.$refs.title as HTMLInputElement;
      title.focus();
    },
    /**
     * Pops a Call To Action banner asking which action the user wants
     */
    async toggleBannerAction(mode: 'askingToUpdate' | 'askingToDelete') {
      this[mode] = true;
    },
    /**
     * Saves the custom ruleset, updating existing or creating a new one
     */
    async confirmSave() {
      const { name, id } = storeApi.appSettings.getters.authUser;
      if (!name) throw new Error('Could not get current user name');
      if (!id) throw new Error('Could not get current user ID');
      if (this.node.type !== 'query-block-group')
        throw new Error('Tried to save a non-group');
      const saveAttributes: RulesetCreateOptions = {
        userId: id + '',
        title: this.title.trim(),
        description: this.description.trim(),
        queryBlockGroup: this.node,
        visibility: this.publicRule ? 'available' : 'personal'
      };
      const updateAttributes: RuleSetUpdateOptions = {
        id: this.node.template?.rulesetId || '',
        attributes: saveAttributes,
        queryBlockGroup: this.node
      };

      if (this.node.template?.userId !== String(id)) {
        this.creatingNew = true;
      }

      if (!this.creatingNew) {
        this.confirmTitle = this.title;
        this.confirmType = 'edit';
      }

      this.author = name;

      storeApi.rulesets.actions
        .saveRuleset(this.creatingNew ? saveAttributes : updateAttributes)
        .catch(prodError);

      this.resetFlags();
      this.oldRules = JSON.stringify(this.node);
    },
    /**
     * Delete a custom Rule Set
     */
    async removeRuleset() {
      if (!this.isRoot) {
        this.$emit('child-ruleset-deleted', this.title);
      }
      this.confirmTitle = this.title;
      this.confirmType = 'delete';
      this.author = '';
      this.title = '';
      this.description = '';
      this.resetFlags();
      await storeApi.rulesets.actions
        .deleteRuleset(this.node as IParsedQueryBlockGroup)
        .catch(prodError);
      console.info(this.node);
    },
    /**
     * Set back state to defaults
     */
    resetFlags() {
      this.saveMode = false;
      this.editingAttrOnly = false;
      this.askingToUpdate = false;
      this.askingToDelete = false;
      this.creatingNew = false;
    },
    /**
     * Show deletion confirmation message with the deleted Rule Set title
     */
    showDeletedConfirmation(title) {
      this.confirmTitle = title;
      this.confirmType = 'delete';
    },
    /**
     * Dismiss confirmation message
     */
    dismissConfirmation() {
      this.confirmTitle = '';
      this.confirmType = '';
    },
    cancelSave() {
      if ('template' in this.node) {
        this.title = this.node.template?.title || '';
        this.description = this.node.template?.description || '';
      }
      this.resetFlags();
    },
    changePublicVisibility(val) {
      this.publicRule = val;
    },
    changeDescription(val) {
      this.description = val;
    },
    /**
     * Check Rule Set and current node if Rule Set came from segments.
     */
    isFromBucket(rule?: any): boolean {
      if (rule?.isFromBucket || rule?.children?.[0]?.isFromBucket) {
        return true;
      }
      if ('children' in this.node) {
        const [firstChild] = this.node.children;
        return (
          firstChild && 'isFromBucket' in firstChild && firstChild.isFromBucket
        );
      }
      return false;
    },
    showAddRuleModal(parentIdentity?: string, ruleIndex?: number) {
      // TODO: figure a better way for this any
      (this as any).$showModal(TheRuleSelectorModal, {
        parentIdentity,
        ruleIndex
      });
    },
    addRule() {
      this.showAddRuleModal(this.node.identity, this.rules.length);
    },
    addGroup() {
      if ('identity' in this.node && this.node.identity) {
        storeApi.state.es.actions
          .addQueryGroup({
            parentIdentity: this.node.identity
          })
          .catch(prodError);
      }
    },
    removeFromBucket(rule: any) {
      if (!this.isFromBucket(rule)) return;
      // Remove the "from bucket" from this specific Rule Set
      const childrenBucketized = rule.children.filter((c) => c.isFromBucket);
      for (let i = 0; i < childrenBucketized.length; i += 1) {
        const { identity } = childrenBucketized[i];
        storeApi.state.es.actions
          .changeQuery({
            identity,
            removeIsFromBucket: true
          })
          .catch(prodError);
      }
    },
    removeQuery() {
      if ('identity' in this.node && this.node.identity) {
        storeApi.state.es.actions
          .removeQuery({ identity: this.node.identity })
          .catch(prodError);
      }
    },
    selectOperator(
      operator: { checked: boolean; value: string }[],
      identity: string
    ) {
      const single = operator.find((o) => o.checked);
      if (single == null) {
        return;
      }
      storeApi.state.es.actions
        .updateQueryGroupOperator({
          identity,
          operator: single.value as any
        })
        .catch(prodError);
    }
  }
});
