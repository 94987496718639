import { QueryTemplatesState } from './state';
import { RuleCategory } from '../../../API/models/ruleCategory/ruleCategory';
import { omit } from '../../../utils/omit';
import { Rule } from '../../../API/models/rule/rule';

/**
 * Gets templates
 */
export const elasticsearchTemplates = (state: QueryTemplatesState) =>
  state.templates;

/**
 * Gets templates state
 */
export const templatesAreReady = (state: QueryTemplatesState) =>
  state.templatesAreReady;

export const categoriesWithRules = (state: QueryTemplatesState) => {
  return state.categories.reduce(
    (acc, category) => {
      acc[category.id] = {
        ...category.attributes,
        ...omit(category, ['attributes']),
        rules: state.rules.filter(
          (rule) => rule.relationships.category.data.id === category.id
        )
      };
      return acc;
    },
    {} as Record<
      RuleCategory['id'],
      RuleCategory['attributes'] & {
        rules: Rule[];
      } & Omit<RuleCategory, 'attributes'>
    >
  );
};
