



















import Vue from 'vue';
const UIActionButton = Vue.extend({
  name: 'UIActionButton',
  props: {
    /**
     * Text to show in the button.
     */
    cta: {
      type: String
    },
    /**
     * When this is set the button will use router-link to this value instead of <a>.
     */
    to: {
      type: String
    },
    /**
     * Href for when the button is a link.
     */
    link: {
      type: String
    },
    /**
     * Where to open the button.
     * @default "_blank"
     */
    target: {
      type: String,
      default: '_blank'
    },
    /**
     * Set this to true if the link is for an external resource and you don't want tracking to it.
     * There are some security concerns when opening external malicious domains.
     */
    safe: {
      type: Boolean,
      default: false
    },
    /**
     * The color theme that the button will apply.
     * @values "blue", "red", "yellow" or any custom theme name.
     * @default "blue"
     */
    theme: {
      type: String,
      default: 'blue'
    },
    /**
     * Whether if the button should apply the outline version of the theme.
     */
    outline: {
      type: Boolean,
      default: false
    },
    /**
     * Which size the button should have.
     * @values "sm", "md", "lg".
     * @default "md"
     */
    size: {
      type: String,
      default: 'md',
      validator: (value: unknown) =>
        typeof value === 'string' && ['sm', 'md', 'lg'].includes(value)
    }
  } as const,
  computed: {
    tag() {
      const tag = {
        name: 'button',
        attrs: {} as any
      };
      if (this.to) {
        tag.name = 'router-link';
        tag.attrs.to = this.to;
      }
      if (this.link) {
        tag.name = 'a';
        tag.attrs.href = this.link;
        tag.attrs.target = this.target;
        if (!this.safe) {
          tag.attrs.rel = 'noopener noreferrer';
        }
      }
      return tag;
    }
  }
});
export default UIActionButton;
