import { isNil } from 'lodash';
import { IQueryBlock } from '../../../API/models/queryUI/queryBlock';
import {
  IParsedQueryBlockGroup,
  IQueryBlockGroup
} from '../../../API/models/queryUI/queryBlockGroup';
import QueryBlock from './query-block';
import { storeApi } from '../../index';
import {
  QueryRawTree,
  QueryStateGroupTree,
  QueryDataUpdateConfig,
  RuleSetTemplate
} from './types';

export default class QueryBlockGroup {
  private myIdentity = `_${Math.random()
    .toString(36)
    .substr(2, 9)}`;

  private myOperator: 'must' | 'should' | 'must_not' = 'must';
  // eslint-disable-next-line @typescript-eslint/prefer-readonly
  private persist = false;
  // eslint-disable-next-line @typescript-eslint/prefer-readonly
  private isRoot = false;
  private children: (QueryBlock | QueryBlockGroup)[] = [];
  /**
   * Initializes the query block group
   */
  public constructor(
    persist = false,
    operator: 'must' | 'should' | 'must_not' | undefined = undefined,
    isRoot = false,
    public template?: RuleSetTemplate,
    children: (IQueryBlock | IQueryBlockGroup)[] = []
  ) {
    this.persist = persist;
    this.isRoot = isRoot;

    for (const child of children) {
      if (child.type === 'query-block-group') {
        this.add(
          this.myIdentity,
          new QueryBlockGroup(
            false,
            child.operator,
            false,
            child.template,
            child.children
          )
        );
      } else {
        const childTemplate = storeApi.queryTemplates.state.templates.find(
          (childTemplate) => childTemplate.id === (child as any).id
        );

        this.add(this.myIdentity, new QueryBlock(childTemplate, child));
      }
    }

    this.updateOperator(operator);
  }

  /**
   * Updates group operator
   */
  private updateOperator(
    operator: 'must' | 'should' | 'must_not' | undefined = undefined
  ) {
    if (
      operator !== 'must' &&
      operator !== 'should' &&
      operator !== 'must_not'
    ) {
      this.myOperator = 'must';
    } else {
      this.myOperator = operator;
    }
  }

  /**
   * Checks if the query block group is empty
   */
  public isEmpty() {
    return this.myIdentity === '';
  }

  /**
   * Add a child
   */
  public add(identity = '', child: QueryBlockGroup | QueryBlock) {
    if (
      identity == null ||
      identity.length === 0 ||
      identity === this.myIdentity
    ) {
      if (child instanceof QueryBlockGroup || child instanceof QueryBlock) {
        this.children.push(child);
      }

      return child;
    }

    this.children.forEach((ch) => {
      if (ch instanceof QueryBlockGroup) {
        ch.add(identity, child);
      }
    });

    return child;
  }

  /**
   * Destroys from buckets only
   * @returns if it has been destroyed
   */
  private destroyFromBuckets(
    params: {
      onlyFromBuckets?: boolean;
    } = {}
  ): boolean {
    this.children = this.children.filter((child) => {
      if (child.destroy == null) {
        return true;
      }

      return !child.destroy('', params);
    });

    if (this.children.length === 0 && !this.persist) {
      this.myIdentity = '';
    }

    return this.myIdentity == null || this.myIdentity.length === 0;
  }

  /**
   * Destroys the query block identity
   * @returns  true in case it has been destroyed
   */
  public destroy(
    identity: string | null = '',
    params: {
      onlyFromBuckets?: boolean;
    } = {}
  ): boolean {
    // Destroys the querys that came from buckets
    if (params.onlyFromBuckets) {
      return this.destroyFromBuckets(params);
    }

    const hasIdentity = identity != null && identity.length > 0;
    const isSelf = !hasIdentity || this.myIdentity === identity;

    this.children = this.children.filter((child) => {
      if (child.destroy == null) {
        return true;
      }

      if (isSelf) {
        return !child.destroy('', params);
      }

      if (hasIdentity) {
        return !child.destroy(identity as string, params);
      }

      // Keep the child then
      return true;
    });

    if (this.children.length === 0 && !this.persist) {
      this.myIdentity = '';
    }

    return this.myIdentity == null || this.myIdentity.length === 0;
  }

  /**
   * Cleans up the group removing those that are empty
   * @returns  Was "self" destroyed?
   */
  public cleanup() {
    // Cleanup each child removing all those that become destroyed
    // For not having enough children
    this.children = this.children.filter((child) => {
      if (!(child instanceof QueryBlockGroup)) {
        return true;
      }

      const isDestroyed = child.cleanup();
      return !isDestroyed;
    });

    if (this.children.length === 0) {
      this.destroy();
      return true;
    }

    return false;
  }

  /**
   * Updates the query block|group
   */
  public update(
    identity: string | null = '',
    params: QueryDataUpdateConfig = {}
  ) {
    if (identity === this.myIdentity) {
      if (params.operator != null) {
        this.updateOperator(params.operator);
      }

      if (params.template) {
        this.template = params.template;
      }

      return this;
    }

    const { removeIsFromBucket } = params;
    delete params.removeIsFromBucket;

    this.children.forEach((child) => {
      // First remove all from buckets because any change to the query
      // Needs the "isFromBucket" to be removed
      if (removeIsFromBucket) {
        child.update(null, {
          removeIsFromBucket: true,
          bucketKey: params.bucketKey
        });
      }

      if (child instanceof QueryBlockGroup) {
        child.update(identity, params);
      }

      if (child instanceof QueryBlock) {
        const childData = child.get();

        if (childData.identity !== identity) {
          return;
        }

        const childConfig =
          isNil(params.replace) && isNil(params.inputs)
            ? { bucketKey: params.bucketKey }
            : {
                replace: params.replace,
                inputs: params.inputs,
                bucketKey: params.bucketKey
              };

        child.update(params.query, childConfig);
      }
    });

    return this;
  }

  /**
   * Gets the group as raw format
   * @param {{ [key: string]: any }} params
   * @returns {QueryRawTree}
   */
  private getRaw(
    params: {
      isntFromBuckets?: boolean;
    } = {}
  ): QueryRawTree | null {
    const rawChildren: QueryRawTree[] = [];

    for (let i = 0; i < this.children.length; i += 1) {
      const child = this.children[i];

      if (child.isEmpty != null && child.isEmpty()) {
        continue;
      }

      if (child instanceof QueryBlock && !child.isValid()) {
        continue;
      }

      const rawRes = child.get('raw', params);
      if (rawRes != null) {
        rawChildren.push(rawRes);
      }
    }

    // No need to setup the block if there is nothing to actually
    if (rawChildren.length === 0) {
      return null;
    }

    return {
      bool: {
        [this.myOperator]: rawChildren
      }
    };
  }

  /**
   * Gets the group as state format
   */
  private getState(
    params: {
      isntFromBuckets?: boolean;
    } = {}
  ): IParsedQueryBlockGroup | null {
    const stateChildren: (QueryStateGroupTree | IQueryBlock)[] = (this
      .children == null
      ? []
      : this.children
    )
      .map(
        (child) =>
          child.get('state', params) as QueryStateGroupTree | IQueryBlock
      )
      .filter((child) => child != null);

    if (stateChildren == null || stateChildren.length === 0) {
      return null;
    }

    return {
      type: 'query-block-group',
      operator: this.myOperator,
      children: stateChildren,
      template: this.template
    };
  }

  /**
   * Gets the group as per request type
   */
  public get(type?: '', params?: Record<string, any>): IParsedQueryBlockGroup;
  public get(
    type: 'raw',
    params: { isntFromBuckets?: boolean }
  ): QueryRawTree | null;
  public get(
    type: 'state',
    params: { isntFromBuckets?: boolean }
  ): QueryStateGroupTree | null;
  public get(type: 'identity'): string;
  public get(
    type: 'raw' | 'state' | 'identity' | '' = '',
    params: {
      isntFromBuckets?: boolean;
    } = {}
  ) {
    if (type === 'raw') {
      return this.getRaw(params);
    }

    if (type === 'state') {
      return this.getState(params);
    }

    if (type === 'identity') {
      return this.myIdentity;
    }

    const group: IParsedQueryBlockGroup = {
      type: 'query-block-group',
      isRoot: this.isRoot,
      identity: this.myIdentity,
      children: this.children
        .map((child) => child.get(''))
        .filter((c) => c != null),
      operator: this.myOperator,
      template: this.template
    };

    return group;
  }
}
