import * as t from 'io-ts';
import { ResourceType } from '../../clients/resourceTypes.enum';

export function jsonApiRelationshipSchema<T extends ResourceType>(
  resourceType: T
) {
  return {
    data: t.union([
      t.null,
      t.interface({
        id: t.string,
        type: t.literal(resourceType)
      })
    ])
  };
}

export function requiredJsonApiRelationshipSchema<T extends ResourceType>(
  resourceType: T
) {
  return {
    data: t.interface({
      id: t.string,
      type: t.literal(resourceType)
    })
  };
}
