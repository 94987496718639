
























import Popper from 'vue-popperjs';
import Vue from 'vue';

const UITooltip = Vue.extend({
  name: 'UITooltip',
  components: {
    Popper
  },
  props: {
    trigger: {
      type: String,
      default: 'click'
    },

    /**
     * Popper options
     */
    options: {
      type: Object,
      default() {
        return {
          placement: 'right',
          modifiers: { offset: { offset: '0,10px' } }
        };
      }
    },
    maxSize: {
      type: String,
      default: '200px'
    },
    maxHeight: {
      type: Number
    },
    /**
     * Text alignment
     */
    align: {
      type: String,
      default: 'left'
    },
    iconWidth: {
      type: String,
      default: '15px'
    }
  },
  data() {
    return {
      hovering: true,
      arrow: {
        y: 0,
        x: 0
      }
    };
  },
  computed: {
    elementsStyle(): any {
      // TODO: should check this any
      const arrow = this.arrow as { x: number; y: number };

      return {
        textAlign: this.align,
        maxWidth: this.maxSize,
        '--arrowY': `${arrow.y}px`,
        '--arrowX': `${arrow.x}px`
      };
    }
  },
  methods: {
    // TODO: should check this any
    getPosition(el: any) {
      const rect: {
        x: number;
        y: number;
        height: number;
        width: number;
      } = el.getBoundingClientRect();

      const centerY: number = rect.y + rect.height / 2;
      const centerX: number = rect.x + rect.width / 2;

      return {
        center: {
          x: centerX,
          y: centerY
        },
        position: {
          x: rect.x,
          y: rect.y
        }
      };
    },
    getOffset(fixed: number, relative: number): number {
      if (fixed > relative) {
        return fixed - relative;
      }

      if (fixed < relative) {
        return -(relative - fixed);
      }

      return 0;
    },
    setArrowPosition(count = 0): void {
      const { toggler, content } = this.$refs;

      setTimeout(() => {
        const arrow = this.arrow as { x: number; y: number };

        // TODO: should check this "any" better
        const togglerBounding: {
          x: number;
          y: number;
          height: number;
          width: number;
        } = (toggler as any).getBoundingClientRect();

        // TODO: should check this "any" better
        const contentBounding: {
          x: number;
          y: number;
          height: number;
          width: number;
        } = (content as any).getBoundingClientRect();

        const contentCenter = {
          x: contentBounding.x + contentBounding.width / 2,
          y: contentBounding.y + contentBounding.height / 2
        };

        const togglerCenter = {
          x: togglerBounding.x + togglerBounding.width / 2,
          y: togglerBounding.y + togglerBounding.height / 2
        };

        const offset = {
          // TODO: should check this any
          x: this.getOffset(togglerCenter.x, contentCenter.x),
          y: this.getOffset(togglerCenter.y, contentCenter.y)
        };

        const yResult = contentBounding.height / 2 + offset.y;

        // The max height will prevent overflow to setup scrolls
        // For example
        if (this.maxHeight != null && yResult > this.maxHeight) {
          arrow.y = this.maxHeight;
        } else {
          arrow.y = yResult;
        }

        arrow.x = contentBounding.width / 2 + offset.x;

        // We want to update multiple times to make sure that
        // The arrow stays on the right position
        if (count < 2) {
          // TODO: should check this any
          this.setArrowPosition(count + 1);
        }
      }, 25);
    }
  }
});

export default UITooltip;
