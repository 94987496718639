import localForage from 'localforage';

localForage.config({
  name: 'IntricatelyApp',
  version: 1.0,
  storeName: 'intricately_storage',
  description: "Storage for Intricately's web app data"
});

export default {
  install(Vue) {
    Vue.prototype.$storage = localForage;
  }
};
