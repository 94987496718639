










































import Vue from 'vue';
import { UITooltip, UIHelpIcon } from '@intricately/ui-lib';
import TheQueryChain from './TheQueryChain.vue';
import { EsState } from '../../store/modules/state/es/state';
import { storeApi } from '../../store';

const TheMarketBuilder = Vue.extend({
  components: {
    TheQueryChain,
    UITooltip,
    UIHelpIcon
  },
  props: {
    isViewing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    title(): string {
      const title = storeApi.appSettings.getters.marketBuilderTitle;
      return title == null || title.length === 0 ? 'Define Your Market' : title;
    },
    textTooltip(): string {
      return storeApi.appSettings.getters.marketBuilderTooltip;
    },
    node(): EsState['queryUi'] {
      return storeApi.state.es.getters.queryUi;
    },
    showLoader(): boolean {
      return storeApi.state.getters.isLoading;
    },
    hasStateChanged(): boolean {
      const { state } = storeApi;

      return state.getters.fetchedStateId !== state.getters.id;
    }
  },
  watch: {
    expanded(val) {
      val
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = 'auto');
    }
  }
});

export default TheMarketBuilder;
