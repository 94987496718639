import IntricatelyAPI from '../../classes/API.class';
import { ResourceConfig } from '../../classes/ResourceConfig';
import Route from '../../classes/Route.class';
import {
  RulesetPostPayload,
  rulesetPostPayloadModel
} from '../../models/ruleset/ruleset.post';
import { RulesetModel } from '../../models/ruleset/ruleset';
import { jsonApi } from '../../jsonApi/jsonApi';
import { rulesetsPathBodyModel } from '../../models/ruleset/ruleset.patch';
import { ResourceType } from '../resourceTypes.enum';
import { publicJsonApiUserModel } from '../../models/user/user';
import { ModelInterface } from '../../models/interfaceGetters/modelInterface';
import { RuleModel } from '../../models/rule/rule';
import { RuleCategoryModel } from '../../models/ruleCategory/ruleCategory';
import * as t from 'io-ts';

export const RulesetResource = new ResourceConfig({
  get: new Route({
    path({ filters, sort, include, page, pageSize }) {
      const params = new URLSearchParams();
      jsonApi.path.applyIncluded(params, include);
      jsonApi.path.applyFilters(params, filters);
      jsonApi.path.applySort(params, sort);

      params.set('page[number]', (page || 1) + '');
      params.set('page[limit]', (pageSize || 20) + '');
      return jsonApi.path.applyParameters('/rule_sets', params);
    },
    options: {} as {
      filters?: {
        name?: string;
        user_id?: string[];
        id?: string[];
        deleted?: 'true' | 'false';
      };
      sort?: string[];
      page?: number;
      pageSize?: number;
      include?: (keyof ModelInterface<typeof RulesetModel>['relationships'])[];
    },
    response: jsonApi.models.body({
      type: 'array',
      model: RulesetModel,
      included: [publicJsonApiUserModel.base],
      meta: t.interface({
        matching_rule_entries: t.number,
        total_rule_entries: t.number
      })
    })
  }),
  post: new Route({
    path() {
      return '/rule_sets';
    },
    response: jsonApi.models.body({
      type: 'object',
      model: RulesetModel
    }),
    request: jsonApi.models.body({
      type: 'object',
      model: rulesetPostPayloadModel
    }),
    options: {} as {
      userId: string;
      attributes: RulesetPostPayload['attributes'];
    },
    payloadConstructor(options) {
      const payload: RulesetPostPayload = {
        type: ResourceType.explorer_rule_set as const,
        attributes: options.attributes,
        relationships: {
          data: {
            id: options.userId,
            type: ResourceType.user
          }
        }
      };

      return {
        data: payload
      };
    }
  }),
  patch: new Route({
    request: jsonApi.models.body({
      type: 'object',
      model: rulesetsPathBodyModel
    }),
    response: jsonApi.models.body({
      type: 'object',
      model: RulesetModel
    }),
    options: {} as ModelInterface<typeof rulesetsPathBodyModel>,
    path({ id }) {
      return '/rule_sets/' + id;
    },
    payloadConstructor(options) {
      return {
        data: options
      };
    }
  }),
  delete: new Route({
    path({ id }) {
      return '/rule_sets/' + id;
    },
    options: {
      id: ''
    },
    response: jsonApi.models.body({
      type: 'object',
      model: RulesetModel
    })
  })
});

/**
 * API client for interacting with the explorer namespace of the intricately API.
 *
 * NOTE: Do not use it directly in vue components. Prefer Vuex instead.
 */
export const explorerApi = new IntricatelyAPI(
  process.env.API_URL + '/explorer',
  {
    rule: new ResourceConfig({
      get: new Route({
        path(options) {
          const params = new URLSearchParams();
          jsonApi.path.applyFilters(params, options.filter);
          jsonApi.path.applyIncluded(params, options.include);
          jsonApi.path.applySort(params, options.sort);
          return jsonApi.path.applyParameters('/rules', params);
        },
        options: {} as {
          /**
           * Filter rules
           */
          filter?: {
            /**
             * Search for rule name or description
             */
            search: string;
          };
          /**
           * Sort options
           */
          sort?: string[];
          /**
           * List of relationships to include.
           */
          include?: (keyof ModelInterface<typeof RuleModel>['relationships'])[];
        },
        response: jsonApi.models.body({
          type: 'array',
          model: RuleModel,
          included: [RuleCategoryModel.base]
        })
      })
    }),
    /**
     * Rules presets to use in a query builder interface.
     */
    ruleset: RulesetResource
  }
);
