import * as t from 'io-ts';
import Model from './model';
import { ModelInterface } from '../models/interfaceGetters/modelInterface';

/**
 * Router class to describe a api route.
 */
export default class Route<
  RQ extends Model = any,
  RP extends Model = any,
  O extends Record<string, any> = any
> {
  /**
   * Options of the request. They are used to configure the path and the payload of the request.
   */
  public options?: O;

  /**
   * Replaces the request path with the returned value.
   *
   * @param options - Custom options passed when making the request.
   */
  public path?: (options: O) => string;

  /**
   * Request model
   */
  public request?: RQ;

  /**
   * Response model
   */
  public response!: RP;

  /**
   * Get the options and returns a payload based on it.
   */
  public payloadConstructor?: (options: O) => ModelInterface<RQ>;

  public constructor(options: Route<RQ, RP, O>) {
    const defaults: Partial<Route> = {
      response: new Model(t.any)
    };

    Object.assign(this, defaults, options);
  }
}
