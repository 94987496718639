import Logger from '../../../logger/Logger.class';
import ilyAxios from '../../../sdk/request';
import { storeApi } from '../../index';

const { prodError } = new Logger('Utils');

/**
 * Method to get the state to be and differences
 */
export const getStateDiff = async () => {
  await storeApi.state.es.actions.updateEsState();
  const [{ state }, { raw }] = await Promise.all([
    storeApi.state.es.actions.getQState(),
    storeApi.state.es.actions.getQRaw()
  ]);

  // Make sure we have the ES updated state
  const newState = {
    // Builder: getters.builder, // TODO: this is wrong...
    elasticsearch_query: raw,
    store: {
      aggs: state.aggs,
      query: state.query
    },
    // Selector: getters.selector, // TODO: this is wrong...
    fill_rates: (storeApi.state.getters.fillRates == null
      ? []
      : storeApi.state.getters.fillRates
    ).map((rate) => {
      // DEV: we want to make sure the rate is as we expect it
      return {
        id: rate.id,
        starred: rate.starred
      };
    })
  };

  return {
    state: newState,
    changed: true
  };
};

/**
 * Requests an update state
 */
const requestUpdateState = async (state: { [key: string]: any }) => {
  const res = await ilyAxios.post('/explorer/states', state);
  const data = res?.data || {};

  if (!data.id) return null;

  storeApi.state.actions.updateId(data.id).catch(prodError);

  storeApi.state.es.actions.updateEsState().catch(prodError);

  return data.id;
};

/**
 * Method to actually update the state
 */
export const updateState = async (payload: { dontQueryMarket?: boolean }) => {
  const shouldQueryMarket = !payload?.dontQueryMarket;

  if (shouldQueryMarket) await storeApi.state.es.actions.queryMarket();

  const { state, changed } = await getStateDiff();

  if (!changed) {
    return state;
  }

  // Save the old state so we can diff later
  storeApi.state.mutations.setOldState(state);

  await storeApi.state.actions.setLoading({ value: true });
  const data = await requestUpdateState(state);
  await storeApi.state.actions.setLoading({ value: false });
  return data;
};
