import { omit } from '../../../utils/omit';

type JsonApiResource = {
  id: string;
  type: string;
  attributes: Record<string, any>;
  relationships?: Record<
    string,
    {
      data:
        | null
        | {
            type: string;
            id: string;
          }
        | {
            type: string;
            id: string;
          }[];
    }
  >;
};

type RelationshipSource<T extends JsonApiResource> = Record<
  keyof T['relationships'],
  any[]
>;

type MappedRelationships<
  T extends JsonApiResource,
  S extends RelationshipSource<T>
> = T extends { relationships: any }
  ? {
      [key in keyof T['relationships']]: T['relationships'][key]['data'] extends any[]
        ? S[key]
        : S[key][number];
    }
  : never;

/**
 * Parses a json API resource.
 *
 * This will simplify the resource attributes, type, id and relationships all under the same level.
 *
 * The relationships are mapped, but you need to provide a source for it.
 */
export const parseResource = <
  T extends JsonApiResource,
  S extends RelationshipSource<T>
>(
  targetResource: T,
  sources?: S
) => {
  const simplifiedResource = {
    ...omit(targetResource, ['attributes', 'relationships']),
    ...targetResource.attributes
  };

  let result = simplifiedResource;

  const targetRelationships = targetResource.relationships;

  if (targetRelationships) {
    for (const rel in targetRelationships) {
      if (!sources?.[rel]) {
        throw new Error(`Missing source for ${rel} relationship`);
      }
    }

    const relationshipsKeys = Object.keys(targetRelationships);

    const mappedRelationships = Object.fromEntries(
      relationshipsKeys.map((key) => {
        const relationship = targetRelationships[key].data;

        if (relationship instanceof Array && sources) {
          return [
            key,
            sources[key].filter((resource) => {
              return relationship.some((rel) => {
                return rel.id === resource.id && rel.type === resource.type;
              });
            })
          ];
        }

        if (relationship && 'id' in relationship && sources) {
          return [
            key,
            sources[key].find((resource) => {
              return (
                resource.id === relationship.id &&
                resource.type === relationship.type
              );
            })
          ];
        }

        return [key, undefined];
      })
    ) as MappedRelationships<T, S>;

    result = {
      ...simplifiedResource,
      ...mappedRelationships
    };
  }

  return result as T['relationships'] extends never
    ? Omit<T, 'attributes' | 'relationships'> & T['attributes']
    : Omit<T, 'attributes' | 'relationships'> &
        T['attributes'] &
        MappedRelationships<T, S>;
};
