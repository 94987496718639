import * as mutations from './mutations';
import * as getters from './getters';
import { getDefaultState } from './state';
import { appSettingsActions } from './actions';

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: appSettingsActions,
  mutations,
  getters
};
