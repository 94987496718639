import _ from 'lodash';
import ilyAxios from '../../../sdk/request';
import { AppSettingsState } from './state';
import { storeApi } from '../../index';
import { ActionTree } from 'vuex';

const actions = <T extends ActionTree<any, any>>(tree: T) => tree;

export const appSettingsActions = actions({
  /**
   * Sets user id
   */
  async setAuthUserId(_ctx, id: string) {
    storeApi.appSettings.mutations.setAuthUserId(id);
  },

  /**
   * Fetches settings
   */
  async fetchAppSettings() {
    return new Promise((resolve, reject) => {
      ilyAxios
        .get('/explorer/settings')
        .then((response) => _.get(response, 'data', []))
        .then((data) => {
          storeApi.appSettings.mutations.setAppSettings(data);
          storeApi.appSettings.mutations.settingsAreReady();
          resolve(data);

          return null;
        })
        .catch((error) => {
          storeApi.appSettings.mutations.settingsAreNotReady();
          reject(error);
        });
    });
  },
  /**
   * Sets module as loading
   */
  async isLoading() {
    storeApi.appSettings.mutations.isLoadingApp();
  },

  /**
   * Sets module as not loading
   */
  async isDoneLoading() {
    storeApi.appSettings.mutations.isDoneLoadingApp();
  },

  /**
   * Sets user
   */
  async setAuthUser(ctx, user: AppSettingsState['authUser']) {
    storeApi.appSettings.mutations.setAuthUser(user);
  }
});
