






































































































import Vue from 'vue';
import Popper from 'vue-popperjs';
// DEV: we use this form so that the tests don't complain
import 'epic-spinners/dist/lib/epic-spinners.min.css';
import { SemipolarSpinner } from 'epic-spinners/dist/lib/epic-spinners.min.js';
import UITextInput from './UITextInput.vue';
import UICheckbox from './UICheckbox.vue';
import ClickOutside from '../directives/click-outside';
import OnKeypress from '../directives/on-keypress';
import { deepEqual } from '../utils/object';

type IItemOption = {
  key: string;
  label: string;
  value: string;
  checked?: boolean;
  sticky?: boolean;
};

export default Vue.extend({
  components: {
    UITextInput,
    Popper,
    UICheckbox,
    SemipolarSpinner
  },
  directives: {
    ClickOutside,
    OnKeypress
  },
  props: {
    placeholder: {
      type: String,
      default: 'Select an item'
    },
    // On cases where we have an overflow for example
    // We want to change this strategy to "fixed"
    // If that happens though, you also need to account
    // For the width changes on "dropdown-items-wrap"
    positionStrategy: {
      type: String,
      default: 'absolute'
    },
    itemMinWidth: {
      type: String
    },
    searchable: {
      type: Boolean,
      default: false
    },
    sort: {
      type: Boolean,
      default: false
    },
    cache: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => [] as any[]
    },
    focusOnMount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      showDropdown: false,
      searching: false,
      internalItemOptions: [] as IItemOption[],
      oldItemsEmission: null as IItemOption[] | null,

      keyword: '',

      popperOptions: {
        placement: 'bottom-start',
        strategy: this.positionStrategy,
        positionFixed: this.positionStrategy === 'fixed',
        arrow: {
          enabled: false
        },
        modifiers: {
          preventOverflow: {
            boundariesElement: 'viewport',
            escapeWithReference: true
          },
          flip: {
            behavior: ['bottom', 'bottom-start', 'bottom-end'],
            enabled: false,
            boundariesElement: 'viewport'
          }
        }
      },

      timerInformChanges: null as any
    };
  },
  computed: {
    displayItems(): IItemOption[] {
      let items =
        this.internalItemOptions == null ? [] : this.internalItemOptions;

      if (this.searchable) {
        const regex = new RegExp(this.keyword.toLowerCase(), 'i');
        items = items.filter(
          (option) =>
            regex.test(option.label.toLowerCase()) ||
            regex.test(option.value.toLowerCase())
        );
      }

      // Sort alphabetically
      if (this.sort) {
        items = items.sort((a, b) => a.label.localeCompare(b.label));
      }

      // Make sure the checked items stay on top
      const stickyItems = items.filter((a) => a.sticky);
      const nonSticky = items.filter((a) => !a.sticky);

      if (!this.searchable) {
        return stickyItems.concat(nonSticky);
      }

      const checked = nonSticky.filter((a) => a.checked);
      const nonChecked = nonSticky.filter((a) => !a.checked);

      return stickyItems.concat(checked).concat(nonChecked);
    },
    displaySearchInput(): boolean {
      return this.searching || !this.header;
    },
    header(): string {
      let placeholderToBe = this.placeholder == null ? '' : this.placeholder;

      const selected = this.internalItemOptions.filter((i) => i.checked);
      if (selected.length > 0) {
        const label =
          selected[0].label == null ? selected[0].value : selected[0].label;
        placeholderToBe = label;

        if (selected.length > 1) {
          placeholderToBe = `${label} (and ${selected.length - 1} more)`;
        }
      }

      return placeholderToBe;
    },
    hasSelectedItems(): boolean {
      const selected = this.internalItemOptions.filter((i) => i.checked);
      return selected.length > 0;
    }
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.updateInternalOptions();
      }
    }
  },
  mounted() {
    this.updateInternalOptions();

    if (!this.focusOnMount || !this.searchable) {
      return;
    }

    setTimeout(() => this.edit(), 500);
  },
  methods: {
    updateInternalOptions() {
      // These options should come with "checked"
      const opts = this.options == null ? [] : this.options;

      // Parse the options and map to something we expect
      this.internalItemOptions = opts.map((val) => {
        const key = `${Math.round(Math.random() * 1000000)}`;
        if (typeof val === 'object') {
          return { ...val, key };
        }

        return { checked: false, value: val, label: val, key };
      });
    },
    getCurrentValues() {
      const internalOptions =
        this.internalItemOptions == null ? [] : this.internalItemOptions;

      return (this.options == null ? [] : this.options)
        .filter((v) => {
          if (v == null) {
            return false;
          }

          const found = internalOptions.find((internalV) => {
            if (!internalV.checked) {
              return false;
            }

            if (typeof v === 'object') {
              return internalV.value === v.value;
            }

            return internalV.value === v;
          });
          return found != null;
        })
        .map((v) => {
          v.checked = true;
          return v;
        });
    },

    informChanges() {
      if (this.timerInformChanges != null) {
        clearTimeout(this.timerInformChanges);
      }

      this.timerInformChanges = setTimeout(() => {
        this.timerInformChanges = null;

        const finalValues = this.getCurrentValues();

        // Already emitted the same items, no need to do it again
        if (deepEqual(this.oldItemsEmission, finalValues) && this.cache) {
          return;
        }

        this.oldItemsEmission = finalValues;
        this.$emit('change', finalValues);
      }, 100);
    },

    toggleDropdown() {
      if (this.showDropdown) {
        this.closeDropdown();
      } else {
        this.openDropdown();
      }
    },

    openDropdown() {
      // Position fixed is always relative to the viewport
      // Which means we can't actually use something like "width: 100%"
      // So we need to compute the min-width so it stays with at least
      // The width of the actual dropdown select
      if (this.positionStrategy === 'fixed') {
        const ref: any = this.$refs['vue-dropdown'];
        const width = ref.clientWidth;
        let count = 0;

        const updateFn = () => {
          if (!this.showDropdown) {
            return;
          }

          const els = ref.getElementsByClassName(['vue-select-dropdown__body']);
          if (els == null || els.length === 0) {
            // We only want to try 5 times
            if (count < 5) {
              count += 1;

              // Lets retry in a bit, maybe it isn't rendered yet
              setTimeout(() => updateFn(), 50);
            }
            return;
          }

          for (let i = 0; i < els.length; i += 1) {
            els[i].style.minWidth = `${width}px`;
          }
        };

        this.$nextTick(() => updateFn());
      }

      this.showDropdown = true;
    },

    closeDropdown() {
      if (!this.showDropdown) {
        // No need to close if already closed
        return;
      }

      this.searching = false;
      this.showDropdown = false;
      this.keyword = '';

      this.informChanges();
    },
    selectOption(option: IItemOption) {
      const internalItemOptions =
        this.internalItemOptions == null ? [] : this.internalItemOptions;

      for (let i = 0; i < internalItemOptions.length; i += 1) {
        // Is it the same option? check it
        if (internalItemOptions[i].key === option.key) {
          if (!internalItemOptions[i].checked || this.multiple) {
            internalItemOptions[i].checked = !internalItemOptions[i].checked;
          }
        } else if (!this.multiple) {
          // Should check that no other is checked in case of not being multiple
          internalItemOptions[i].checked = false;
        }
      }

      if (!this.multiple) {
        this.closeDropdown();
      }

      if (this.searchable) {
        // Always focus after clicking on an option
        setTimeout(() => {
          // TODO: improve this any by typing it
          (this.$refs['search-input'] as any).focus();
        }, 50);
      }
    },
    start() {
      if (this.searching) {
        this.openDropdown();
      } else {
        this.toggleDropdown();
      }
    },
    edit() {
      this.keyword = '';
      this.searching = true;
      this.openDropdown();

      setTimeout(() => {
        // TODO: improve this any by typing it
        (this.$refs['search-input'] as any).focus();
      }, 50);
    }
  }
});
