import * as t from 'io-ts';
import Route from './Route.class';
import Model from './model';

export type RequestMethodType = 'post' | 'put' | 'patch' | 'delete' | 'get';

export const basicResponseModel = new Route({
  response: new Model(t.any)
});

export class ResourceConfig<
  GET extends Route,
  POST extends Route,
  PUT extends Route,
  PATCH extends Route,
  DELETE extends Route
> implements Record<RequestMethodType, any> {
  /**
   * If the request includes the resource in the path.
   *
   * @default true
   */
  public namespaced = true;
  /**
   * Configuration for the get route
   */
  public get!: GET;
  /**
   * Configuration for the post route
   */
  public post!: POST;
  /**
   * Configuration for the put route
   */
  public put!: PUT;
  /**
   * Configuration for the patch route
   */
  public patch!: PATCH;
  /**
   * Configuration for the delete route
   */
  public delete!: DELETE;

  public constructor(
    config: Partial<ResourceConfig<GET, POST, PUT, PATCH, DELETE>>
  ) {
    // Need to do this separately so we can get the correct types inferred.
    const defaults: ResourceConfig<any, any, any, any, any> = {
      namespaced: true,
      delete: basicResponseModel,
      get: basicResponseModel,
      patch: basicResponseModel,
      post: basicResponseModel,
      put: basicResponseModel
    };

    Object.assign(this, defaults, config);
  }
}
