


































import Vue, { PropOptions } from 'vue';
import UIRuleSelectorModalIcon from './icon/UIRuleSelectorModalIcon.vue';

/**
 * The sidebar component is a flexible container that has a header, optional back arrow, content area and footer.
 */
const UIRuleSelectorModalSidebar = Vue.extend({
  name: 'UIRuleSelectorModalSidebar',
  components: {
    UIRuleSelectorModalIcon
  },
  props: {
    /**
     * When true the `back-arrow` prop adds a back arrow icon to the header. This will emit an event when clicked which you can listen to.
     */
    hasBackArrow: {
      type: Boolean,
      default: false
    } as PropOptions<boolean>,
    /**
     * Adds a title to the top of the sidebar.
     */
    title: {
      type: String,
      default: ''
    } as PropOptions<string | undefined>
  },
  computed: {
    titleId(): string {
      return `title-${this._uid}`;
    }
  }
});

export default UIRuleSelectorModalSidebar;
