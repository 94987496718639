





























import Vue from 'vue';

const TheRulesetFooter = Vue.extend({
  name: 'TheRulesetFooter',
  props: {
    canAddGroup: {
      type: Boolean,
      required: true
    },
    canRemoveQuery: {
      type: Boolean,
      required: true
    }
  }
});

export default TheRulesetFooter;
