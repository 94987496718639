import { isArray, isPlainObject } from 'lodash';

/**
 * Nullifies the values inside a data value
 * @param {T} data
 * @param {string[]} safeKeys
 * @param {string[]} removeKeys
 * @returns {T}
 */
const nullifyValues = <T>(
  data: T,
  safeKeys: string[] = [],
  removeKeys: string[] = []
  // TODO: proper typing T
): T | any | null => {
  const clonedData = JSON.parse(JSON.stringify(data));

  // Handle the arrays
  if (isArray(clonedData)) {
    return clonedData.map((single) =>
      nullifyValues(single, safeKeys, removeKeys)
    );
  }

  // Handle objects
  if (isPlainObject(clonedData)) {
    const newObj = {};

    // We sort th ekeys so we know we have a common ground for comparisons
    const keys = Object.keys(clonedData).sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });

    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];

      const foundRemoveKey =
        removeKeys.filter((removeKey) => removeKey === key).length > 0;
      if (foundRemoveKey) {
        continue;
      }

      const foundSafeKey =
        safeKeys.filter((safeKey) => safeKey === key).length > 0;

      const value = clonedData[key];
      newObj[key] = foundSafeKey
        ? value
        : nullifyValues(value, safeKeys, removeKeys);
    }

    return newObj as T;
  }

  // Handle all the other values by nullifying them
  return null;
};

export default nullifyValues;
