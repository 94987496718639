import * as t from 'io-ts';

export const timestampSchema = {
  /**
   * The resource creation timestamp
   */
  created_at: t.string,
  /**
   * Timestamp of when the resource was last updated.
   */
  updated_at: t.union([t.string, t.null]),
  /**
   * The resource deletion timestamp.
   *
   * When this value is defined, the resource should be treated as deleted.
   */
  deleted_at: t.union([t.string, t.null])
};
