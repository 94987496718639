import ilyAxios from '../../../sdk/request';
import { FeatureTemplate } from '../../es-query/libs/types';
import { storeApi } from '../../index';

/**
 * Fetches Feature templates (segments)
 */
export const fetchFeatures = async () => {
  const { mutations } = storeApi.featureSegments;

  try {
    const { data: features } = await ilyAxios.get<FeatureTemplate[]>(
      '/explorer/features'
    );

    mutations.setFeatures(features);
    mutations.featuresAreReady();
    return Promise.resolve(features);
  } catch (e) {
    mutations.featuresAreNotReady();
    throw e;
  }
};
