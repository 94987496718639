import UIVueToaster from './UIVueToaster.vue';
import { ToastType, ToastConfig } from './types';

// NOTE: I couldn't link the type of the component method since it was referencing itself in the type annotation... Maybe future versions of typescript will allow that.
declare module 'vue/types/vue' {
  interface Vue {
    /**
     * Sends a global toast message of the defined type.
     *
     * @param message - Message to display in the toast.
     * @param type - Type of the toast, the default type is 'success'.
     * @param config - Extra configuration for the toast, empty by default.
     */
    $toast: (message: string, type?: ToastType, config?: ToastConfig) => void;
  }
}

/**
 * Plugin for injecting a global toast message manager.
 */
const ToastPlugin = {
  install(Vue) {
    if (document) {
      const container = document.createElement('div');
      container.id = 'js-vue-toaster-container';
      document.body.appendChild(container);
      const VueToasterClass = Vue.extend(UIVueToaster);
      const vueToasterInstance = new VueToasterClass();
      vueToasterInstance.$mount(`#${container.id}`);
      Vue.prototype.$toast = vueToasterInstance.toast;
    }
  }
};

export default ToastPlugin;
