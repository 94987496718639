
































import Vue from 'vue';
import {
  UICheckbox,
  UIBasicTextInput,
  UIInputLabel
} from '@intricately/ui-lib';

const TheSaveModeForm = Vue.extend({
  name: 'TheSaveModeForm',
  components: {
    UICheckbox,
    UIBasicTextInput,
    UIInputLabel
  },
  props: {
    description: {
      type: String,
      required: true
    },
    publicRule: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    computedPublicRule: {
      get() {
        return this.publicRule;
      },
      set(val) {
        this.$emit('public-checked', val);
      }
    } as any,
    computedDescription: {
      get() {
        return this.description;
      },
      set(val) {
        this.$emit('description-edited', val);
      }
    }
  }
});

export default TheSaveModeForm;
