import UIVueModal from './UIVueModal.vue';

declare module 'vue/types/vue' {
  interface Vue {
    $showModal: (
      component: any,
      properties?: any,
      componentMethods?: any
    ) => void;
    $hideModal: () => void;
  }
}

const ModalPlugin = {
  install(Vue, options) {
    if (document) {
      const modalContainer = document.createElement('div');
      modalContainer.id = 'js-vue-modal-container';
      document.body.appendChild(modalContainer);
      const VueModalClass = Vue.extend(UIVueModal);
      const vueModalInstance = new VueModalClass({
        parent: options.parent
      });
      vueModalInstance.$mount('#js-vue-modal-container');

      Vue.prototype.$showModal = vueModalInstance.show;
      Vue.prototype.$hideModal = vueModalInstance.hide;
    }
  }
};

export default ModalPlugin;
