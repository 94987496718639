





















import Vue from 'vue';
import { ToastType, ToastConfig } from './types';

const UIVueToaster = Vue.extend({
  name: 'UIVueToaster',
  data() {
    return {
      allowedTypes: ['success', 'info', 'warning', 'error'],
      messages: [] as {
        message: string;
        onClick: () => void;
        key: string;
        type: string;
        show: boolean;
      }[]
    };
  },
  methods: {
    toast(
      message: string,
      type: ToastType = 'success',
      config: ToastConfig = {}
    ) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this as any;

      if (message == null) {
        // eslint-disable-next-line
        console.error('[Vue warn]: vue-toaster is missing required prop');
        return;
      }

      if (
        typeof message !== 'string' ||
        vm.allowedTypes.find((t) => t === type) == null
      ) {
        // eslint-disable-next-line
        console.error('[Vue warn]: vue-toaster unexpected type');
        return;
      }

      if (vm.messages.length === 3) {
        vm.messages.pop();
      }

      const newMessage = {
        type,
        message,
        key: Math.round(Math.random() * 10000),
        onClick: config.onClick
      };

      vm.messages = [newMessage].concat(vm.messages);

      if (!config.sticky && type !== 'error') {
        setTimeout(
          () => vm.remove(newMessage.key),
          config.timeout == null ? 3000 : config.timeout
        );
      }
    },

    remove(key: string, onClick: () => void): void {
      this.messages = this.messages.filter((message) => message.key !== key);
      if (onClick != null && typeof onClick === 'function') {
        onClick();
      }
    }
  }
});

export default UIVueToaster;
