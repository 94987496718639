













import _ from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 200
    },
    useKeyup: {
      type: Boolean,
      default: false
    },
    focusOnMount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lazyTimeout: null as any,
      myValue: ''
    };
  },
  watch: {
    value() {
      if (!_.isNil(this.value)) {
        this.myValue = this.value;
      }
    }
  },
  mounted() {
    if (!_.isNil(this.value)) {
      this.myValue = this.value as string;
    }

    if (!this.focusOnMount) {
      return;
    }

    setTimeout(() => {
      (this.$refs.text as HTMLInputElement).focus();
    }, 500);
  },
  methods: {
    focus() {
      (this.$refs.text as HTMLInputElement).focus();
    },
    update() {
      this.$emit('input', this.myValue);
    },
    onKeyup() {
      if (!this.useKeyup) {
        return;
      }

      if (this.maxLength > 0) {
        const stringified = this.myValue.toString();
        if (stringified.length > this.maxLength) {
          this.myValue = this.myValue.slice(0, this.maxLength);
        } else {
          this.lazyKeyup();
        }
      } else {
        this.lazyKeyup();
      }
    },
    onBlur() {
      this.$emit('blur');

      if (this.useKeyup) {
        return;
      }

      if (this.maxLength > 0) {
        const stringified = this.myValue.toString();
        if (stringified.length > this.maxLength) {
          this.myValue = this.myValue.slice(0, this.maxLength);
        } else {
          this.update();
        }
      } else {
        this.update();
      }
    },
    lazyKeyup() {
      if (this.lazyTimeout != null) {
        clearTimeout(this.lazyTimeout);
      }

      this.lazyTimeout = setTimeout(() => this.update(), 100);
    }
  }
});
