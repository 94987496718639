import AggsBlockManager, { AggsBlockTree } from './libs/aggs-block-manager';
import QueryBlockManager from './libs/query-block-manager';
import SegmentPreview from './libs/segment-preview';
import TemplatesManager from './libs/templates-manager';
import HitsManager from './libs/hits-manager';
import {
  FeatureTemplate,
  QueryRawTree,
  FeatureRawTree,
  AggsStateTree,
  Hits,
  Label,
  AggsDataUpdateAgg,
  AggsDataUpdateConfig,
  QueryStateGroupTree,
  QueryDataUpdateConfig,
  EsQueryRaw
} from './libs/types';
import QueryBlockGroup from './libs/query-block-group';
import AggsBlock from './libs/aggs-block';
import { IParsedQueryBlockGroup } from '../../API/models/queryUI/queryBlockGroup';
import { IQueryBlock } from '../../API/models/queryUI/queryBlock';
import { IOldRule, IParsedOldRule } from '../modules/query-templates/actions';
import { omit } from '../../utils/omit';
import { Ruleset } from '../../API/models/ruleset/ruleset';

/**
 * The esQuery is a manager for the data that goes to the state
 *
 * it is responsible for comminicating with other managers:
 * - AggsBlockManager: Manages aggregations (Fill Rates)
 * - QueryBlockManager: Manages the query ui used for creating the marked builder ui.
 * - TemplatesManager: Manages the query templates (rules)
 */
export default class EsQuery {
  private abm: AggsBlockManager;
  private qbm: QueryBlockManager;
  private segprev: SegmentPreview;
  private templates: TemplatesManager;
  private hits: HitsManager;

  public constructor({
    features = [] as FeatureTemplate[],
    queries = [] as IOldRule[]
  }) {
    this.abm = new AggsBlockManager();
    this.qbm = new QueryBlockManager();
    this.segprev = new SegmentPreview([]);
    this.templates = new TemplatesManager({
      queries,
      features
    });
    this.hits = new HitsManager({});
  }

  /**
   * Converts the query ui (query blocks) to a ES query that the backends understands.
   * This will later be used on routes like "/market"
   * 
   * @example
   *  nested: {
        path: 'use_cases',
        query: {
          bool: {
            must: [
              {
                term: {
                  'use_cases.api_name': use_case_name
                }
              },
              {
                range: {
                  'use_cases.traffic': { gte: min, lt: max }
                }
              }
            ]
          }
        }
      }
   */
  private getRaw(
    params: {
      /**
       * Buckets are the segments/templates.
       *
       * A query is from bucket when it is related to a segment.
       */
      isntFromBuckets?: boolean;
    } = {}
  ): EsQueryRaw | Omit<QueryRawTree, 'query'> {
    const query = this.qbm.get('raw', params) as QueryRawTree;
    const res = {
      size: 5,
      sort: [
        {
          'report.spend': {
            order: 'desc'
          }
        }
      ],

      _source: [
        'name',
        'logo_url',
        'employees.count',
        'growth_v1',
        'report.spend'
      ],
      aggs: this.abm.get('raw', true) as FeatureRawTree,
      query
    };

    // Need to add the fill rates using the templates
    const queryTemplates = this.templates.get('query') as IOldRule[];
    for (let i = 0; i < queryTemplates.length; i += 1) {
      const tmpl = queryTemplates[i];

      // Ignore the ones with inputs
      if (tmpl.inputs) {
        continue;
      }

      res.aggs[tmpl.id] = {
        filter: {
          ...tmpl.elasticsearch_template
        }
      };
    }

    return res.query ? res : omit(res, ['query']);
  }

  /**
   * Converts "es-query" data to a "state" that the backend understands
   * This will later be used on routes like "/state"
   */
  private getState(
    params: {
      isntFromBuckets?: boolean;
    } = {}
  ) {
    return {
      aggs: this.abm.get('state'),
      query: this.qbm.get('state', params)
    };
  }

  /**
   * Get proxy for all the managers
   */
  public get(type?: '', params?: { block?: 'query' }): IParsedQueryBlockGroup;
  public get(type: undefined, params: { block: 'aggs' }): AggsBlockTree[];
  public get(type: '', params: { block: 'aggs' }): AggsBlockTree[];
  public get(
    type: 'raw',
    params: { isntFromBuckets?: boolean }
  ): ReturnType<EsQuery['getRaw']>;
  public get(
    type: 'state',
    params: { isntFromBuckets?: boolean }
  ): ReturnType<EsQuery['getState']>;
  public get(type: 'templates', params?: { block?: 'query' }): IParsedOldRule[];
  public get(
    type: 'templates',
    params: { block: 'features' }
  ): FeatureTemplate[];
  public get(type: 'preview'): ReturnType<EsQuery['segprev']['get']>;
  public get(type: 'hits'): ReturnType<EsQuery['hits']['get']>;
  public get(type: 'aggs'): FeatureRawTree;
  public get(
    type: 'raw' | 'state' | 'templates' | 'preview' | 'hits' | 'aggs' | '' = '',
    params: {
      block?: 'features' | 'aggs' | 'query';
      isntFromBuckets?: boolean;
    } = {}
  ) {
    if (type === 'raw') {
      return this.getRaw(params);
    }

    if (type === 'state') {
      return this.getState(params);
    }

    if (type === 'templates') {
      if (params.block === 'features' || params.block === 'aggs') {
        return this.templates.get('aggs');
      }

      return this.templates.get('query');
    }

    if (type === 'preview') {
      return this.segprev.get();
    }

    if (type === 'hits') {
      return this.hits.get();
    }

    if (type === 'aggs') {
      return this.abm.get('raw');
    }

    return params.block === 'aggs' ? this.abm.get() : this.qbm.get();
  }

  /**
   * Add proxy for all the managers
   */
  public add(type?: '', params?: Record<string, any>): undefined;
  public add(
    type: 'aggs',
    params: {
      value?: FeatureTemplate;
      templateId?: string;
      templateIdentity?: string;
      addToTop?: boolean;
    }
  ): AggsBlock;
  public add(
    type: 'query-group',
    params: {
      removeAllFromBuckets?: boolean;
      removeIsFromBucket?: boolean;
      queryGroup?: Ruleset['attributes']['query_template'];
      parentIdentity?: string;
    }
  ): QueryBlockGroup;
  public add(
    type: 'query',
    params: {
      templateIdentity?: string;

      removeAllFromBuckets?: boolean;
      removeIsFromBucket?: boolean;

      isFromBucket?: boolean;
      parentIdentity?: string;
      query?: IOldRule;
      state?: QueryStateGroupTree | IQueryBlock;
    }
  ): QueryBlockGroup;
  public add(
    type: 'aggs' | 'query-group' | 'query' | '' = '',
    params: {
      value?: FeatureTemplate;
      templateId?: string;
      templateIdentity?: string;
      parentIdentity?: string;
      queryGroup?: Ruleset['attributes']['query_template'];

      type?: '' | 'state';
      templates?: TemplatesManager;

      addToTop?: boolean;

      removeAllFromBuckets?: boolean;
      removeIsFromBucket?: boolean;
      isFromBucket?: boolean;
      query?: QueryStateGroupTree | IQueryBlock | IOldRule;
      state?: QueryStateGroupTree | IQueryBlock;
      isRoot?: boolean;
    } = {}
  ) {
    if (type === 'aggs') {
      const feature =
        params.value != null
          ? params.value
          : this.templates.get('aggs', {
              identity: params.templateIdentity,
              templateId: params.templateId
            });

      return this.abm.add(feature, { addToTop: params.addToTop });
    }

    if (type === 'query-group') {
      return this.qbm.add('group', params);
    }

    if (type === 'query') {
      const query = this.templates.get('query', {
        identity: params.templateIdentity
      });

      return this.qbm.add('query', {
        ...params,
        query
      });
    }

    return undefined;
  }

  /**
   * Destroy proxy for all the managers
   */
  public destroy(type?: '', params?: Record<string, any>): undefined;
  public destroy(
    type: 'aggs',
    params: { parentIdentity?: string; identity?: string; all?: boolean }
  ): void;
  public destroy(type: 'query', params: { identity?: string }): void;
  public destroy(
    type: 'aggs' | 'query' | '' = '',
    params: {
      parentIdentity?: string;
      identity?: string;
      all?: boolean;
    } = {}
  ) {
    if (type === 'aggs') {
      return this.abm.destroy(params);
    }

    if (type === 'query') {
      const id = typeof params === 'object' ? params.identity : params;
      return this.qbm.destroy(id);
    }

    return undefined;
  }

  /**
   * Update proxy for all the managers
   */
  public update(type?: '', params?: Record<string, any>): undefined;
  public update(
    type: 'state',
    params: {
      query?: QueryStateGroupTree | IQueryBlock;
      aggs?: AggsStateTree[];
    }
  ): undefined;
  public update(type: 'labels', params: { labels: Label[] }): AggsBlockTree[];
  public update(
    type: 'aggs',
    params: {
      value?: AggsDataUpdateAgg;
      config?: AggsDataUpdateConfig;
      parentIdentity?: string;
      identity?: string;
      type?: 'order' | 'labels' | '';
      order?: string[];
    }
  ): void | null | AggsBlock;
  public update(
    type: 'hits',
    params: {
      hits?: Hits;
    }
  ): void;
  public update(
    type: 'query',
    params: {
      identity?: string;
      config?: QueryDataUpdateConfig;
    }
  ): QueryBlockGroup;
  public update(
    type: 'state' | 'labels' | 'aggs' | 'query' | 'hits' | '' = '',
    params: {
      value?: AggsDataUpdateAgg;
      config?: AggsDataUpdateConfig | QueryDataUpdateConfig;
      labels?: Label[];
      parentIdentity?: string;
      identity?: string;
      hits?: Hits;
      aggs?: AggsStateTree[];
      query?: QueryStateGroupTree | IQueryBlock;

      type?: 'order' | 'labels' | '';
      order?: string[];
    } = {}
  ) {
    if (type === 'state') {
      if (params.query != null) {
        this.qbm.add('state', {
          templates: this.templates,
          query: params.query
        });
      }

      if (params.aggs != null) {
        this.abm.add(params.aggs, { templates: this.templates, type: 'state' });
      }

      return undefined;
    }

    if (type === 'labels') {
      return this.abm.update(params.labels as Label[], {
        ...params,
        type: 'labels'
      });
    }

    if (type === 'aggs') {
      return this.abm.update(
        {
          agg: params.value,
          config: params.config
        },
        params
      );
    }

    if (type === 'query') {
      return this.qbm.update(
        params.identity,
        params.config as QueryDataUpdateConfig
      );
    }

    if (type === 'hits') {
      if (params.hits != null && params.hits.hits != null) {
        this.segprev.update(params.hits.hits);
      }

      return this.hits.update(params.hits);
    }

    return undefined;
  }
}
