import * as t from 'io-ts';
import { rulesetAttributesSchema } from './ruleset';
import pick from '../../../utils/pick';
import Model from '../../classes/model';
import { jsonApiResource } from '../../jsonApi/models/resource';
import { ResourceType } from '../../clients/resourceTypes.enum';
import { ModelInterface } from '../interfaceGetters/modelInterface';
import { requiredJsonApiRelationshipSchema } from '../../jsonApi/models/relationship';

/**
 * Required attributes for creating a ruleset.
 */
export const requiredPostAttributesSchema = pick(rulesetAttributesSchema, [
  'title',
  'visibility',
  'description',
  'query_template'
]);

/**
 * Schema for the body of a POST request for the ruleset resource.
 */
export const rulesetPostRequestBodySchema = jsonApiResource({
  hideId: true,
  type: ResourceType.explorer_rule_set,
  attributes: t.interface(requiredPostAttributesSchema),
  relationships: t.interface(
    requiredJsonApiRelationshipSchema(ResourceType.user)
  )
});

/**
 * Model describing the payload using for a POST request in the rule_sets endpoint.
 */
export const rulesetPostPayloadModel = new Model(
  t.interface(rulesetPostRequestBodySchema)
);

export type RulesetPostPayload = ModelInterface<typeof rulesetPostPayloadModel>;
