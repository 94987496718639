






import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: null as any
    }
  },
  computed: {
    style() {
      const bg = this.value
        ? '/images/checkbox-on.svg'
        : '/images/checkbox-off.svg';
      return {
        'background-image': `url(${bg})`
      };
    }
  },
  methods: {
    update() {
      // TODO: find a better way to set these any
      this.$emit('input', (this.$refs.checkbox as any).checked);
    }
  }
});
