

























































import Vue, { PropOptions } from 'vue';

import UIRuleSelectorModalIcon from '../icon/UIRuleSelectorModalIcon.vue';
import { UIBadge } from '@intricately/ui-lib';

const states = ['normal', 'searched'] as const;

/**
 * The NavItem component supplies several props for you to flesh out each item. We'll go through them one by one. First, use the `text` prop to add text to your NavItem.
 */
const UIRuleSelectorModalNavItem = Vue.extend({
  name: 'UIRuleSelectorModalNavItem',

  components: {
    UIRuleSelectorModalIcon,
    UIBadge
  },

  props: {
    /**
     * Adds an icon to the left of the text. This uses the UIRuleSelectorModalIcon component under the hood so you must supply a valid option for the icon component.
     */
    icon: {
      type: String,
      default: ''
    },
    muted: {
      type: Boolean,
      default: false
    },
    /**
     * The text for your NavItem
     */
    text: {
      type: String,
      default: ''
    },
    /**
     * Adds a string of additional text beneath the default text. Renders at a slightly smaller size.
     */
    subtext: {
      type: String,
      default: ''
    },
    /**
     * Renders a badge on the right side of the NavItem. Will give the NavItem a muted appearance if set to 0.
     */
    count: {
      type: [Number, undefined],
      default: undefined
    } as PropOptions<number | undefined>,
    /**
     * Used to change the overall appearance of the NavItem.
     */
    state: {
      type: String,
      default: 'normal',
      validator: (value) => states.includes(value)
    } as PropOptions<typeof states[number]>
  },
  computed: {
    badgeTemplate(): string {
      return this.state === 'searched' ? 'framed' : 'hollow';
    },
    isEmpty(): boolean {
      return this.count === 0 ? true : false;
    },
    ids(): { text: string; subtext: string | false; badge: string | false } {
      return {
        text: `text-${this._uid}`,
        subtext: this.subtext ? `subtext-${this._uid}` : false,
        badge: this.count ? `badge-${this._uid}` : false
      };
    }
  }
});
export default UIRuleSelectorModalNavItem;
