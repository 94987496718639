
import Vue from 'vue';

/**
 * A functional component that applies CSS classes to children components.
 */
const ApplyClass = Vue.extend({
  name: 'ApplyClass',
  functional: true,
  props: {
    class: { type: String, default: '' }
  },
  render(h, ctx) {
    return ctx.children.map((child) => {
      if (child.data && child.tag) {
        child.data.staticClass = child.data.staticClass || '';
        child.data.staticClass += ' ' + ctx.data.staticClass;
      }

      return child;
    });
  }
});

export default ApplyClass;
