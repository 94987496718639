// eslint-disable-next-line @typescript-eslint/no-empty-function
export const copyToClipboard = (text: string, fn = () => {}) => {
  const txt = document.createElement('textarea');
  txt.value = text;
  txt.setAttribute('readonly', '');
  txt.style.position = 'absolute';
  txt.style.left = '-9999px';
  document.body.appendChild(txt);
  txt.select();
  document.execCommand('copy');
  document.body.removeChild(txt);

  fn();
};
