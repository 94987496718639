import { ReportsState } from './state';

/**
 * Sorts reports coming in by date
 * @param {ReportsState['reportsList']} list
 */
const sortReports = (
  list: ReportsState['reportsList']
): ReportsState['reportsList'] => {
  return list.sort((a, b) => {
    const aDate = new Date(a.attributes.created_at as any).getTime();
    const bDate = new Date(b.attributes.created_at as any).getTime();

    return aDate < bDate ? 1 : -1;
  });
};

export const setUserEnrichments = (
  state: ReportsState,
  payload: typeof state['userEnrichments']
) => {
  state.userEnrichments = payload;
};

export const setReportFieldSets = (
  state: ReportsState,
  payload: typeof state['reportFieldSets']
) => {
  state.reportFieldSets = payload;
};

export const setReportsList = (
  state: ReportsState,
  payload: typeof state['reportsList']
) => {
  state.reportsList = sortReports(payload);
};

export const updateReport = (
  state: ReportsState,
  payload: typeof state['reportsList'][0]
) => {
  let found = false;

  for (let i = 0; i < state.reportsList.length; i += 1) {
    const single = state.reportsList[i];

    if (`${single.id}` === `${payload.id}`) {
      state.reportsList[i] = payload;
      found = true;
      break;
    }
  }

  if (!found) {
    state.reportsList.push(payload);
  }

  state.reportsList = sortReports(state.reportsList);
};
