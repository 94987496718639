import EsQuery from '../../../es-query/index';
import { FeatureTemplate } from '../../../es-query/libs/types';
import { IOldRule } from '../../query-templates/actions';

let esManager: EsQuery;

/**
 * Initialize the esManager instance with the provided features (segments) and queries (rules)
 */
export const initialize = (
  features?: FeatureTemplate[],
  queries?: IOldRule[]
) => {
  if (esManager != null) {
    return esManager;
  }

  esManager = new EsQuery({ features, queries });

  return esManager;
};

/**
 * Gets the esManager instance
 */
export const getQ = () => esManager;

/**
 * Checks if the esManager instance is initialized
 */
export const isQInitialized = () => esManager != null;
