











import Vue, { PropOptions } from 'vue';

const types = ['action', 'control'] as const;

/**
 * A hyperlink like button.
 *
 * An _action_ UILink represents a Call To Action.
 * A _control_ UILink represents controls to the parent section (e.g. dismisses a banner).
 */
const UILink = Vue.extend({
  props: {
    type: {
      type: String,
      required: true,
      validate: (value) => types.includes(value)
    } as PropOptions<typeof types[number]>
  }
});

export default UILink;
