














import Vue from 'vue';
import TheAppHeader from './components/TheAppHeader.vue';
import { EventBus, GlobalEvents } from './plugins/event-bus';
import { storeApi } from './store';
import { Components } from '../typings/openapi/explorer_download_history';
import Logger from './logger/Logger.class';

const { prodError } = new Logger('App init');

const TheApp = Vue.extend({
  name: 'TheApp',
  components: {
    TheAppHeader
  },
  data() {
    const userId = storeApi.appSettings.getters.authUserId;
    return {
      hasBanner: false,
      hasStickyHeader: false,
      // DEV: Vetur is complaining for some reason
      // TODO: investigate how to fix it
      [userId]: (this as any).$pnGetMessage(userId, (this as any).pubnubHandler)
    };
  },
  computed: {
    loading(): boolean {
      return storeApi.appSettings.getters.loading;
    },
    userId(): string {
      return storeApi.appSettings.getters.authUserId;
    },
    isLoggedIn(): boolean {
      const session = localStorage.getItem('session');
      return session != null && session.length > 0;
    },
    hasStickyHeaderAndBanner(): boolean {
      return this.hasBanner && this.hasStickyHeader;
    },
    routerContainerClassName(): string {
      if (this.hasBanner && this.hasStickyHeader) {
        return 'router-view-container--banner-sticky-header';
      }
      if (this.hasBanner) {
        return 'router-view-container--banner';
      }
      if (this.hasStickyHeader) {
        return 'router-view-container--sticky-header';
      }

      return '';
    }
  },
  created(): void {
    EventBus.$on(GlobalEvents.showAppBanner, () => {
      this.hasBanner = true;
    });
    EventBus.$on(GlobalEvents.hideAppBanner, () => {
      this.hasBanner = false;
    });
    EventBus.$on(GlobalEvents.showStickyHeader, () => {
      this.hasStickyHeader = true;
    });
    EventBus.$on(GlobalEvents.hideStickyHeader, () => {
      this.hasStickyHeader = false;
    });
  },
  mounted() {
    // TODO: should improve this any
    (this as any).$pnSubscribe({
      channels: [this.userId]
    });

    console.log({ storeApi });

    // Check if the url is staging or not
    // const url = window.location.href;
    // const isProduction = url.indexOf('explorer.intricately.com') !== -1;

    // Setting the requests to go as default to production
    return storeApi.state.actions.setIsProduction({ value: true });
  },
  updated() {
    document.title = storeApi.appSettings.getters.title;
  },
  methods: {
    pubnubHandler(data?: {
      message?: {
        type?: string;
        data?: string | Components.Schemas.Download;
      };
    }) {
      if (data == null || data.message == null) {
        return;
      }

      switch (data.message.type) {
        case 'sticky_toast_message':
          this.handleToast(data.message.data as string);
          break;
        case 'explorer_report_download_request':
          const parsedData = data.message.data as Components.Schemas.Download;

          if (parsedData) {
            storeApi.reports.actions.fetchEnrichments().catch(prodError);
          }

          this.handleReportUpdate(
            parsedData.id,
            parsedData.progress,
            parsedData.status
          );
          break;
        default:
        // DEV: do nothing in this case
      }
    },
    handleToast(message?: string) {
      if (message == null || message === '') {
        return;
      }

      const link = message.match(/href='([^']*)/);
      const id = link == null ? null : this.getStringDownloadId(link[1]);

      if (id != null && id.length > 0) {
        const string =
          '<a style="color: #fff;" href="#">Click here to download your report!</a>';
        this.$toast(string, 'success', {
          sticky: true,
          onClick: () => storeApi.state.actions.downloadReport(id)
        });
      } else {
        const string = `<a style="color: #fff;" href="${link}">Click here to download your report!</a>`;
        this.$toast(string, 'success', {
          sticky: true
        });
      }
    },
    handleReportUpdate(
      id?: string | number,
      progress?: number,
      status?: Components.Schemas.Download['status']
    ) {
      if (id === undefined || progress === undefined || !status) return;

      storeApi.reports.actions
        .updateReportProgress({
          progress,
          status,
          reportId: id + ''
        })
        .catch(() => {
          this.$toast('There was an error updating your report', 'error');
        });
    },
    getStringDownloadId(hrefString = ''): string | null {
      if (hrefString) {
        let str = hrefString;
        if (hrefString.indexOf('?') > -1) {
          const splitByQ = hrefString.split('?');
          [str] = splitByQ;
        }

        if (str == null) {
          return null;
        }

        const res = str.match(/\d+/);
        return res == null ? null : res[0];
      }

      return '';
    }
  }
});

export default TheApp;
