















import Vue from 'vue';
import _ from 'lodash';

export default Vue.extend({
  data() {
    return {
      showing: false,
      component: null,
      properties: {},
      componentMethods: {}
    };
  },
  mounted() {
    document.addEventListener('keyup', this.onKeyup);
  },
  methods: {
    show(component, properties = {}, componentMethods = {}) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;

      if (component) {
        vm.properties = properties;
        vm.componentMethods = componentMethods;
        vm.component = component;
        vm.showing = true;
        Vue.set(document.body.style, 'overflow', 'hidden');
      }
    },
    hide() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;

      vm.showing = false;
      vm.component = null;
      vm.properties = {};
      vm.componentMethods = {};
      Vue.set(document.body.style, 'overflow', 'initial');
    },
    onKeyup(event) {
      if (_.get(event, 'keyCode') === 27 && this.showing) {
        this.hide();
      }
    }
  }
});
