import { MarketInputsState } from './state';

/**
 * Cached list result
 * @param {MarketInputsState} state
 * @returns {any}
 */
export const cachedList = (state: MarketInputsState) => (key: string) => {
  if (key != null && key.length > 0) {
    return state.listCache[key];
  }

  return [];
};
