import { Hits } from './types';

export default class HitsManager {
  private myHits: Hits = {};

  /**
   * Initializes the hits manager
   * @param {Hits} hits
   */
  public constructor(hits: Hits = {}) {
    this.myHits = hits;
  }

  /**
   * Updates hits
   * @param {Hits} newHits
   */
  public update(newHits: Hits = {}) {
    if (Object.keys(newHits).length > 0) {
      this.myHits = newHits;
    }
  }

  /**
   * Gets hits
   * @returns {Hits}
   */
  public get() {
    // TODO: should we treat the data coming in?
    return this.myHits;
  }
}
