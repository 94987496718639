import { MarketInputsState } from './state';

/**
 * Resets the cache list
 * @param {MarketInputsState} state
 */
export const clearListCache = (state: MarketInputsState) => {
  state.listCache = {};
};

/**
 * Caches a list
 * @param {MarketInputsState} state
 * @param {object} payload
 */
export const cacheList = (
  state: MarketInputsState,
  payload: { key: string; list: any }
) => {
  state.listCache[payload.key] = payload.list;
};
