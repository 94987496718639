import UIVueGlobalSlider from './UIVueGlobalSlider.vue';

const SliderPlugin = {
  install(Vue, options) {
    if (document) {
      const sliderContainer = document.createElement('div');
      sliderContainer.id = 'js-vue-global-slider-container';
      document.body.appendChild(sliderContainer);
      const VueGlobalSliderClass = Vue.extend(UIVueGlobalSlider);
      const slider = new VueGlobalSliderClass({
        parent: options.parent
      });
      slider.$mount('#js-vue-global-slider-container');

      Vue.prototype.$showSlider = slider.show;
      Vue.prototype.$hideSlider = slider.hide;
    }
  }
};

export default SliderPlugin;
