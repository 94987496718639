



































import Vue from 'vue';
import UILink from './UILink.vue';
import UIBlockBanner from './UIBlockBanner.vue';

/**
 * Banner for prompting the user to update an existing Rule Set or creating a new one if save is clicked in an existing Rule Set.
 */
const TheRulesetSaveBanner = Vue.extend({
  components: {
    UILink,
    UIBlockBanner
  }
});

export default TheRulesetSaveBanner;
