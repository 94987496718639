import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import PubNubVue from 'pubnub-vue';
import './styles/main.scss';
import router from './router/index';
import { store } from './store/index';
import VueStorage from './plugins/vue-storage';
import VueToaster from './plugins/toaster';
import VueModal from './plugins/modal';
import VueGlobalSlider from './plugins/global-slider';
import App from './TheApp.vue';
import setupSentry from './utils/setupSentry';
import beamerConfig from './utils/beamer';

setupSentry();
beamerConfig();

Vue.use(VueClipboard);
Vue.use(VueStorage);
Vue.use(VueToaster);
Vue.use(PubNubVue, {
  subscribeKey: process.env.PUBNUB_SUBSCRIBE_KEY,
  publishKey: process.env.PUBNUB_PUBLISH_KEY
});

(() => {
  const app = new Vue({
    el: '#app',
    router,
    store,
    render: (createElement) => createElement(App)
  });
  Vue.use(VueModal, {
    parent: app
  });
  Vue.use(VueGlobalSlider, {
    parent: app
  });
})();
