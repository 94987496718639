













































































































import Vue from 'vue';
import UINumericTextInput from '../UINumericTextInput.vue';
import ClickOutside from '../../directives/click-outside';
import UITextInput from '../UITextInput.vue';
import UISelectDropdown from '../UISelectDropdown.vue';
import UISelectDropdownAsync from '../UISelectDropdownAsync.vue';
import { storeApi } from '../../store/index';
import Logger from '../../logger/Logger.class';

const { prodError } = new Logger('Model');

type IItemOption = {
  index: number;
  label: string;
  value: string;
  checked?: boolean;
  [key: string]: any;
};

export default Vue.extend({
  directives: {
    ClickOutside
  },
  components: {
    UINumericTextInput,
    UITextInput,
    UISelectDropdown,
    UISelectDropdownAsync
  },
  props: {
    input: {
      type: Object
    },
    focusOnMount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      key: 0, // Used to force the re-write when a value has changed

      dropdownSelectOptions: [] as IItemOption[]
    };
  },
  computed: {
    isAsync(): boolean {
      return this.input?.possible_values?.type === 'autosuggest';
    }
  },
  watch: {
    input() {
      this.updateWithInputValues().catch(prodError);
    }
  },
  mounted() {
    this.updateWithInputValues().catch(prodError);
  },
  methods: {
    /**
     * Method to be used with async autosuggest dropdowns
     * @param {string} keyword
     */
    async fetchDropdownKeywordAutosuggest(keyword: string) {
      const asyncEndpoint = this.input.possible_values.api_endpoint;

      return storeApi.marketInputs.actions.fetchAutosuggestList({
        endpoint: asyncEndpoint,
        prefix: keyword
      });
    },

    /**
     * Updates the component with input values
     */
    async updateWithInputValues() {
      // Gather the data for the input type
      let valueType = '';
      let values: IItemOption[] = [];

      if (this.input?.possible_values) {
        valueType = this.input.possible_values.type;
      }

      this.dropdownSelectOptions = this.input?.value;

      if (this.isAsync) return;

      // Get the list by type of input
      switch (valueType) {
        case 'list-download':
          // HACK to create a deep copy from the list of objects returned by the endpoint to avoid reactivity issues.
          values = JSON.parse(
            JSON.stringify(
              await storeApi.marketInputs.actions.fetchList(this.input)
            )
          );
          break;
        case 'autosuggest':
          // Auto suggest needs an input
          if (this.input.prefix?.length > 0) {
            values = await storeApi.marketInputs.actions.fetchList(this.input);
          }

          break;
        default:
      }

      // Time to set the inputs as expected
      if (['single-select', 'multi-select'].includes(this.input?.type)) {
        if (this.input?.value) {
          const arrCheckedValues = (this.input.value instanceof Array
            ? this.input.value
            : [this.input.value]
          )
            .filter((item) => Boolean(item))
            .map((item) => (typeof item === 'object' ? item?.value : item));

          // Find the checked values
          values = values.map((item) => {
            const found = arrCheckedValues.find(
              (checkedV) => checkedV === item.value
            );

            if (found) item.checked = true;

            return item;
          });
        }

        // Single select dropdown can only have one item selected.
        if (this.input?.type === 'single-select') {
          const logInfo = {
            logToConsole: true,
            additionalData: this.input
          };

          if (this.input?.value?.length > 1) {
            prodError(
              Error(
                'single-select dropdown inputs cannot have more than one value.'
              ),
              logInfo
            );
          }

          if (!this.input?.value) {
            prodError(
              Error('Input props in UIQueryInput lacks "value" property.'),
              logInfo
            );
          }

          values = values.map((v) => {
            const itsNotSameInputAsProps =
              v.value !== this.input?.value?.[0]?.value;

            if (itsNotSameInputAsProps) v.checked = false;

            return v;
          });
        }

        this.setDropdownOptions(values);
      }

      // This will force the update to actually happen
      // Otherwise it doesn't re-render the inputs
      this.key += 1;
    },

    /**
     * Sets the multiple select values for the dropdown
     */
    setDropdownOptions(opts: IItemOption | IItemOption[]) {
      const newOptions = (opts instanceof Array
        ? opts
        : [opts]) as IItemOption[];

      this.dropdownSelectOptions = newOptions.map((opt) => ({
        ...opt,
        checked: Boolean(opt.checked)
      }));
    },

    /**
     * Handles the change to the multiple select values
     * @param {IItemOption[]} newValues
     */
    onDropdownSelect(newValues: IItemOption[]) {
      const toCheck = newValues || [];

      // Reset all values
      const optionsToBe = (this.dropdownSelectOptions || []).map((opt) => {
        opt.checked = false;
        return opt;
      });

      // Set the checks
      for (let i = 0; i < toCheck.length; i += 1) {
        const newCheckOpt = toCheck[i];
        const found = optionsToBe.find(
          (opt) => opt.value === newCheckOpt.value
        );

        if (found) {
          found.checked = true;
        } else {
          // Not found? maybe it is async or something, add him
          optionsToBe.push({
            ...newCheckOpt,
            checked: true
          });
        }
      }

      // Cache now
      this.dropdownSelectOptions = optionsToBe;

      // Need to inform that this changed
      const value = optionsToBe.filter((opt) => opt.checked);
      this.$emit('input', { ...this.input, value });
    }
  }
});
