import { AppSettingsState } from './state';

export const setAppSettings = (
  state: AppSettingsState,
  payload: typeof state['settings']
) => {
  state.settings = payload;
};

export const isLoadingApp = (state: AppSettingsState) => {
  state.loading = true;
};

export const isDoneLoadingApp = (state: AppSettingsState) => {
  state.loading = false;
};

export const settingsAreReady = (state: AppSettingsState) => {
  state.settingsAreReady = true;
};

export const settingsAreNotReady = (state: AppSettingsState) => {
  state.settingsAreReady = false;
};

export const setAuthUserId = (
  state: AppSettingsState,
  payload: typeof state['authUserId']
) => {
  state.authUserId = payload;
};

export const setAuthUser = (
  state: AppSettingsState,
  payload: typeof state['authUser']
) => {
  state.authUser = payload;
};
