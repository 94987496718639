/**
 * Apply JSON API filters for a URLSearchparams instance based on the provided filters object.
 *
 * If no filters are provided then nothing will happen.
 *
 * @example
 *  const params = new URLSearchParams();
 *  applyFilters({ page: '1,2' });
 */
export function applyFilters(
  params: URLSearchParams,
  filters: Partial<Record<string, string | string[]>> | undefined
) {
  if (filters) {
    for (const key in filters) {
      const filter = filters[key];

      if (filter instanceof Array) {
        for (const value of filter) {
          params.append(`filter[${key}][]`, value);
        }
      } else if (filter) {
        params.set(`filter[${key}]`, filter);
      }
    }
  }
}
