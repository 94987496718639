import { AxiosError } from 'axios';
import * as Sentry from '@sentry/browser';
import minimatch from 'minimatch';
import getRandomRgb from './colors/getRandomRgb';
import { loggerColors } from './loggerColorPatterns';

if (process.env.NODE_ENV !== 'production' && !process.env.JEST_WORKER_ID) {
  console.log('DEBUG: ', process.env.DEBUG);
}

/**
 * @warn This logger is meant to be used on the front-end.
 *
 * App logger. You should use this to report errors to sentry and do scoped logging.
 * It will group logs according to the provided namespace and also stack trace on development mode.
 * To create a new logger you need to provide a namespace to be used on log groups.
 *
 * The namespace can be separated by double dots.
 * You can also change the namespace on the fly or set a sub namespace with 2 double dots.
 *
 * @example
 *  const fileLogger = new Logger('namespace'); // all logs of this logger will be inside a console group called 'namespace'
 *  fileLogger.baseNamespace = 'fileLogger'; // we manually set the base namespace again
 *  fileLogger.subNamespace = 'operation1'; // The namespace here will be 'fileLogger::operation1'
 *  fileLogger.namespace = 'foo::bar'; // set the base as foo and the sub as bar
 */
export default class Logger {
  /**
   * Currently active logger namespace.
   */
  public static activeGroup = '';

  /**
   * Patterns of namespaces to log.
   */
  public static DEBUG = process.env.DEBUG || '';

  /**
   * If the namespace can be logged.
   */
  public get enabled() {
    return minimatch(this.namespace, Logger.DEBUG);
  }

  /**
   * If the namespace for the active log level can be logged.
   */
  public get shouldLog(): boolean {
    return this.enabled && minimatch(this.namespace, Logger.DEBUG);
  }

  /**
   * Color of the active namespace.
   */
  private readonly namespaceColor = getRandomRgb();

  /**
   * Base namespace of the logger.
   *
   * @example
   *  new Logger(foo:bar::bob).baseNamespace // will return 'foo:bar';
   */
  public baseNamespace = '';
  /**
   * Sub namespace of the logger.
   * @example
   *  new Logger(foo:bar::bob).subNamespace // will return 'bob';
   */
  public subNamespace = '';
  /**
   * Logging scope being used at the moment.
   *
   * @example
   * 'dev'
   */
  public activeScope = '';

  /**
   * @param namespace - Used to create log groups.
   */
  public constructor(namespace: string) {
    this.namespace = namespace;
  }

  /**
   * Full namespace of the logger.
   *
   * It also includes the app: prefix.
   * if setting it to a value. The baseNamespace and subNamespace will be inferred from it.
   */
  public get namespace() {
    const start = `app:${this.activeScope}:`;
    let end = this.baseNamespace;

    if (this.subNamespace) {
      end += `::${this.subNamespace}`;
    }

    return start + end;
  }

  public set namespace(value) {
    const [base, sub] = value.split('::');
    this.baseNamespace = base;
    this.subNamespace = sub;
  }

  /**
   * If the current logger should auto hide its logs under the folded group.
   */
  public get autohide() {
    return !this.shouldLog && process.env.APP_DEBUG_EXCLUDE_MODE === 'hide';
  }

  public get isLoggingANewScope() {
    return this.namespace !== Logger.activeGroup;
  }

  public get shouldExcludeLog() {
    return process.env.APP_DEBUG_EXCLUDE_MODE === 'exclude' && !this.shouldLog;
  }

  /**
   * Main logger function. Before calling it, set the Logger.activeScope.
   * @param message - Message to display in the log.
   * @param level - log level to use with console[level].
   */
  private readonly log = (
    message: any[],
    level: keyof typeof console = 'log'
  ) => {
    let log = console;

    if (process.env.JEST_WORKER_ID) {
      log = new Proxy(log, {
        get() {
          return (...args: any[]) => args;
        }
      });
    }

    if (this.shouldExcludeLog) {
      return {
        logDetails: {
          logged: false as const,
          reason: 'shouldExcludeLog'
        }
      };
    }

    const groupType = this.autohide ? 'groupCollapsed' : 'group';

    if (this.isLoggingANewScope) {
      log.groupEnd();
      Logger.activeGroup = this.namespace;
      log[groupType]('%c%s', `color: ${this.namespaceColor}`, this.namespace);
    }

    const [firstMessage] = message;

    const messageColor =
      process.env.COLORIZE_MESSAGES === 'true'
        ? loggerColors.getColorFor(firstMessage)
        : 'white';

    const messageToLog = [`color: ${messageColor}`, ...message];
    let pattern = '%c%s%c%O';

    if (typeof firstMessage === 'string' && firstMessage.includes('%')) {
      pattern = '%c%s' + firstMessage;
      messageToLog.shift();
      messageToLog.shift();
    }

    const logArgs = {
      pattern,
      css: `color: ${this.namespaceColor}`,
      scope: `${this.activeScope}:  `,
      message: messageToLog
    };

    const toLog = [
      logArgs.pattern,
      logArgs.css,
      logArgs.scope,
      ...logArgs.message
    ];

    if (level === 'log') {
      log.groupCollapsed(...toLog);
      if (process.env.NODE_ENV !== 'production') {
        log.groupCollapsed('(See trace)');
        log.trace();
        log.groupEnd();
      }
      log.groupEnd();
    } else {
      log[level](...toLog);
    }

    return {
      logDetails: {
        logged: true,
        ...logArgs
      }
    };
  };

  private tryToLog(...args: Parameters<Logger['log']>) {
    try {
      this.log(...args);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Development only logger. It will include the name of the current executing function.
   * @param message - Message to show in the console.
   */
  public dev = (...message: any[]) => {
    if (process.env.NODE_ENV !== 'development') {
      return undefined;
    }
    this.activeScope = 'dev';
    return this.tryToLog(message);
  };

  /**
   * Used for warnings in development mode.
   */
  public devWarn = (...message: any[]) => {
    if (process.env.NODE_ENV !== 'development') {
      return undefined;
    }
    this.activeScope = 'devWarn';
    return this.tryToLog(message, 'warn');
  };

  /**
   * Used for logs that should be displayed in production.
   */
  public prod = (...message: any[]) => {
    this.activeScope = 'prod';
    return this.tryToLog(message);
  };

  /**
   * Used for errors that affect development only.
   */
  public devError = (...message: any[]) => {
    this.activeScope = 'devError';
    return this.tryToLog(message, 'error');
  };

  /**
   * Used for production errors. The log can be omitted and it will be sent to sentry.
   * @param error - Message to display in the console or/and send to sentry.
   * @param options - Extra options regarding how the error will be handled and additional data.
   */
  public prodError = (
    error: string | Error | AxiosError,
    options: {
      logToConsole?: boolean;
      throwError?: boolean;
      additionalData?: any;
    } = {}
  ) => {
    this.activeScope = 'prodError';

    Sentry.configureScope((scope) => {
      scope.setLevel(Sentry.Severity.Error);
      scope.setTag('namespace', this.baseNamespace);

      if (options.additionalData) {
        scope.setContext('loggerInfo', options.additionalData);
      }

      if (error instanceof Error) {
        Sentry.captureException(error);
      } else {
        Sentry.captureException(new Error(error));
      }
    });

    if (options.logToConsole !== false) {
      return this.tryToLog(
        [
          `Error detected on ${this.baseNamespace}: `,
          error,
          'Error data: ',
          options.additionalData
        ],
        'error'
      );
    }

    if (options.throwError) {
      throw typeof error === 'string' ? new Error(error) : error;
    }

    return false;
  };

  /**
   * Pretty logs a object as a JSON string.
   */
  public devJSON = (target: any) => {
    if (process.env.NODE_ENV !== 'development') {
      return;
    }
    let message = target;

    try {
      message = JSON.stringify(target, null, '  ');
    } catch (e) {
      this.devError(e);
    }

    this.dev(message);
  };
}
