




















































import { EsState } from '../../store/modules/state/es/state';
import formatNumber from '../../filters/format-number';
import abbreviateNumber from '../../filters/abbreviate-number';
import { getInputsFromBucket } from '../../store/modules/state/es/utils';
import UITooltip from '../tooltip/UITooltip.vue';
import UIProgressBar from '../UIProgressBar.vue';
import Vue, { PropOptions } from 'vue';
import { storeApi } from '../../store/index';
import Logger from '../../logger/Logger.class';
import { Bucket } from '../../store/es-query/libs/types';
import { IQueryBlock } from '../../API/models/queryUI/queryBlock';

const { prodError } = new Logger('TheFeatureCardView');

type Feature = EsState['aggsUi'][0];

const TheFeatureCardView = Vue.extend({
  name: 'TheFeatureCardView',
  components: {
    UIProgressBar,
    UITooltip
  },
  filters: {
    formatNumber,
    abbreviateNumber
  },
  props: {
    feature: {
      type: Object,
      required: true
    } as PropOptions<Feature>
  },
  data() {
    return {
      debounceBucket: null as any
    };
  },
  computed: {
    hitsTotal(): number {
      const compFeature = this.feature;
      return compFeature.globalTotal == null ? 0 : compFeature.globalTotal;
    },
    buckets(): Bucket[] {
      return this.feature ? this.feature.buckets || [] : [];
    },
    hasActiveBucket(): boolean {
      const has = this.buckets.filter((bucket) => bucket.queryIdentityActive);
      return has.length > 0;
    }
  },
  methods: {
    getBucketCount(bucket: Bucket): number {
      return bucket == null || bucket.count == null ? 0 : bucket.count;
    },
    getBucketMaxPercentage(): number {
      const max = this.hitsTotal;
      if (max === 0) {
        return 0;
      }

      let maxCount = 0;
      for (let i = 0; i < this.buckets.length; i += 1) {
        const newCount = this.getBucketCount(this.buckets[i]);
        maxCount = newCount > maxCount ? newCount : maxCount;
      }

      return (maxCount * 100) / max;
    },
    getBucketPercentage(bucket: Bucket): number {
      const max = this.hitsTotal;
      if (max === 0) {
        return 0;
      }

      const count = this.getBucketCount(bucket);
      if (count === 0) {
        return 0;
      }

      return (count * 100) / max;
    },
    onBucket(bucket: Bucket) {
      if (this.debounceBucket != null || this.getBucketCount(bucket) === 0) {
        return;
      }

      // Set a debouncer so that the user doesn't double-click causing bugs
      this.debounceBucket = setTimeout(() => {
        this.debounceBucket = null;
      }, 500);

      // Start by removing all the active queries
      const isActive = bucket.queryIdentityActive != null;
      if (isActive) {
        // Being bucket already active we want to remove it
        storeApi.state.es.actions
          .removeQuery({
            identity: bucket.queryIdentityActive || '',
            isFromBucket: true
          })
          .catch(prodError);

        bucket.queryIdentityActive = null;

        return;
      }

      // Do we have already a query? if we have we want to use that one and update it
      const bucketWithActiveId = this.buckets.find(
        (b) => b.queryIdentityActive != null
      );
      let queryId: null | string = null;
      if (bucketWithActiveId != null) {
        // Remove the active id from the old active bucket
        queryId = bucketWithActiveId.queryIdentityActive || null;
        bucketWithActiveId.queryIdentityActive = null;
      }

      const compFeature = this.feature;

      // About to setup a new query template
      const tmplId = compFeature != null ? compFeature.queryTemplateId : '';
      if (tmplId == null || tmplId.length === 0) {
        return;
      }
      const queryTemplates = storeApi.state.es.getters.queryTemplates;
      const template = (queryTemplates == null ? [] : queryTemplates).find(
        (t) => t.id === tmplId
      );
      if (template == null) {
        return;
      }

      // The query for this one should be specifically the one on agg
      if (compFeature.esQueryBlock != null) {
        const rawQueryTmpl = JSON.stringify(compFeature.esQueryBlock).replace(
          /bucket_key/g,
          bucket.key || ''
        );
        template.elasticsearch_template = JSON.parse(rawQueryTmpl);
      }

      const stateQuery: IQueryBlock = {
        type: 'query-block',
        isFromBucket: true,
        bucketKey: bucket.key,
        templateId: tmplId,
        inputs: getInputsFromBucket(
          template.inputs || [],
          bucket.key || '',
          compFeature.template.segments
        )
      };

      Promise.resolve()
        .then(() => {
          if (queryId?.length) {
            // Cache the query id on the new bucket
            bucket.queryIdentityActive = queryId;

            return storeApi.state.es.actions.changeQuery({
              query: {
                ...template,
                inputs: stateQuery.inputs || []
              },
              inputs: stateQuery.inputs || [],
              replace: false,
              identity: queryId,
              isFromBucket: stateQuery.isFromBucket,
              bucketKey: stateQuery.bucketKey
            });
          }

          return storeApi.state.es.actions.addQuery({
            state: stateQuery,
            query: template
          });
        })
        .catch(prodError);
    }
  }
});

export default TheFeatureCardView;
