import QueryBlockGroup from './query-block-group';
import QueryBlock from './query-block';
import TemplatesManager from './templates-manager';
import { IParsedQueryBlockGroup } from '../../../API/models/queryUI/queryBlockGroup';
import {
  QueryRawTree,
  QueryStateGroupTree,
  QueryDataUpdateConfig
} from './types';
import { IQueryBlock } from '../../../API/models/queryUI/queryBlock';
import { IOldRule } from '../../modules/query-templates/actions';
import { Ruleset } from '../../../API/models/ruleset/ruleset';

export default class QueryBlockManager {
  private rootQuery = new QueryBlockGroup(true, undefined, true);

  /**
   * Gets the query registered as per request type
   */
  public get(type?: '', params?: Record<string, any>): IParsedQueryBlockGroup;
  public get(
    type: 'raw',
    params: { isntFromBuckets?: boolean }
  ): QueryRawTree | null;
  public get(
    type: 'state',
    params: { isntFromBuckets?: boolean }
  ): QueryStateGroupTree | null;
  public get(type: 'identity'): string;
  public get(
    type: 'raw' | 'state' | 'identity' | '' = '',
    params: {
      isntFromBuckets?: boolean;
    } = {}
  ) {
    if (type === 'state') {
      return this.rootQuery.get('state', params);
    }

    if (type === 'raw') {
      return this.rootQuery.get('raw', params);
    }

    if (type === 'identity') {
      return this.rootQuery.get('identity');
    }

    return this.rootQuery.get();
  }

  /**
   * Create Group at a child identity
   * Add the `queryBlock` to the group
   * @param {QueryBlock|QueryBlockGroup} queryBlock - the QueryBlock instance to add
   * @returns {QueryBlockGroup}
   */
  private addRuleToRootGroup(queryBlock: QueryBlock | QueryBlockGroup) {
    const group = this.rootQuery;

    const groupNested = new QueryBlockGroup();
    groupNested.add('', queryBlock);
    group.add('', groupNested);

    return queryBlock;
  }

  /**
   * Adds a "state to a query
   */
  private addState(
    entity: QueryBlock | QueryBlockGroup | null,
    params: {
      isRoot?: boolean;
      query?: QueryStateGroupTree | IQueryBlock;
      templates?: TemplatesManager;
    } = {}
  ): QueryBlock | QueryBlockGroup | null {
    let entityToBe = entity;
    const { query, templates } = params;

    const queryState = query as IQueryBlock;
    const queryGroupState = query as QueryStateGroupTree;

    if (
      queryState != null &&
      queryState.templateId != null &&
      templates != null
    ) {
      const template = templates.get('query', {
        templateId: queryState.templateId
      });
      const queryBlock = new QueryBlock(
        template as IOldRule | undefined,
        queryState
      );

      if (entityToBe != null && entityToBe instanceof QueryBlockGroup) {
        entityToBe.add('', queryBlock);
      } else {
        entityToBe = queryBlock;
      }
    }

    if (
      queryGroupState != null &&
      queryGroupState.children != null &&
      queryGroupState.children.length > 0
    ) {
      const newGroup = new QueryBlockGroup(
        false,
        queryGroupState.operator,
        params.isRoot,
        queryGroupState.template
      );

      if (entityToBe != null && entityToBe instanceof QueryBlockGroup) {
        entityToBe.add('', newGroup);
      } else {
        entityToBe = newGroup;
      }

      // Add each children now
      for (let i = 0; i < queryGroupState.children.length; i += 1) {
        this.addState(newGroup, {
          templates,
          query: queryGroupState.children?.[i]
        });
      }
    }

    return entityToBe;
  }

  /**
   * Adds a query to an identity
   */
  public add(
    type?: '',
    params?: {
      removeAllFromBuckets?: boolean;
      removeIsFromBucket?: boolean;
    }
  ): QueryBlockGroup;
  public add(
    type: 'state',
    params: {
      removeAllFromBuckets?: boolean;
      removeIsFromBucket?: boolean;

      isRoot?: boolean;
      templates?: TemplatesManager;

      parentIdentity?: string;
      query?: QueryStateGroupTree | IQueryBlock;
      state?: QueryStateGroupTree | IQueryBlock;
    }
  ): QueryBlockGroup;
  public add(
    type: 'query',
    params: {
      removeAllFromBuckets?: boolean;
      removeIsFromBucket?: boolean;

      parentIdentity?: string;
      query?: IOldRule;
      state?: QueryStateGroupTree | IQueryBlock;

      [key: string]: any;
    }
  ): QueryBlockGroup;
  public add(
    type: 'group',
    params: {
      removeAllFromBuckets?: boolean;
      removeIsFromBucket?: boolean;
      queryGroup?: Ruleset['attributes']['query_template'];
      parentIdentity?: string;
    }
  ): QueryBlockGroup;
  public add(
    type: 'state' | 'query' | 'group' | '' = '',
    params: {
      removeAllFromBuckets?: boolean;
      removeIsFromBucket?: boolean;
      queryGroup?: Ruleset['attributes']['query_template'];

      parentIdentity?: string;
      query?: QueryStateGroupTree | IQueryBlock | IOldRule;
      state?: QueryStateGroupTree | IQueryBlock;

      // State specific
      isRoot?: boolean;
      templates?: TemplatesManager;
    } = {}
  ) {
    if (params.removeAllFromBuckets && this.rootQuery != null) {
      this.rootQuery.destroy(null, { onlyFromBuckets: true });
    }

    if (params.removeIsFromBucket && this.rootQuery != null) {
      this.rootQuery.update(null, { removeIsFromBucket: true });
    }

    if (type === 'state') {
      const queryToBe = (params.query == null ? {} : params.query) as
        | QueryStateGroupTree
        | IQueryBlock;
      // State enforces the query to be new
      const stateEntity = this.addState(null, {
        ...params,
        query: queryToBe,
        isRoot: true
      });

      // Still null? then just enforce it to exist
      if (stateEntity == null) {
        this.rootQuery = new QueryBlockGroup(true, undefined, true);
      } else {
        this.rootQuery = stateEntity as QueryBlockGroup;
      }

      return this.rootQuery;
    }

    if (type === 'query') {
      const { query, state } = params;
      const queryBlock = new QueryBlock(
        query as IOldRule,
        state as IQueryBlock
      );

      if (
        params.parentIdentity == null ||
        params.parentIdentity === (this.rootQuery.get('identity') as string)
      ) {
        return this.addRuleToRootGroup(queryBlock);
      }

      return this.rootQuery.add(params.parentIdentity, queryBlock);
    }

    if (type === 'group') {
      if (params.queryGroup) {
        return this.rootQuery.add(
          params.parentIdentity,
          new QueryBlockGroup(
            false,
            params.queryGroup.operator,
            false,
            params.queryGroup.template,
            params.queryGroup.children
          )
        );
      }

      return this.rootQuery.add(params.parentIdentity, new QueryBlockGroup());
    }

    return this.rootQuery;
  }

  /**
   * Updates a query
   * @param {string?} identity
   * @param {{ [key: string]: any; }} params
   * @returns {QueryBlockGroup}
   */
  public update(identity = '', params: QueryDataUpdateConfig = {}) {
    if (params.removeAllFromBuckets && this.rootQuery != null) {
      this.rootQuery.destroy(null, { onlyFromBuckets: true });
    }

    if (params.removeIsFromBucket && this.rootQuery != null) {
      this.rootQuery.update(null, { removeIsFromBucket: true });
    }

    const rootIdentity = this.rootQuery.get('identity') as string;
    const identityToUpd =
      identity == null || identity.length === 0 ? rootIdentity : identity;

    this.rootQuery.update(identityToUpd, params);

    return this.rootQuery;
  }

  /**
   * Destroys the query
   * @param {string?} identity
   * @returns {boolean} true in case it has been destroyed
   */
  public destroy(identity: string | null = '') {
    if (identity == null) {
      return false;
    }

    const res = this.rootQuery.destroy(identity);

    // Check if root is destroyed, we always need a root
    if (this.rootQuery == null || this.rootQuery.isEmpty()) {
      this.rootQuery = new QueryBlockGroup(true, undefined, true);
    }

    return res;
  }
}
