





































































































































































































import UIRuleSelectorModalSidebar from './UIRuleSelectorModalSidebar.vue';
import UIRuleSelectorModalButton from './UIRuleSelectorModalButton.vue';
import UIRuleSelectorModalNavItem from './vertical-nav/UIRuleSelectorModalNavItem.vue';
import UIRuleSelectorModalNav from './vertical-nav/UIRuleSelectorModalNav.vue';
import UIRuleSelectorModalIcon from './icon/UIRuleSelectorModalIcon.vue';
import UIRuleSelectorModal from './UIRuleSelectorModal.vue';
import Vue from 'vue';
import {
  UIActionButton,
  UIInlineSearchInput,
  UITablePagination,
  UICheckbox
} from '@intricately/ui-lib';
import { storeApi } from '../../../store/index';
import ruleBuilderBg from './assets/rule-builder.svg';
import Logger from '../../../logger/Logger.class';
import { ResourceType } from '../../../API/clients/resourceTypes.enum';
import { Rule } from '../../../API/models/rule/rule';
import chart from './assets/chart.svg';
import { ComponentProps } from '../../../utils/componentProps';
import { relativeDay } from '../../../utils/relativeTime';
import { capitalize } from '../../../utils/capitalize';

type ParsedRuleSet = typeof storeApi.rulesets.getters.rulesets[number];
type ParsedCategory = typeof storeApi.queryTemplates.getters.categoriesWithRules[string];
type View = 'rule_sets' | 'rule_categories' | 'rules' | 'rule_sets_preview';
type ViewData = {
  contentTitle?: string;
  sidebarTitle?: string;
  content?: string;
  safe?: boolean;
  contentImages?: {
    src: string;
    gap: string;
    isPreview?: boolean;
    previewText?: string;
    width?: number;
    height?: number;
  }[];
  icon?: string;
  canSearch?: boolean;
  meta?: string[];
};

const { prodError } = new Logger('TheRuleSelectorModal');

const TheRuleSelectorModal = Vue.extend({
  name: 'TheRuleSelectorModal',
  components: {
    UIRuleSelectorModalSidebar,
    UIRuleSelectorModalButton,
    UIRuleSelectorModalNavItem,
    UIRuleSelectorModalNav,
    UIRuleSelectorModalIcon,
    UIRuleSelectorModal,
    UIInlineSearchInput,
    UIActionButton,
    UITablePagination,
    UICheckbox
  },
  props: {
    parentIdentity: {
      type: String,
      default: ''
    },
    identity: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      view: 'rule_categories' as View,
      selectedCategory: undefined as undefined | ParsedCategory,
      activeCategory: undefined as undefined | ParsedCategory,
      activeRule: undefined as undefined | Rule,
      activeRuleSet: undefined as undefined | ParsedRuleSet,
      searchQuery: '',
      lastClickedDrilldownItem: '',
      searchTimeout: '' as any,
      searchUserRulesOnly: false,
      /**
       * Becames true after finishing the request for the first search.
       */
      isSearching: false
    };
  },
  computed: {
    ruleSetCount(): number {
      return Math.min(
        this.ruleSetsMeta.matching_rule_entries,
        this.ruleSetsMeta.total_rule_entries
      );
    },
    userId() {
      return storeApi.appSettings.getters.authUserId;
    },
    viewData(): ViewData {
      const views: Record<View, ViewData> = {
        rule_categories: {
          contentTitle: this.activeCategory?.name || 'Rule Builder',
          sidebarTitle: 'Select a Rule',
          content:
            this.activeCategory?.description ||
            'Select data attributes from the list on the left to build your ideal record filter',
          safe: true,
          contentImages: this.activeCategory
            ? [
                {
                  src: this.activeCategory.preview_url,
                  gap: '16px',
                  width: 466,
                  height: 97
                },
                { src: chart, gap: '57px', isPreview: true }
              ]
            : [{ src: ruleBuilderBg, gap: '64px' }]
        },
        rule_sets_preview: {
          contentTitle: 'Welcome to the Rule Library',
          sidebarTitle: 'Select a Rule',
          content: `Build your Market by selecting the Rules that define it</br></br>
            Streamline your Market building with Rule Sets for easy access to frequently used Rule combinations`,
          safe: true,
          contentImages: [
            {
              src: require('./assets/categories/rule-sets.svg'),
              gap: '16px'
            },
            {
              src: require('./assets/custom-rules.svg'),
              gap: '48px'
            }
          ]
        },
        rules: {
          canSearch: false,
          contentTitle: this.activeRule?.attributes.title,
          sidebarTitle: this.selectedCategory?.name,
          content: this.activeRule?.attributes.description,
          safe: true,
          icon: this.activeCategory?.icon_url,
          contentImages: [
            { src: require('./assets/drilldown.svg') || '', gap: '16px' },
            { src: chart, gap: '57px', isPreview: true }
          ]
        },
        rule_sets: {
          contentTitle: this.activeRuleSet?.title || 'Rule Sets',
          sidebarTitle: 'Rule Sets',
          icon: require('./assets/rulesets.svg'),
          content:
            this.activeRuleSet?.description ||
            'Here you will find the custom rules created by you and your team.</br></br>Use the search bar to refine by rule name, rule creator, or contained data attributes.',
          safe: !this.activeRuleSet,
          meta: (() => {
            const items: string[] = [];

            if (this.activeRuleSet?.user.name) {
              items.push('Creator: ' + this.activeRuleSet.user.name);
            }

            if (this.activeRuleSet?.updated_at) {
              items.push(
                'Updated: ' +
                  capitalize(
                    relativeDay(new Date(this.activeRuleSet.updated_at))
                  )
              );
            } else if (this.activeRuleSet?.created_at) {
              items.push(
                capitalize(relativeDay(new Date(this.activeRuleSet.created_at)))
              );
            }

            return items;
          })(),
          contentImages: [
            { src: require('./assets/drilldown.svg'), gap: '16px' },
            {
              src: require('./assets/rule-sets-soon.svg'),
              width: 288,
              height: 157,
              gap: '22px',
              isPreview: true,
              previewText: '(Rule Set Preview Coming Soon)'
            }
          ]
        }
      };

      return views[this.view];
    },
    categories() {
      const searching = this.isSearching as boolean;

      const sortedCategories = Object.values(
        storeApi.queryTemplates.getters.categoriesWithRules
      ).sort((category, nextCategory) => {
        const searchOrder =
          category.meta.matching_rule_entries >
          nextCategory.meta.matching_rule_entries
            ? -1
            : 1;

        const alphabeticalOrder = category.name < nextCategory.name ? -1 : 1;

        return searching ? searchOrder : alphabeticalOrder;
      });

      return sortedCategories.map((category) => {
        return {
          ...category,
          rules: category.rules.sort((rule, nextRule) => {
            const alphabeticalOrder =
              rule.attributes.title < nextRule.attributes.title ? -1 : 1;
            const searchOrder = rule.meta?.match ? -1 : 1;

            return searching && category.meta.matching_rule_entries > 0
              ? searchOrder
              : alphabeticalOrder;
          })
        };
      });
    },
    ruleSets() {
      return storeApi.rulesets.getters.rulesets;
    },
    ruleSetsMeta() {
      return storeApi.rulesets.state.ruleSetsMeta;
    },
    paginationData(): Partial<ComponentProps<typeof UITablePagination>> {
      return {
        pages: Math.ceil(this.ruleSetsMeta.total_rule_entries / 20),
        pagesToShow: 7
      };
    }
  },
  methods: {
    resetModal() {
      this.view = 'rule_categories';
      this.selectedCategory = undefined;
    },
    changeRuleSetPage(page) {
      storeApi.rulesets.actions
        .fetchRulesets({
          page,
          search: this.searchQuery
        })
        .catch(prodError);
    },
    resetSearch() {
      this.searchQuery = '';
      this.searchUserRulesOnly = false;
      this.search();
    },
    search() {
      const promises: Promise<any>[] = [];

      promises.push(
        storeApi.rulesets.actions.fetchRulesets({
          search: this.searchQuery,
          user: this.searchUserRulesOnly ? this.userId : undefined
        })
      );

      promises.push(
        storeApi.queryTemplates.actions.fetchTemplates({
          search: this.searchQuery
        })
      );

      Promise.all(promises)
        .then(() => {
          this.isSearching = !!this.searchQuery;
          this.activeRuleSet = undefined;
        })
        .catch(prodError);
    },
    prepareSearch(value) {
      clearTimeout(this.searchTimeout);
      this.searchQuery = value;
      this.searchTimeout = setTimeout(this.search, 250);
    },
    browseCategoryRules(category: ParsedCategory) {
      this.selectedCategory = category;
      this.activeRule = category.rules[0];
      this.view = 'rules';
    },
    browseRuleSets() {
      this.activeRuleSet = undefined;
      this.view = 'rule_sets';
    },
    selectQuery(query?: ParsedRuleSet | Rule) {
      if (!query) {
        throw new Error('Query is undefined!');
      }

      if (!this.parentIdentity && !this.identity) {
        throw new Error(
          'Cannot select a query without an identity or parent identity'
        );
      }

      if ('type' in query && query.type === ResourceType.explorer_rule_set) {
        storeApi.state.es.actions
          .addQueryGroup({
            parentIdentity: this.parentIdentity,
            queryGroup: {
              ...query.query_template,
              template: {
                rulesetId: query.id,
                userId: query.user.id,
                title: query.title,
                description: query.description
              }
            }
          })
          .catch(prodError);

        storeApi.state.es.actions
          .updateEsState()
          .then(() => {
            return storeApi.state.es.actions.saveState();
          })
          .catch(prodError);

        this.$hideModal();
        return;
      }

      const queryTemplate = storeApi.state.es.getters.queryTemplates.find(
        (template) => {
          return template.id === query.id;
        }
      );

      if (!queryTemplate) {
        throw new Error('Could not find matching query template');
      }

      if (this.parentIdentity) {
        storeApi.state.es.actions
          .addQuery({
            query: queryTemplate,
            parentIdentity: this.parentIdentity
          })
          .catch(prodError);
      } else if (this.identity) {
        storeApi.state.es.actions
          .changeQuery({
            query: queryTemplate,
            identity: this.identity,
            replace: true
          })
          .catch(prodError);
      }

      this.$hideModal();
    }
  },
  mounted() {
    this.resetSearch();
    const searchInput = this.$refs['search-input'] as Vue;
    const innerInput = searchInput.$el.querySelector('input');
    innerInput?.focus();
  },
  beforeDestroy() {
    this.resetSearch();
  }
});

export default TheRuleSelectorModal;
