import * as t from 'io-ts';
import Model from '../../classes/model';
import { ModelInterface } from '../interfaceGetters/modelInterface';
import { queryUiInputModel } from './queryInput';

/**
 * Saved UI structure to build the front end.
 *
 * A query block describes the UI for a rule.
 */
export const queryBlockSchema = {
  /**
   * Type of query block.
   */
  type: t.literal('query-block'),

  /**
   * If this is related to the segments
   */
  isFromBucket: t.boolean
};

const queryBlockOptionalSchema = {
  /**
   * List of input fields for this rule
   */
  inputs: t.union([t.array(queryUiInputModel.base), t.null]),
  /**
   * Random generated id for the query block.
   */
  identity: t.string,
  /**
   * TODO: Investigate
   */
  description: t.string,
  /**
   * Which key of the bucket (segment) this query block is related to.
   */
  bucketKey: t.union([t.string, t.number]),
  advertisement: t.boolean,
  value: t.string,

  /**
   * TODO: Investigate
   */
  data: t.any,
  /**
   * Name of the related rule
   */
  label: t.string,
  /**
   * Id of the related rule
   *
   * TODO: investigate if this is used.
   */
  templateId: t.string,
  /**
   * Id of the related rule
   */
  id: t.string
};

export const queryBlockModel = new Model(
  t.intersection([
    t.interface(queryBlockSchema),
    t.partial(queryBlockOptionalSchema)
  ])
);

export type IQueryBlock = ModelInterface<typeof queryBlockModel>;
