import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration, RewriteFrames } from '@sentry/integrations';

/**
 * Sentry initial setup.
 */
export default function setupSentry() {
  Sentry.init({
    dsn: process.env.RAVEN_URL,
    integrations: [
      new RewriteFrames(),
      new VueIntegration({ Vue, attachProps: true })
    ],
    beforeSend(event: any) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn('Sentry event: ', event);
        return null; // This cancels the event from being sent.
      }

      return event;
    }
  });

  /**
   * Init Sentry with an unknow user until we get current user id.
   */
  Sentry.configureScope((scope) => {
    scope.setUser({ undefined });
  });
}
