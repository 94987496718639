
















import Vue from 'vue';

export default Vue.extend({
  props: {
    id: {
      type: String
    },
    name: {
      type: String
    },
    label: {
      type: String
    },
    value: {
      type: null as any
    }
  },
  data() {
    return {
      valueParsed: ''
    };
  },
  mounted(): void {
    this.valueParsed = this.value as string;
  },
  methods: {
    update(): void {
      this.$emit('input', this.valueParsed);
    }
  }
});
