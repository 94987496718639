import { request, convertQueriesToString } from '../request';

// Originaly generated by:
// - https://www.npmjs.com/package/openapi-client-axios#generating-type-files-dts
// With the open api spec:
// https://bitbucket.org/cloudrupt/openapi-specs/raw/master/explorer_download_history.yml
import { Components } from '../../../typings/openapi/explorer_download_history';

// ----------------------------------------
// Variables

// ----------------------------------------
// Functions

/**
 * Gets a report history list
 * @param {{ sort?: Components.Parameters.SortParam.Sort; }|null} query
 * @param {Components.Schemas.HistoryFilters|null} query
 * @returns {Promise}
 */
export const getReportHistoryList = (
  query?: { sort?: Components.Parameters.SortParam.Sort },
  queryFilter?: Components.Schemas.HistoryFilters,
  pageFilter?: { number?: number; limit?: number }
) => {
  return request<Components.Schemas.GetReports>({
    url: `/explorer/reports?include=report_field_set,user&${convertQueriesToString(
      query,
      queryFilter,
      pageFilter
    )}`,
    method: 'GET'
  }).then((response) => response.data);
};

/**
 * Creates a report
 * @param {Components.Schemas.NewReportBody} body
 * @returns {Promise}
 */
export const createReport = (body: Components.Schemas.NewReportBody) => {
  return request<{ data: Components.Schemas.Report }>({
    url: '/explorer/reports',
    method: 'POST',
    data: body
  }).then((response) => response.data);
};

/**
 * Updates a report
 * @param {{ reportId: string; }} params
 * @param {Components.Schemas.EditReportBody} body
 * @returns {Promise}
 */
export const updateReport = (
  params: {
    reportId: string;
  },
  body: Components.Schemas.EditReportBody
) => {
  return request<{ data: Components.Schemas.Report }>({
    url: `/explorer/reports/${params.reportId}`,
    method: 'PATCH',
    data: body
  }).then((response) => response.data);
};

/**
 * Gets report enrichment list list
 * @returns {Promise}
 */
export const getReportEnrichments = (
  query?: { sort?: Components.Parameters.SortParam.Sort },
  queryFilter?: Components.Schemas.HistoryFilters,
  pageFilter?: { number?: number; limit?: number }
) => {
  // TODO: to uncomment after implementation
  return request<Components.Schemas.GetEnrichments>({
    url: `/explorer/enrichments?${convertQueriesToString(
      query,
      queryFilter,
      pageFilter
    )}`,
    method: 'GET'
  }).then((response) => response.data);
};

/**
 * Fetch report fields set
 * @param {string} payload
 */
export const getReportFieldSets = (payload?: string) => {
  let url = '/explorer/report_field_sets';
  if (payload != null && payload.length > 0) {
    url += `/${payload}/fields`;
  }

  return request<{ id: number; name: string }[]>({
    url,
    method: 'GET'
  }).then((response) => response.data);
};
