export default {
  // TODO: needs typing
  inserted(el: any, binding: any, vNode: any) {
    if (typeof binding.value !== 'function') {
      const compName = vNode.context.name;
      let warn = `[Vue-on-keypress:] provided expression '${binding.expression}' is not a function, but has to be`;
      if (compName) {
        warn += `Found in component '${compName}'`;
      }
      // Disable eslint for console warn because in this context
      // The warning is needed
      // eslint-disable-next-line
      console.warn(warn);
    }
    const handler = (e) => {
      // If (e.typ)
      if (e.keyCode === 27) {
        binding.value(e);
      }
    };
    el.myCustomVueOnKeypress = handler;

    document.addEventListener('keyup', handler);
  },
  // TODO: needs typing
  unbind(el: any) {
    document.removeEventListener('keyup', el.myCustomVueOnKeypress);
    el.myCustomVueOnKeypress = null;
  }
};
