





























































import { UITooltip, UIHelpIcon } from '@intricately/ui-lib';
import shortenNumber from '../../filters/shorten-number';
import percentagify from '../../filters/percentagify';
import formatNumber from '../../filters/format-number';
import Vue from 'vue';
import { storeApi } from '../../store/index';

const TheSegmentPreview = Vue.extend({
  name: 'TheSegmentPreview',
  filters: {
    formatNumber,
    shortenNumber,
    percentagify
  },
  components: {
    UITooltip,
    UIHelpIcon
  },
  computed: {
    title(): string {
      return storeApi.appSettings.getters.marketPreviewTitle;
    },
    textTooltip(): string {
      return storeApi.appSettings.getters.marketPreviewTooltip;
    },
    companies() {
      return storeApi.state.es.getters.previews;
    }
  }
});

export default TheSegmentPreview;
