import _ from 'lodash';

/**
 * Checks if there is a valid value
 * @param {*} val
 * @returns {boolean}
 */
export const hasValidValue = <T>(val: T): boolean => {
  if (val == null) {
    return false;
  }

  const arr = (_.isArray(val) ? val : [val]) as any[];
  const hasValues = arr
    .map((v) => {
      let hasValue = v != null;
      if (hasValue && typeof v === 'object') {
        hasValue = Object.keys(v).length > 0;

        if (hasValue && v.hasOwnProperty('length')) {
          hasValue = v.length > 0;
        }
      } else if (hasValue && typeof v === 'string') {
        hasValue = v.length > 0;
      }

      return hasValue;
    })
    .filter((v) => v);

  return hasValues.length > 0;
};
