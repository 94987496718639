export type IUser = {
  id?: number | null;
  email?: string | null;
  name?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  team_id?: string | null;
  created_at?: number | null;
};

export type UsersState = {
  usersList: IUser[];
};

export const getDefaultState = (): UsersState => ({
  usersList: []
});
