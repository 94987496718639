









import Vue from 'vue';
import type { PropOptions } from "vue/types/umd";

const icons = [
  'avatar',
  'blocks',
  'buildings',
  'chat-gear',
  'data-center',
  'decision-tree',
  'globe',
  'money-bag',
  'people',
  'arrow-left',
  'x'
] as const;
const roles = ['img', 'presentation'] as const;

/**
 * A UIRuleSelectorModalIcon is an svg consisting of a single color, or shades of that color. Use the name prop to select an icon, which will match the filename of the SVG to be rendered inline.
 */
const UIRuleSelectorModalIcon = Vue.extend({
  name: 'UIRuleSelectorModalIcon',
  props: {
    name: {
      type: String,
      required: true,
      validator: value => icons.includes(value),
    } as PropOptions<(typeof icons)[number]>,
    ariaLabel: {
      type: String,
      default: '',
    },
    role: {
      type: String,
      default: 'img',
      validator: value => roles.includes(value),
    } as PropOptions<(typeof roles)[number]>
  },
  computed: {
    icon() {
      return () => import(`./icons/${this.name}.svg`);
    }
  }
});

export default UIRuleSelectorModalIcon;
