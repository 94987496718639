





















import Vue, { PropOptions } from 'vue';
import UIRuleSelectorModalIcon from './icon/UIRuleSelectorModalIcon.vue';
import { UIBadge } from '@intricately/ui-lib';
import rulesetsIcon from './assets/rulesets.svg';
const states = ['normal', 'muted', 'searched', 'selected'] as const;

/**
 * The button component can be used to add a fullwidth button to the bottom of a UIRuleSelectorModalSidebar
 */
const UIRuleSelectorModalButton = Vue.extend({
  name: 'UIRuleSelectorModalButton',
  components: {
    UIRuleSelectorModalIcon,
    UIBadge
  },
  props: {
    /**
     * Renders a badge on the right side of the button.
     */
    count: {
      type: [Number, undefined],
      default: undefined
    } as PropOptions<number | undefined>,
    /**
     * Used to change the overall appearance of the Button.
     */
    state: {
      type: String,
      default: 'normal',
      validator: (value) => states.includes(value)
    } as PropOptions<typeof states[number]>
  },
  data() {
    return {
      rulesetsIcon
    };
  },
  computed: {
    badgeTemplate(): string {
      return this.state === 'searched' ? 'framed' : 'hollow';
    },
    isEmpty(): boolean {
      return this.count === 0 ? true : false;
    }
  }
});

export default UIRuleSelectorModalButton;
