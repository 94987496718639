/* eslint-disable max-lines */
import _ from 'lodash';
import { ActionTree } from 'vuex';
import {
  FeatureTemplate,
  AggsStateTree,
  Input,
  AggsDataUpdateAgg,
  QueryStateGroupTree
} from '../../../es-query/libs/types';
import { initialize as initEs, getQ } from './service';
import { updateMarket } from './utils';
import AggsBlock from '../../../es-query/libs/aggs-block';
import { AggsBlockTree } from '../../../es-query/libs/aggs-block-manager';
import { storeApi } from '../../../index';
import { EsState } from './state';
import { IQueryBlock } from '../../../../API/models/queryUI/queryBlock';
import { IOldRule, IParsedOldRule } from '../../query-templates/actions';
import { Ruleset } from '../../../../API/models/ruleset/ruleset';

const actions = <T extends ActionTree<EsState, Record<string, any>>>(tree: T) =>
  tree;

export const esStateActions = actions({
  /**
   * Initializes es query
   */
  async initialize(
    store,
    payload: { features?: FeatureTemplate[]; queries?: IOldRule[] } = {}
  ) {
    initEs(payload.features, payload.queries);

    store.commit('setQueryTemplates', getQ().get('templates'));
    store.commit(
      'setFeatureTemplates',
      getQ().get('templates', { block: 'features' })
    );

    await storeApi.state.es.actions.updateEsState();
  },
  /**
   * Sets the loading
   */
  async setLoading(store, payload: { value: boolean }) {
    storeApi.state.es.mutations.setLoading(payload.value);
  },

  /**
   * Sets the raw state
   */
  async setRaw(store, payload: { [key: string]: any }) {
    if (payload == null) {
      return null;
    }

    storeApi.state.es.mutations.setRaw(payload);
    return Promise.resolve();
  },

  /**
   * Sets the raw market state
   */
  async setRawMarket(store, payload: { [key: string]: any }) {
    if (payload == null) {
      return null;
    }

    storeApi.state.es.mutations.setRawMarket(payload);
    return Promise.resolve();
  },

  /**
   * Gets the raw format of the es query
   */
  async getQRaw(store, payload?: { isntFromBuckets?: boolean }) {
    const rawClean = getQ().get('raw', { isntFromBuckets: true });
    const rawUnclean = getQ().get('raw', {});

    // DEV: qState is needed for the debugger for example
    await storeApi.state.es.actions.setRaw(rawUnclean);

    if (payload != null && payload.isntFromBuckets) {
      return { raw: rawClean };
    }

    return { raw: rawUnclean };
  },

  /**
   * Gets the state format of the es query
   */
  async getQState(store, payload: { isntFromBuckets?: boolean } = {}) {
    const raw = getQ().get('raw', {});
    const res = {
      state: getQ().get('state', payload)
    };
    // DEV: qState is needed for the debugger for example
    //      It is also used to actually save the state
    await storeApi.state.es.actions.setRaw(raw);

    return res;
  },

  /**
   * Updates the state with the data present on the es-query
   */
  async updateEsState(store, payload: { dontUpdateAggsUi?: boolean } = {}) {
    const queryUi = getQ().get('', { block: 'query' });

    store.commit('setQueryUi', queryUi);

    if (!payload.dontUpdateAggsUi) {
      const aggs = getQ().get('', { block: 'aggs' }) as AggsBlockTree[];
      const aggsUi = aggs.map((agg) => agg.agg.get() as AggsStateTree);
      storeApi.state.es.mutations.setAggsUi(aggsUi);
    }

    storeApi.state.es.mutations.setHits(getQ().get('hits'));
    storeApi.state.es.mutations.setPreviews(getQ().get('preview'));
  },

  /**
   * Add aggregations to the es-query lib
   */
  async addAggs(
    store,
    payload: {
      parentIdentity?: string;
      dontUpdate?: boolean;
      agg?: { identity?: string };
      aggs?: { identity?: string }[];
      addToTop?: boolean;
    }
  ) {
    const config =
      payload.parentIdentity != null
        ? { parentIdentity: payload.parentIdentity }
        : {};

    let aggs = payload.agg != null ? [payload.agg] : payload.aggs;
    aggs = aggs == null ? [] : aggs;

    let changed = false;
    let lastAddedId = '';

    for (let i = 0; i < aggs.length; i += 1) {
      const agg = aggs[i];

      if (agg != null) {
        const res = getQ().add('aggs', {
          templateIdentity: agg.identity,
          addToTop: payload.addToTop,
          ...config
        }) as AggsBlock;

        // Update the last added so we know on the frontend
        // That this was just added
        if (res != null) {
          lastAddedId = (res.get() as AggsStateTree).identity;
        }

        changed = changed || res != null;
      }
    }

    // Only query if something changed
    if (changed && !payload.dontUpdate) {
      if (lastAddedId != null && lastAddedId.length === 0) {
        await storeApi.state.actions.updateLastQueryOrAggAddedId(lastAddedId);
      }

      await storeApi.state.es.actions.updateEsState();
      await storeApi.state.es.actions.saveState();
    }

    return null;
  },

  /**
   * Updates aggregations order on the es-query lib
   */
  async updateAggsOrder(
    store,
    payload: {
      order: string[];
    }
  ) {
    getQ().update('aggs', {
      type: 'order',
      order: payload.order
    });

    // Only query if something changed
    await storeApi.state.es.actions.saveState({ force: true });
  },

  /**
   * Remove aggregations from the es-query lib
   */
  async removeAggs(
    store,
    payload: {
      agg: string | string[];
      dontUpdate?: boolean;
    }
  ) {
    const aggs = (_.isArray(payload.agg)
      ? payload.agg
      : [payload.agg]) as string[];
    const promises = aggs.map(async (agg) =>
      getQ().destroy('aggs', { identity: agg })
    );
    await Promise.all(promises);

    if (payload.dontUpdate) {
      return null;
    }

    await storeApi.state.es.actions.updateEsState();
    await storeApi.state.es.actions.saveState();
    return Promise.resolve();
  },

  /**
   * Updates an aggregation ranges / labels
   */
  async updateRanges(
    store,
    payload: {
      identity?: string;
      dontUpdate?: boolean;
      agg: AggsDataUpdateAgg;
    }
  ) {
    getQ().update('aggs', {
      identity: payload.identity,
      value: payload.agg,
      config: {
        replace: false
      }
    });

    if (payload.dontUpdate) {
      return null;
    }

    await storeApi.state.es.actions.updateEsState();
    await storeApi.state.es.actions.saveState();
    return Promise.resolve();
  },

  /**
   * Adds query
   */
  async addQuery(
    store,
    payload: {
      parentIdentity?: string;
      query: IParsedOldRule;
      state?: QueryStateGroupTree | IQueryBlock;
    }
  ) {
    const queryState = (payload.state != null
      ? payload.state
      : {}) as IQueryBlock;
    const removeIsBuckets = payload.state == null || !queryState.isFromBucket;

    const res = getQ().add('query', {
      state: payload.state,
      templateIdentity: payload.query.identity,
      parentIdentity: payload.parentIdentity,
      removeIsFromBucket: removeIsBuckets,
      isFromBucket: queryState.isFromBucket
    });

    if (res != null) {
      const lastAddedId = res.get().identity;

      if (lastAddedId != null && lastAddedId.length === 0) {
        await store.dispatch('state/updateLastQueryOrAggAddedId', lastAddedId, {
          root: true
        });
      }
    }

    await storeApi.state.es.actions.updateEsState();
    await storeApi.state.es.actions.saveState();
  },

  /**
   * Changes a query
   */
  async changeQuery(
    store,
    payload: {
      identity?: string;
      isFromBucket?: boolean;
      bucketKey?: string | number;
      removeIsFromBucket?: boolean;
      dontUpdate?: boolean;
      replace?: boolean;
      query?: IOldRule;
      inputs?: Input[];
    }
  ) {
    const removeIsBuckets = !payload.isFromBucket;

    getQ().update('query', {
      identity: payload.identity,
      config: {
        query: payload.query,
        inputs: payload.inputs,
        replace: payload.replace,
        bucketKey: payload.bucketKey,
        removeIsFromBucket: removeIsBuckets || payload.removeIsFromBucket
      }
    });

    if (payload.dontUpdate) {
      return null;
    }

    await storeApi.state.es.actions.updateEsState();
    await storeApi.state.es.actions.saveState();

    return Promise.resolve();
  },

  /**
   * Edits query values
   */
  async editQueryValues(
    store,
    payload: {
      identity?: string;
      isFromBucket?: boolean;
      dontUpdate?: boolean;
      query?: IOldRule;
      inputs?: Input[];
    }
  ) {
    const removeIsBuckets = !payload.isFromBucket;

    getQ().update('query', {
      identity: payload.identity,
      config: {
        query: _.assign({}, payload.query, {
          inputs: payload.inputs
        }),
        replace: false,
        removeIsFromBucket: removeIsBuckets
      }
    });

    if (payload.dontUpdate) {
      return null;
    }

    await storeApi.state.es.actions.updateEsState();
    await storeApi.state.es.actions.saveState();
    return Promise.resolve();
  },

  /**
   * Removes a query rule block
   * @param {Store} store
   * @param {string} identity
   */
  async removeQuery(
    store,
    payload: { identity: string; isFromBucket?: boolean }
  ) {
    if (
      payload == null ||
      payload.identity == null ||
      payload.identity.length === 0
    ) {
      return null;
    }

    getQ().destroy('query', {
      identity: payload.identity
    });

    // Bucketization is a bit different because it needs to handle the 100,0,0,0
    // The way of us doing this is querying the market at the get go
    if (payload.isFromBucket) {
      await store.dispatch('queryMarket');
    }

    await storeApi.state.es.actions.updateEsState();
    await storeApi.state.es.actions.saveState({
      dontQueryMarket: payload.isFromBucket
    });

    return null;
  },

  /**
   * Adds query group
   */
  async addQueryGroup(
    store,
    payload: {
      parentIdentity: string;
      queryGroup?: Ruleset['attributes']['query_template'];
    }
  ) {
    getQ().add('query-group', payload);

    const queryUi = getQ().get('', { block: 'query' });
    storeApi.state.es.mutations.setQueryUi(queryUi);

    await storeApi.state.es.actions.updateEsState();
  },

  /**
   * Updates query operator
   */
  async updateQueryGroupOperator(
    store,
    payload: {
      identity?: string;
      operator?: 'must' | 'should' | 'must_not';
      dontUpdate?: boolean;
    }
  ) {
    getQ().update('query', {
      identity: payload.identity,
      config: {
        operator: payload.operator
      }
    });

    if (payload.dontUpdate) {
      return null;
    }

    await storeApi.state.es.actions.updateEsState();
    await storeApi.state.es.actions.saveState();
    return Promise.resolve();
  },

  /**
   * Updates es-query state with a new payload
   */
  async updateState(
    _store,
    payload: {
      // TODO: what here???
    }
  ) {
    if (payload == null) {
      return null;
    }

    getQ().destroy('aggs', { all: true });
    getQ().destroy('query', {});

    getQ().update('state', payload);

    await storeApi.state.es.actions.updateEsState();
    return Promise.resolve();
  },

  /**
   * Updates es-query hits data with a new payload
   */
  async updateHits(store, payload: { [key: string]: any }) {
    if (payload == null) {
      return null;
    }

    getQ().update('hits', { hits: payload });
    await storeApi.state.es.actions.updateEsState();
    return Promise.resolve();
  },

  /**
   * Sets up requests for the backend's elastic search. this is the only place to do it
   */
  queryMarket(store) {
    return updateMarket(store);
  },
  /**
   * Saves the state
   */
  async saveState(
    store,
    payload: {
      force?: boolean;
      debounceTime?: number;
      dontSaveNextTime?: number;
      dontQueryMarket?: boolean;
    } = {}
  ) {
    await storeApi.state.actions.queryState(payload);
  }
});
