import * as t from 'io-ts';
import Model from '../../classes/model';
import { ModelInterface } from '../interfaceGetters/modelInterface';

export const queryUiInputValueSchema = {
  /**
   * Actual value of the input.
   */
  value: t.union([t.number, t.string, t.null]),
  /**
   * Key that the input value represents
   */
  query_block_key: t.string,
  /**
   * If the input value should be considered.
   * Only relevant for multiselect inputs.
   *
   * TODO: verify if this is a front-end artifact
   */
  checked: t.boolean,
  /**
   * TODO: verify what this means. Seems like it is true even for user defined values.
   */
  default: t.boolean,
  /**
   * label of the selected value.
   */
  label: t.number
};

const possibleValuesSchema = {
  /**
   * Type of operation to perform
   * @example
   *  "list-download"
   *
   * TODO: verify if we can use an enum here
   */
  type: t.union([t.literal('autosuggest'), t.literal('list-download')]),
  show_filter: t.boolean,
  /**
   * API endpoint to use to fetch the list resources.
   *
   * ex: "/api/v1/explorer/query_template_lists/industries"
   */
  api_endpoint: t.string
};

const queryUiInputSchema = {
  possible_values: t.partial(possibleValuesSchema),
  /**
   * Input label to display to the user
   * @example
   *  "Select one or more industries"
   */
  label: t.string,
  /**
   * Default value of the input
   */
  default: t.any,
  /**
   * Type of input
   * @example
   *  "multi-select"
   *
   */
  type: t.string,
  /**
   * Key of the input value in the ES query.
   * @example
   *  "industries_name"
   *
   */
  query_block_key: t.string,
  /**
   * Key of the input value in the bucket (segment)
   */
  bucket_key: t.string,
  /**
   * If the field is required
   */
  required: t.boolean,
  /**
   * TODO: Investigate
   */
  template_key: t.string,
  /**
   * Array of values for this input field.
   */
  value: t.union([
    t.array(t.union([t.partial(queryUiInputValueSchema), t.string, t.number])),
    t.string,
    t.number
  ])
};

export const queryUiInputModel = new Model(t.partial(queryUiInputSchema));

export type QueryUiInput = ModelInterface<typeof queryUiInputModel>;
