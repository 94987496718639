import { Label, Bucket, FeatureSegment } from '../libs/types';
import { getBucketKeys, isSameBucketKey } from './buckets';

/**
 * Converts a label to an aggs segment
 */
export const convertLabelToAggSegment = (
  label: Label
): FeatureSegment | null => {
  if (label == null || label.key == null || label.value == null) {
    return null;
  }

  const segmentToChange: FeatureSegment = {};
  const keySplit = label.key.split('-');
  const [min, max] = keySplit;

  if (min != null && min !== '*') {
    segmentToChange.min = min;
  }

  if (max != null && max !== '*') {
    segmentToChange.max = max;
  }

  segmentToChange.label = label.value;

  return segmentToChange;
};

/**
 * Converts a segment to label
 * @param labels      a default array coming with possible labels
 * @param forceZero   forces a decimal .0 on the key
 */
export const convertAggSegmentToLabel = (
  segment: FeatureSegment = {},
  labels: Label[] = [],
  forceDecimal = false
): Label => {
  let min = (segment.min || segment.from || '*') + '';

  if (forceDecimal && min !== '*' && !min.includes('.')) {
    min += '.0';
  }

  let max = (segment.max || segment.to || '*') + '';

  if (forceDecimal && max !== '*' && !max.includes('.')) {
    max += '.0';
  }

  const key = `${min}-${max}`;

  let value = segment.label;

  if (!value && labels) {
    // eslint-disable-next-line prefer-destructuring
    value = labels
      .filter((label) => label.key === key)
      .map((label) => label.value)[0];
  }

  return { value, key };
};

/**
 * Converts a bucket to label
 * @param segments segments coming from the agg
 * @param forceZero forces a decimal .0 on the key
 */
export const convertBucketToLabel = (
  bucket: Bucket & FeatureSegment = {},
  segments: (Bucket & FeatureSegment)[] = [],
  forceDecimal = false
): Label => {
  const aggValue = convertAggSegmentToLabel(bucket, [], forceDecimal);
  if (aggValue.value != null) {
    return aggValue;
  }

  // Check if we have a label on the segments, in case we weren't
  // Able to convert it
  const bucketKey = bucket.key ?? bucket.bucket_key;
  const foundSeg = segments.find((segment) => {
    const keys = getBucketKeys([segment]);
    const found = keys.find(
      (key) =>
        isSameBucketKey(key, bucket.key) ||
        isSameBucketKey(key, bucket.bucket_key)
    );

    return found != null;
  });
  const label = foundSeg != null ? foundSeg.label : undefined;

  return {
    value: label,
    key: bucketKey
  };
};
