















import Vue, { PropOptions } from 'vue';
import ApplyClass from './funcional/apply/applyClass/ApplyClass.vue';

const themes = ['info', 'danger'] as const;

const UIBlockBanner = Vue.extend({
  components: {
    ApplyClass
  },
  props: {
    theme: {
      type: String,
      required: true,
      validate: (value) => themes.includes(value)
    } as PropOptions<typeof themes[number]>
  }
});

export default UIBlockBanner;
