import { Rule } from '../../../API/models/rule/rule';
import { RuleCategory } from '../../../API/models/ruleCategory/ruleCategory';
import { QueryTemplatesState } from './state';

/**
 * Sets the templates on the store
 */
export const setTemplates = (
  state: QueryTemplatesState,
  payload: typeof state['templates']
) => {
  state.templates = payload;
};

/**
 * Sets the templates as ready
 */
export const templatesAreReady = (state: QueryTemplatesState) => {
  state.templatesAreReady = true;
};

/**
 * Sets the templates as not ready
 */
export const templatesAreNotReady = (state: QueryTemplatesState) => {
  state.templatesAreReady = false;
};

export const SET_CATEGORIES = (
  state: QueryTemplatesState,
  payload: RuleCategory[]
) => {
  state.categories = payload;
};

export const SET_RULES = (state: QueryTemplatesState, payload: Rule[]) => {
  state.rules = payload;
};
