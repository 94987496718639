import {
  isEmpty as empty,
  assign,
  concat,
  isNil,
  cloneDeep,
  set,
  get as lodashGet
} from 'lodash';
import stringifiedPath from './stringified-path';
import {
  convertAggSegmentToLabel,
  convertLabelToAggSegment
} from '../utils/labels';
import {
  FeatureRawTree,
  FeatureTemplate,
  Label,
  AggsStateTree,
  FeatureEsBlock,
  AggsDataUpdateAgg,
  AggsDataUpdateConfig,
  FeatureSegment
} from './types';

export default class AggsBlock {
  private myIdentity = '';
  private originalTmpl: FeatureTemplate | null = null;
  private myAggs: {
    id?: string;
    queryTemplateId: string;
    segmentType?: string;
    defaultSegments?: {
      // TODO: need to improve
      [key: string]: any;
    }[];
    parentId?: string;
    segmentOrder?: {
      // TODO: need to improve
      [key: string]: any;
    }[];
    esAggsBlock: FeatureEsBlock;
    esQueryBlock?: {
      [key: string]: any;
    };
    esSortBlock?: {
      [key: string]: any;
    };
    esSegmentPreviewBlock?: {
      [key: string]: any;
    };
    label: string;
    description?: string;
    advertisement?: boolean;
    includeInSegmenterByDefault?: string;
    queryInputs?: {
      // TODO: need to improve
      [key: string]: any;
    }[];
    // TODO: what is this??
    listBased?: any;
    esTemplate: {
      [key: string]: any;
    };
  } | null = null;
  private mySegments: {
    // TODO: need to improve
    [key: string]: any;
  }[] = [];
  private myEsAggsBlockKey = '';

  /**
   * Initializes the aggs block
   */
  public constructor(initTmpl: FeatureTemplate, initStateAgg?: AggsStateTree) {
    this.init(initTmpl, initStateAgg);
  }

  /**
   * Initializes the aggs block. Acts as the constructor
   */
  private init(template: FeatureTemplate, initStateAgg?: AggsStateTree) {
    this.myIdentity = `_${Math.random()
      .toString(36)
      .substr(2, 9)}`;

    this.originalTmpl = template;

    this.myAggs = {
      id: template['id'],
      queryTemplateId: template['query_template_id'],
      segmentType: template['segment_type'],
      defaultSegments: template['default_segments'],
      parentId: template['parent_id'],
      segmentOrder: template['segment_order'],
      esAggsBlock: template['elasticsearch_aggs_block'],
      esQueryBlock: template['elasticsearch_query_block'],
      esSortBlock: template['elasticsearch_sort_block'],
      esSegmentPreviewBlock: template['elasticsearch_segment_preview_block'],
      label: template['label'] || '',
      description: template['description'],
      advertisement: template['advertisement'],
      includeInSegmenterByDefault:
        template['include_feature_in_segmentter_by_default'],
      queryInputs: template['query_inputs'],
      listBased: template['list_based'],
      esTemplate: template['elasticsearch_template'] || {}
    };

    // Mapping the segments so there is a cloned version and we don't change by reference
    this.mySegments = (template['default_segments'] || []).map((seg) => ({
      ...seg
    }));
    this.myEsAggsBlockKey = this.getEsAggsBlockKey(template);

    // Take care of state based aggs
    if (initStateAgg != null) {
      if (initStateAgg.segments != null) {
        this.mySegments = initStateAgg.segments.map((seg) => ({ ...seg }));
      }

      if (initStateAgg.queryInputs != null) {
        this.myAggs.queryInputs = initStateAgg.queryInputs;
      }
    }
  }

  /**
   * Get the es aggs block key out of a template
   */
  private getEsAggsBlockKey(template: FeatureTemplate): string {
    if (template != null && template['elasticsearch_aggs_block']) {
      const res = Object.keys(template['elasticsearch_aggs_block']);
      return res == null || res[0] == null ? '' : res[0];
    }

    return '';
  }

  /**
   * Destroys the agg block by resetting him
   */
  public destroy() {
    this.myAggs = null;
    this.mySegments = [];
    this.myEsAggsBlockKey = '';
  }

  /**
   * Converts a raw range to a segment
   */
  private convertRawRangeToSegment(range: FeatureSegment = {}): FeatureSegment {
    const segmentToChange: FeatureSegment = {};

    if (range.min != null && range.min !== '*') {
      segmentToChange.min = range.min;
    } else if (range.from != null && range.from !== '*') {
      segmentToChange.min = range.from;
    }

    if (range.max != null && range.max !== '*') {
      segmentToChange.max = range.max;
    } else if (range.to != null && range.to !== '*') {
      segmentToChange.max = range.to;
    }

    segmentToChange.label = range.value;

    return segmentToChange;
  }

  /**
   * Updates the block with new labels
   */
  private updateLabels(labels: Label[] = []) {
    if (this.mySegments == null) {
      return this;
    }

    this.mySegments = this.mySegments.map((seg) => {
      const segLabel: Label = convertAggSegmentToLabel(seg);

      const found = labels.filter((l) => l.key === segLabel.key);
      if (found.length === 0) {
        return seg;
      }

      const newLabelData = convertLabelToAggSegment(found[0]);
      if (newLabelData != null) {
        seg.label = newLabelData.label != null ? newLabelData.label : seg.label;
      }

      return seg;
    });

    return this;
  }

  /**
   * Updates the block with new raw ranges
   */
  private updateRawRanges(ranges: FeatureSegment[] = []): AggsBlock {
    if (ranges == null) {
      return this;
    }

    this.mySegments = ranges.map((range) => {
      const newSegment = this.convertRawRangeToSegment(range);

      // Already has a label so lets use that instead of finding a new one
      if (newSegment.label != null) {
        return newSegment;
      }

      // Try to find an old label so it is a bit more complete
      for (let i = 0; i < this.mySegments.length; i += 1) {
        const oldSegLabel = convertAggSegmentToLabel(this.mySegments[i]);
        const newSegLabel = convertAggSegmentToLabel(newSegment);

        if (oldSegLabel.key === newSegLabel.key) {
          newSegment.label = oldSegLabel.value;
          break;
        }
      }

      return newSegment;
    });

    return this;
  }

  /**
   * Updates the block with a new data
   */
  public update(newData: Label[], params: { type: 'labels' }): AggsBlock;
  public update(
    newData: AggsDataUpdateAgg,
    params: {
      type: 'raw';
      replace: true;
    }
  ): AggsBlock;
  public update(
    newData: AggsDataUpdateAgg,
    params?: Record<string, any>
  ): AggsBlock;
  public update(
    newData: Label[] | null | AggsDataUpdateAgg = null,
    params: AggsDataUpdateConfig = {}
  ) {
    if (params != null && params.type === 'labels' && newData != null) {
      return this.updateLabels(newData as Label[]);
    }

    const newDataSeg = newData as AggsDataUpdateAgg;
    if (
      params != null &&
      Object.keys(params).length > 0 &&
      newDataSeg != null
    ) {
      if (
        !params.replace &&
        newDataSeg.customizable &&
        newDataSeg.segments != null
      ) {
        this.mySegments = newDataSeg.segments;
      } else if (
        params.replace &&
        params.type === 'raw' &&
        newDataSeg.ranges != null
      ) {
        return this.updateRawRanges(newDataSeg.ranges);
      }
    } else if (
      !empty(newData) &&
      (newData as FeatureTemplate).query_template_id != null
    ) {
      this.init(newData as FeatureTemplate);
    }

    return this;
  }

  /**
   * Checks if the aggs block is empty
   * @returns {boolean}
   */
  public isEmpty(): boolean {
    return empty(this.myAggs);
  }

  /**
   * Transforms segments into ranges
   */
  // TODO: proper typing segments: Segment[]
  private transformSegmentsToRanges(segments: any[] = []): Range[] {
    return segments.reduce((memo, current) => {
      const result: FeatureSegment = {};

      if (
        !isNil(current.min) &&
        current.min != null &&
        current.min.toString().trim() !== ''
      ) {
        result.from = current.min;
      }

      if (
        !isNil(current.max) &&
        current.max != null &&
        current.max.toString().trim() !== ''
      ) {
        result.to = current.max;
      }

      if (empty(result)) {
        return memo;
      }

      return concat(memo, result);
    }, []);
  }

  /**
   * Get list based template
   */
  private getListBasedTemplate(): FeatureEsBlock | null {
    if (this.myAggs == null) {
      return null;
    }

    const baseBlock = this.myAggs.esAggsBlock;
    const aggsBlock: typeof baseBlock = cloneDeep(baseBlock);
    const termsPath = stringifiedPath(aggsBlock, 'terms');

    const compiled = assign({}, lodashGet(aggsBlock, termsPath), {
      include: this.mySegments.map((segment) => segment.value),
      size: this.mySegments.length
    });

    set(aggsBlock, termsPath, compiled);

    return aggsBlock;
  }

  /**
   * Gets an usable backend raw
   */
  private getRaw(): FeatureRawTree | null {
    if (this.myAggs == null) {
      return null;
    }

    if (this.myAggs.segmentType === 'user-defined') {
      const aggsBlock = this.myAggs.esAggsBlock;
      const aggsKeys = Object.keys(aggsBlock);
      const aggsKey = aggsKeys[0] == null ? '' : aggsKeys[0];

      if (this.myAggs.listBased) {
        return this.getListBasedTemplate();
      }

      return assign({}, aggsBlock, {
        [aggsKey]: assign({}, aggsBlock[aggsKey], {
          range: assign({}, aggsBlock[aggsKey].range, {
            ranges: this.transformSegmentsToRanges(this.mySegments)
          })
        })
      });
    }

    return this.myAggs.esAggsBlock;
  }

  /**
   * Gets block used labels
   */
  private getLabels(): Label[] {
    if (this.mySegments == null) {
      return [];
    }

    return this.mySegments.map((seg) => convertAggSegmentToLabel(seg));
  }

  /**
   * Get aggs
   */
  public get(type?: ''): AggsStateTree;
  public get(type: 'raw'): FeatureRawTree | null;
  public get(type: 'labels'): Label[];
  public get(type: 'raw' | 'labels' | '' = '') {
    if (type === 'raw') {
      return this.getRaw();
    }

    if (type === 'labels') {
      return this.getLabels();
    }

    return {
      esAggsBlockKey: this.myEsAggsBlockKey,
      esQueryBlock: this.myAggs != null ? this.myAggs.esQueryBlock : {},
      label: this.myAggs != null ? this.myAggs.label : '',
      description: this.myAggs != null ? this.myAggs.description : '',
      identity: this.myIdentity,
      segments: this.mySegments,
      customizable:
        this.myAggs != null
          ? this.myAggs.segmentType === 'user-defined'
          : false,
      queryInputs: this.myAggs != null ? this.myAggs.queryInputs : [],
      listBased: this.myAggs != null ? this.myAggs.listBased : null,
      queryTemplateId: this.myAggs != null ? this.myAggs.queryTemplateId : '',
      template: this.originalTmpl
    };
  }
}
