import { get, isPlainObject } from 'lodash';
import stringifiedPath from './stringified-path';

export default (object: { [key: string]: any }, key: string): string => {
  const path = stringifiedPath(object, key);
  const cache = get(object, path);

  if (isPlainObject(cache)) {
    const nestedKey = Object.keys(cache);
    return `${path}["${
      nestedKey == null || nestedKey[0] == null ? '' : nestedKey[0]
    }"]`;
  }

  return '';
};
