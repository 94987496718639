import * as models from './models/jsonApiModels';
import * as path from './pathConstructors/jsonApiPathConstructors';

/**
 * Methods and others related to the jsonApi
 */
export const jsonApi = {
  path,
  models
};
