var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"progress-bar",class:{
    'progress-bar--a': _vm.type === 'a',
    'progress-bar--b': _vm.type === 'b'
  }},[_c('div',{staticClass:"progress-bar__filler",style:({
      width: _vm.parsedWidthValue + '%',
      'background-color': _vm.color,
      border: !_vm.hasBorder ? null : _vm.borderWidth + 'px solid ' + _vm.borderColor
    })}),_vm._v(" "),(_vm.setLabel)?_c('div',{staticClass:"progress-bar__label"},[_vm._v(_vm._s(_vm.parsedValue)+" %")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }