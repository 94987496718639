


























































































import Vue from 'vue';
import { UILoader } from '@intricately/ui-lib';
import UIActionButton from './UIActionButton.vue';
import formatNumber from '../filters/format-number';
import TheReportDownload from './segment-preview/TheReportDownload.vue';
import { storeApi } from '../store/index';
import { beamer_config } from '../utils/beamer';

const TheAppHeader = Vue.extend({
  name: 'TheAppHeader',
  components: {
    UILoader,
    UIActionButton
  },
  data() {
    return {
      beamerSelector: beamer_config.selector,
      marketSpotlightsURL:
        'https://www.notion.so/intricately/Pre-Sales-Reports-9152b06c9d574c97975cdf9e14cab209#51768228eb02459699f85f7208345d5e',
      prospectOptions: [
        {
          text: 'Enrichment',
          href: '/enrichment'
        },
        {
          text: 'My Smart Lists',
          href: '/smartLists'
        }
      ],
      alertOptions: [
        {
          text: 'Watch Lists',
          href: '/watchlists'
        },
        {
          text: 'Events',
          href: '/events'
        }
      ],
      learnOptions: [
        {
          text: 'Help Center',
          href: 'lorem',
          external: true
        },
        {
          text: 'Webinars',
          href: 'lorem',
          external: true
        },
        {
          text: 'Blogs',
          href: 'lorem',
          external: true
        }
      ],
      profileOptions: [
        {
          text: 'User Administration',
          href: 'lorem',
          external: true
        },
        {
          text: 'Team Administration',
          href: 'lorem'
        },
        {
          text: 'Settings',
          href: 'lorem'
        },
        {
          text: 'Logout',
          href: 'lorem'
        }
      ]
    };
  },
  computed: {
    showLoader(): boolean {
      return storeApi.state.es.getters.isLoading;
    },
    appTitle(): string {
      return storeApi.appSettings.getters.title;
    },
    hasCompanies(): boolean {
      return this.totalCompanies != null && this.totalCompanies.length > 0;
    },
    totalCompanies(): string {
      let msg = '';

      const { total } = storeApi.state.es.getters.hits;

      if (total != null && total > 0) {
        msg =
          total === 1 ? '1 matching company' : `${formatNumber(total)} Records`;
      }

      return msg;
    }
  },
  methods: {
    openReportDownloadModal() {
      this.$showModal(TheReportDownload);
    }
  },
  mounted() {
    const beamerSelector = this.beamerSelector;

    /**
     * Set Beamer Icon role to alert if there's a notification.
     */
    function setBeamerAlertRole() {
      const beamerIcon = document?.querySelector('#' + beamerSelector);
      const beamerNotification = document?.querySelector('.beamer_icon.active');
      if (beamerIcon && beamerNotification) {
        beamerIcon.setAttribute('role', 'alert');
      }
    }

    setTimeout(setBeamerAlertRole, 3000);
  }
});

export default TheAppHeader;
