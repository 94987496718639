/**
 * Abbreviate big numbers to symbol e.g. 1200 --> 1.2k, 33500000 --> 33M.
 * It also applies a custom rounding e.g. 1.08M --> 1,1M
 */
export default function(number: number, roundBreakpoint = 0.07): string {
  if (typeof number !== 'number') {
    return '0';
  }

  const symbol = ['', 'k', 'M', 'B', 'T'];
  const tier = (Math.log10(number) / 3) | 0;
  if (tier === 0) {
    return number + '';
  }
  const scale = Math.pow(10, tier * 3);
  const scaled = number / scale;
  const truncated = scaled | 0;
  const rounded =
    scaled > truncated + roundBreakpoint && scaled < truncated + 0.1
      ? truncated + 0.1
      : scaled;
  const finalNumber =
    rounded > 9 || rounded.toString().includes('.0')
      ? rounded.toFixed(0)
      : rounded.toFixed(1);
  const abbreviatedNumber = finalNumber + symbol[tier];
  return abbreviatedNumber.includes('.0')
    ? abbreviatedNumber.slice(0, abbreviatedNumber.indexOf('.0')) + symbol[tier]
    : abbreviatedNumber;
}
