import { updateAggsUi } from './utils';
import { EsState } from './state';

export const setLoading = (
  state: EsState,
  payload: typeof state['isLoading']
) => {
  state.isLoading = payload;
};

export const setOldRaw = (state: EsState, payload: typeof state['oldRaw']) => {
  state.oldRaw = payload == null ? {} : payload;
};

export const setRaw = (state: EsState, payload: typeof state['raw']) => {
  state.raw = payload;
};

export const setAggsUi = (state: EsState, payload: typeof state['aggsUi']) => {
  state.oldAggsUi = state.aggsUi;
  state.aggsUi = updateAggsUi(state, payload);
};

export const setRawMarket = (
  state: EsState,
  payload: typeof state['rawMarket']
) => {
  state.rawMarket = payload;
  state.aggsUi = updateAggsUi(state, state.aggsUi);
};

export const setFeatureTemplates = (
  state: EsState,
  payload: typeof state['featureTemplates']
) => {
  state.featureTemplates = payload;
};

export const setQueryTemplates = (
  state: EsState,
  payload: typeof state['queryTemplates']
) => {
  state.queryTemplates = payload;
};

export const setQueryUi = (
  state: EsState,
  payload: typeof state['queryUi']
) => {
  state.queryUi = payload;
};

export const setPreviews = (
  state: EsState,
  payload: typeof state['previews']
) => {
  state.previews = payload;
};

export const setHits = (state: EsState, payload: typeof state['hits']) => {
  state.hits = payload == null ? { hits: [], total: 0 } : payload;
};
