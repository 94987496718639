import { ActionContext } from 'vuex';
import { explorerApi } from '../../../API/clients/explorer/explorer.client';
import { storeApi } from '../../index';
import { Rule } from '../../../API/models/rule/rule';

/**
 * TODO: Replace this for the new format when the api is ready.
 */
export interface IOldRule {
  description?: Rule['attributes']['description'];
  include_feature_in_builder_by_default?: Rule['attributes']['default'];
  elasticsearch_template?: Rule['attributes']['query_template']['elasticsearch_template'];
  label?: Rule['attributes']['title'];
  inputs?: Rule['attributes']['query_template']['inputs'];
  id: Rule['id'];
  /**
   * TODO: Investigate.
   */
  advertisement?: boolean;
  categoryId?: string;
}

export interface IParsedOldRule extends IOldRule {
  identity?: string;
  advertisement?: boolean;
  display_order?: number;
}

/**
 * Fetches templates
 */
export const fetchTemplates = (
  store: ActionContext<any, any>,
  payload?: {
    search: string;
  }
) =>
  new Promise<IOldRule[]>(async (resolve) => {
    const { data: rules, included: categories } = await explorerApi.get(
      'rule',
      {
        include: ['category'],
        filter: {
          search: payload?.search || ''
        },
        sort: ['matching', 'title']
      }
    );

    if (!categories) {
      throw new Error('Could not get the rule categories');
    }

    const mappedRulesets = rules.map((ruleset) => {
      return {
        description: ruleset.attributes.description,
        include_feature_in_builder_by_default: ruleset.attributes.default,
        elasticsearch_template:
          ruleset.attributes.query_template.elasticsearch_template,
        label: ruleset.attributes.title,
        inputs: ruleset.attributes.query_template.inputs,
        id: ruleset.id,
        categoryId: ruleset.relationships.category.data.id
      };
    });

    storeApi.queryTemplates.mutations.SET_CATEGORIES(categories);
    storeApi.queryTemplates.mutations.SET_RULES(rules);
    storeApi.queryTemplates.mutations.setTemplates(mappedRulesets);
    storeApi.queryTemplates.mutations.templatesAreReady();

    return resolve(mappedRulesets);
  }).catch((error) => {
    store.commit('templatesAreNotReady');
    throw error;
  });
