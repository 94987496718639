import { get as g } from 'lodash';
import { Hit, Preview } from './types';

export default class SegmentPreview {
  private myPreview: Hit[] = [];

  /**
   * Initializes the segment preview
   * @param {Hit[]} preview
   */
  public constructor(preview: Hit[] = []) {
    this.myPreview = preview;
  }

  /**
   * Updates the preview
   * @param {Hit[]} newPreview
   */
  public update(newPreview: Hit[] = []) {
    if (newPreview != null && newPreview.length) {
      this.myPreview = newPreview;
    }
  }

  /**
   * Gets preview data
   * @returns {Preview[]}
   */
  public get(): Preview[] {
    return this.myPreview.map((prev) => {
      const spend1 = g(prev, 'report.spend', 0);
      const spend2 = g(prev, '_source.report.spend', 0);

      return {
        // eslint-disable-next-line no-underscore-dangle
        id: prev._id != null ? prev._id : prev.id,
        name: g(prev, '_source.name', ''),
        logo: g(prev, '_source.logo_url', ''),
        employees: g(prev, '_source.employees.count', 0),
        growth: g(prev, '_source.growth_v1.yoy', 0),
        spend: spend1 !== 0 ? spend1 : spend2
      };
    });
  }
}
