import { ActionContext } from 'vuex';
import {
  getReportEnrichments,
  getReportFieldSets,
  createReport as sdkCreateReport,
  updateReport as sdkUpdateReport,
  getReportHistoryList
} from '../../../sdk/modules/reports';
import { ReportsState } from './state';
import { Components } from '../../../../typings/openapi/explorer_download_history';

/**
 * Fetches the user enrichments
 * @param {Store} store
 */
export const fetchEnrichments = async (
  store: ActionContext<any, any>,
  params: {
    pageNumber?: number;
    pageLimit?: number;
  } = {}
) => {
  const data = await getReportEnrichments(
    { sort: '-created_at' },
    {},
    {
      number: params.pageNumber != null ? params.pageNumber : 1,
      limit: params.pageLimit != null ? params.pageLimit : 20
    }
  );
  store.commit('setUserEnrichments', data.data);

  return data;
};

/**
 * Fetches the report fields set
 * @param {Store} store
 */
export const fetchReportFieldSets = async (
  store: ActionContext<any, any>,
  params: {
    payload?: string;
  } = {}
) => {
  const data = await getReportFieldSets(params.payload);
  store.commit('setReportFieldSets', data);
  return data;
};

/**
 * Fetches the user enrichments
 * @param {Store} store
 */
export const fetchReports = async (
  store: ActionContext<any, any>,
  params: {
    userId?: string;
    search?: string;
    pageNumber?: number;
    pageLimit?: number;
  } = {}
) => {
  const queryFilter: { [key: string]: any } = {};
  if (params.userId != null && params.userId.length > 0) {
    queryFilter.user_id = params.userId;
  }
  if (params.search != null && params.search.length > 0) {
    queryFilter.name = params.search;
  }

  const data = await getReportHistoryList(
    { sort: '-created_at' },
    queryFilter,
    {
      number: params.pageNumber != null ? params.pageNumber : 1,
      limit: params.pageLimit != null ? params.pageLimit : 5
    }
  );
  store.commit('setReportsList', data.data);

  return data;
};

/**
 * Request a report creation
 * @param {Store} store
 * @param {{ name: string; stateId?: string; fieldId?: string; fileType?: string; enrichmentId?: string; }} payload
 */
export const createReport = async (
  store: ActionContext<any, any>,
  params: {
    name?: string;
    stateId?: string;
    fieldId?: string;
    format?: string;
    enrichmentId?: string | null;
  }
) => {
  const attributesParams: { [key: string]: string | number | undefined } = {
    name: params.name
  };

  if (params.stateId != null && params.stateId.length > 0) {
    attributesParams.explorer_state_id = params.stateId;
  }
  if (params.fieldId != null && params.fieldId.length > 0) {
    attributesParams.explorer_report_field_set_id = parseInt(
      params.fieldId,
      10
    );
  }
  if (params.format != null && params.format.length > 0) {
    attributesParams.format = params.format;
  }
  if (params.enrichmentId) {
    attributesParams.enrichment_job_id = parseInt(params.enrichmentId, 10);
  }

  const res = await sdkCreateReport({
    data: {
      type: 'list',
      attributes: attributesParams
    }
  });

  store.commit('updateReport', res.data);
  return res;
};

/**
 * Request a report update
 * @param {Store} store
 * @param {{ reportId: string; name: string; }} payload
 */
export const updateReport = async (
  store: ActionContext<any, any>,
  params: {
    reportId: string;
    name: string;
  }
) => {
  const res = await sdkUpdateReport(
    { reportId: params.reportId },
    {
      data: {
        attributes: {
          name: params.name
        }
      }
    }
  );

  store.commit('updateReport', res.data);
  return res;
};

interface IReportDownload extends Omit<Components.Schemas.Download, 'id'> {
  reportId: string;
}

/**
 * Request a report update
 * @param {Store} store
 * @param {{ reportId: string; progress: number; status: string; }} payload
 */
export const updateReportProgress = async (
  store: ActionContext<any, any>,
  params: IReportDownload
) => {
  const list: ReportsState['reportsList'] = store.state.reportsList;
  const item = list.find((i) => `${i.id}` === `${params.reportId}`);

  if (!item) return false;

  item.meta = item.meta == null ? {} : item.meta;
  item.meta.progress = params.progress;
  item.meta.status = params.status;

  store.commit('updateReport', item);
  return true;
};
