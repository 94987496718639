import Logger from '../logger/Logger.class';

const { prodError } = new Logger('Config');

declare global {
  interface Window {
    beamer_config: IBeamerConfig;
  }
}

/**
 * Beamer configuration.
 */
export interface IBeamerConfig {
  /**
   * This is your product code on Beamer
   */
  product_id: string;
  /**
   * Optional: Id, class (or list of both) of the HTML element to use as a button
   */
  selector?: string;
  /**
   * Optional: Choose how to display the Beamer panel in your site
   */
  display?: 'left' | 'right' | 'popup' | 'in-app';
  /**
   * Optional: Display Beamer panel in a new window
   */
  standalone?: boolean;
}

export const beamer_config: IBeamerConfig = {
  product_id: process.env.BEAMER_PRODUCT_ID + '',
  selector: 'beamer-bell',
  display: 'right'
};

/**
 * Beamer setup.
 */
export default function beamerConfig() {
  const scriptTag = document.createElement('script');
  scriptTag.type = 'text/javascript';
  scriptTag.src = 'https://app.getbeamer.com/js/beamer-embed.js';
  scriptTag.defer = true;

  try {
    window.beamer_config = beamer_config;
    document.head.appendChild(scriptTag);
  } catch (error) {
    error.message = `Failed to inject Beamer: ${error.message}`;
    prodError(error);
  }
}
