











































import _ from 'lodash';
import { FeatureTemplate } from '../../store/es-query/libs/types';
import UITextInput from '../UITextInput.vue';
import Vue from 'vue';
import { storeApi } from '../../store/index';
import Logger from '../../logger/Logger.class';

const { prodError } = new Logger('TheFeaturesSelector');

const TheFeaturesSelector = Vue.extend({
  name: 'TheFeaturesSelector',
  components: {
    UITextInput
  },
  props: {
    parentIdentity: {
      type: String,
      default: null
    },
    addToTop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchKeyword: ''
    };
  },
  computed: {
    myFeatures(): FeatureTemplate[] {
      const features = storeApi.state.es.getters.featureTemplates;

      if (this.searchKeyword) {
        const regex = new RegExp(this.searchKeyword, 'i');
        return _.filter(
          features,
          (feature) =>
            regex.test(feature.label || '') ||
            regex.test(feature.description || '')
        );
      }

      return features;
    }
  },
  mounted() {
    const input = this.$refs['search-input'] as InstanceType<
      typeof UITextInput
    >;
    (input.$el as HTMLInputElement).focus();
  },
  methods: {
    selectFeature(feature: FeatureTemplate) {
      storeApi.state.es.actions
        .addAggs({
          agg: feature,
          parentIdentity: this.parentIdentity,
          addToTop: this.addToTop
        })
        .catch(prodError);

      this.dismiss();
    },
    dismiss() {
      // TODO: find a way to improve on this any
      this.$hideModal();
    }
  }
});

export default TheFeaturesSelector;
