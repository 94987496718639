import { isNil, isArray } from 'lodash';
import stringifiedPath from './stringified-path';
import { Input, QueryEsTemplate, FeatureEsTemplate } from './types';
import stringifiedPathPlaceholder from './stringified-path-placeholder';

const KNOWN_KEYS = [
  'label',
  'type',
  'query_block_key',
  'possible_values',
  'default',
  'prefix',
  'value'
];

/**
 * Cloning the input
 * @param {Input} input
 * @returns {Input}
 */
export const cloneInput = (input: Input) => {
  if (input == null) {
    return input;
  }

  const newInput = {};

  for (let i = 0; i < KNOWN_KEYS.length; i += 1) {
    const key = KNOWN_KEYS[i];
    if (input.hasOwnProperty(key)) {
      newInput[key] = input[key];
    }
  }

  return newInput;
};

/**
 * Gets value of input
 */
export const getInputValue = (inputValue: Input['value']) => {
  if (inputValue == null) {
    return [];
  }

  const cachedValues: (string | number)[] = [];

  const inputs = inputValue instanceof Array ? inputValue : [inputValue];
  for (const currentInput of inputs) {
    if (typeof currentInput !== 'object') {
      cachedValues.push(currentInput);
      continue;
    }

    // We need a value moving forward
    if (currentInput.value == null) {
      continue;
    }

    const values = isArray(currentInput.value)
      ? currentInput.value
      : [currentInput.value];
    for (let c = 0; c < values.length; c += 1) {
      const value = values[c];
      if (typeof value !== 'object') {
        cachedValues.push(value);
      } else {
        cachedValues.push(value.value);
      }
    }
  }

  return cachedValues.filter((v) => v != null);
};

/**
 * Validate input per Gte
 */
export const isGteInput = (input: Input) => {
  if (input.type == null || input.query_block_key == null) {
    return false;
  }

  return (
    /integer|single-select/i.test(input.type) &&
    /min/i.test(input.query_block_key)
  );
};

/**
 * Validate input value per Gte
 */
export const isGteInputValid = (input: Input) =>
  isGteInput(input) && !isNil(input.value);

/**
 * Validate input per Lt
 */
export const isLtInput = (input: Input) => {
  if (input.type == null || input.query_block_key == null) {
    return false;
  }

  return (
    /integer|single-select/i.test(input.type) &&
    /max/i.test(input.query_block_key)
  );
};

/**
 * Validate input value per Lt
 */
export const isLtInputValid = (input: Input) =>
  isLtInput(input) && !isNil(input.value);

/**
 * Validate input per term
 */
export const isTermInput = (input: Input) => {
  if (input.type == null || input.query_block_key == null) {
    return false;
  }

  return (
    /single-select/i.test(input.type) && !/min|max/i.test(input.query_block_key)
  );
};

/**
 * Validate input value per term
 */
export const isTermInputValid = (input: Input) =>
  isTermInput(input) && isArray(input.value) && input.value.length > 0;

/**
 * Validate input per terms
 */
export const isTermsInput = (input: Input) => {
  if (input.type == null) {
    return false;
  }

  return /multi-select/i.test(input.type);
};

/**
 * Validate input value per terms
 */
export const isTermsInputValid = (input: Input) =>
  isTermsInput(input) && isArray(input.value) && input.value.length > 0;

/**
 * Validate input per date
 */
export const isDateInput = (input: Input) => {
  if (input.type == null || input.query_block_key == null) {
    return false;
  }

  return /_date/i.test(input.query_block_key) && /string/i.test(input.type);
};

/**
 * Validate input value per date
 */
export const isDateInputValid = (input: Input | null | undefined) =>
  input != null;

/**
 * Validate input per start date
 */
export const isStartDateInput = (input: Input) => {
  if (input.query_block_key == null) {
    return false;
  }

  return isDateInput(input) && /start_date/i.test(input.query_block_key);
};

/**
 * Validate input value per start date
 */
export const isStartDateInputValid = (input: Input) => input != null;

/**
 * Validate input per end date
 */
export const isEndDateInput = (input: Input) => {
  if (!input.query_block_key) {
    return false;
  }

  return isDateInput(input) && /end_date/i.test(input.query_block_key);
};

/**
 * Validate input value per end date
 */
export const isEndDateInputValid = (input: Input | null | undefined) =>
  input != null;

/**
 * Gets the right validity function for the input
 */
export const getInputValidityFn = (input: Input) => {
  let validityFn = (input: any) => input != null;

  // Try to find the input on the query
  if (isGteInput(input)) {
    validityFn = isGteInputValid;
  } else if (isLtInput(input)) {
    validityFn = isLtInputValid;
  } else if (isTermInput(input)) {
    validityFn = isTermInputValid;
  } else if (isTermsInput(input)) {
    validityFn = isTermsInputValid;
  } else if (isStartDateInput(input)) {
    validityFn = isStartDateInputValid;
  } else if (isEndDateInput(input)) {
    validityFn = isEndDateInputValid;
  }

  return validityFn;
};

/**
 * Gets the value path on the input
 */
export const getInputValuePath = (
  input: Input,
  template: QueryEsTemplate | FeatureEsTemplate
) => {
  let path = '';

  // Try to find the input on the query
  if (isGteInput(input)) {
    path = stringifiedPath(template, 'gte');
  } else if (isLtInput(input)) {
    path = stringifiedPath(template, 'lt');
  } else if (isTermInput(input)) {
    path = stringifiedPathPlaceholder(template, 'term');
  } else if (isTermsInput(input)) {
    path = stringifiedPathPlaceholder(template, 'terms');
  } else if (isStartDateInput(input)) {
    path = stringifiedPath(template, 'gt');
  } else if (isEndDateInput(input)) {
    path = stringifiedPath(template, 'lt');
  }

  return path;
};

/**
 * Gets the value path on the input
 */
export const getInputValueArr = (input: Input) => {
  const valueToCheck = getInputValue(input.value);
  let valueToGo: (string | number)[] | string | number = valueToCheck;

  if (isGteInput(input)) {
    valueToGo = isArray(valueToCheck) ? valueToCheck[0] : valueToCheck;
  } else if (isLtInput(input)) {
    valueToGo = isArray(valueToCheck) ? valueToCheck[0] : valueToCheck;
  } else if (isTermInput(input)) {
    valueToGo = isArray(valueToCheck) ? valueToCheck[0] : valueToCheck;
  } else if (isTermsInput(input)) {
    valueToGo = valueToCheck;
  } else if (isStartDateInput(input)) {
    valueToGo = isArray(valueToCheck) ? valueToCheck[0] : valueToCheck;
  } else if (isEndDateInput(input)) {
    valueToGo = isArray(valueToCheck) ? valueToCheck[0] : valueToCheck;
  }

  return valueToGo;
};
