/* eslint-disable max-lines */
import { ActionContext } from 'vuex';
import axios from 'axios';
import { deepClone } from '../../../../utils/object';
import { convertBucketToLabel } from '../../../es-query/utils/labels';
import {
  getBucketKeys,
  isSameBucketKey
} from '../../../es-query/utils/buckets';
import { getQ } from './service';
import {
  Input,
  Bucket,
  FeatureTemplate,
  FeatureSegment
} from '../../../es-query/libs/types';
import { EsState } from './state';
import EsQuery from '../../../es-query';
import Logger from '../../../../logger/Logger.class';
import { storeApi } from '../../../index';
import { IParsedQueryBlockGroup } from '../../../../API/models/queryUI/queryBlockGroup';
import { IQueryBlock } from '../../../../API/models/queryUI/queryBlock';
import { getDefinedValues } from '../../../../utils/getDefinedValues';
import { tokenify } from '../../../../sdk/request';

const { prodError } = new Logger('es-utils');

/**
 * Gets input from a bucket key
 */
export const getInputsFromBucket = (
  inputs: Input[],
  bucketKey: string | number,
  segments?: FeatureSegment[]
): Input[] => {
  if (bucketKey == null || inputs == null) {
    return inputs;
  }

  return inputs.map((baseInput) => {
    if (baseInput == null) {
      return baseInput;
    }

    const input = { ...baseInput };
    const queryKey = input.query_block_key;
    const segment = (segments == null ? [] : segments).find((s) =>
      isSameBucketKey(s, bucketKey)
    );

    // To be used later on when comparing "min-max" kind of values in case there is no segment
    let keySplit = [bucketKey, bucketKey];
    if (typeof bucketKey === 'string') {
      keySplit = bucketKey.split('-');
    } else if (typeof bucketKey === 'number') {
      keySplit = [bucketKey, bucketKey];
    }

    const isQueryMin = queryKey === 'min' || queryKey === 'from';
    const isQueryMax = queryKey === 'max' || queryKey === 'to';
    let min: any;
    let max: any;

    // Try to find the right min and max
    if (
      segment != null &&
      (segment.hasOwnProperty('min') ||
        segment.hasOwnProperty('from') ||
        segment.hasOwnProperty('max') ||
        segment.hasOwnProperty('to'))
    ) {
      min = segment.hasOwnProperty('min') ? segment.min : segment.from;
      max = segment.hasOwnProperty('max') ? segment.max : segment.to;
    } else {
      // In this case we know it must be a "min-max" kind of input
      [min, max] = keySplit;
    }

    let inputInt: any;

    // Check if there is a min input
    if (min != null && isQueryMin) {
      if (min === '*') {
        // We want to override any default values
        if (input.required) {
          // On these cases we want some kind of value
          inputInt =
            input.default != null && input.default < 0 ? input.default : 0;
        } else {
          // Remove the default and value on these cases
          inputInt = undefined;
          input.default = undefined;
        }
      } else {
        inputInt = min;
      }
    }

    // Check if there is a max input
    if (max != null && max !== '*' && isQueryMax) {
      inputInt = max;
    }

    /* eslint-disable no-case-declarations */
    switch (input.type) {
      case 'integer':
        input.value = inputInt;
        break;
      case 'multi-select':
      case 'single-select':
        if ((min != null || max != null) && (isQueryMax || isQueryMin)) {
          input.value = [{ value: inputInt }];
          break;
        }

        // TODO: no key????? what if there are other inputs??
        input.value = [{ value: bucketKey }];
        break;
      default:
      // Do nothing on this case
    }

    return input;
  });
};

/**
 * Gets queries that came from buckets
 */
export const getBucketizedQueries = (
  query?: IQueryBlock | IParsedQueryBlockGroup | null
): (IQueryBlock | IParsedQueryBlockGroup)[] => {
  let bucketQueries: (IQueryBlock | IParsedQueryBlockGroup)[] = [];

  if (query == null) {
    return bucketQueries;
  }

  // Check if the single query is the one we want
  if ('isFromBucket' in query) {
    if (query.isFromBucket) {
      bucketQueries.push(query);
    }
    return bucketQueries;
  }

  // Iterate each children and flatten out the bucketized
  const queryGroup = query;
  if (queryGroup.children) {
    for (const child of queryGroup.children) {
      bucketQueries = bucketQueries.concat(getBucketizedQueries(child));
    }
  }

  return bucketQueries;
};

/**
 * Gets active bucket keys from query
 */
export const getActiveBuckets = (
  queryId: string
): {
  bucketKeys: string[];
  queryIdentity: string;
  queryBucketKey?: string | number;
}[] => {
  // Check if there is a "bucketized query" for this segment and bucket
  const query = getQ()?.get?.() as IParsedQueryBlockGroup;

  if (!query) {
    return [];
  }

  const bucketQueries = getBucketizedQueries(query).filter(
    (bucketQuery) => 'id' in bucketQuery && bucketQuery.id === queryId
  );

  const activeBuckets: {
    bucketKeys: string[];
    queryIdentity: string;
    queryBucketKey?: string | number;
  }[] = [];

  // Iterate each bucket query to find out which keys should be activated
  for (const bucketQuery of bucketQueries) {
    // We want to sort the buckets by setting the last resorts as last
    if ('inputs' in bucketQuery) {
      bucketQuery.inputs = bucketQuery.inputs || [];

      const bucketKeys = getBucketKeys(bucketQuery.inputs).reverse();

      if (bucketKeys == null || bucketKeys.length === 0) {
        continue;
      }

      if (bucketQuery.identity) {
        activeBuckets.push({
          bucketKeys,
          queryIdentity: bucketQuery.identity,
          queryBucketKey: (bucketQuery as IQueryBlock).bucketKey
        });
      }
    }
  }

  return activeBuckets;
};

/**
 * Sorts buckets
 */
export const sortBuckets = (
  buckets: Bucket[],
  segments: (FeatureSegment | Bucket)[]
): Bucket[] => {
  let sortedAggBuckets: Bucket[] = [];

  if (segments == null || segments.length === 0) {
    return buckets;
  }

  // Iterate the order joining one by one
  for (let c = 0; c < segments.length; c += 1) {
    const segKeys = getBucketKeys([segments[c]]);
    if (segKeys == null || segKeys.length === 0) {
      continue;
    }

    const found = buckets.find(
      (b) => segKeys.find((k) => isSameBucketKey(k, b)) != null
    );
    if (found != null) {
      sortedAggBuckets.push(found);
    }
  }

  // Check if there is any leftovers and join them on the end of the list
  const notFound = buckets.filter((b) => {
    const found = sortedAggBuckets.find((bucketIn) =>
      isSameBucketKey(bucketIn, b)
    );
    return found == null;
  });

  sortedAggBuckets = sortedAggBuckets.concat(notFound);

  return sortedAggBuckets;
};

/**
 * Sets buckets active state
 */
export const setBucketsActiveState = (buckets: Bucket[], queryId: string) => {
  // Check if there is a "bucketized query" for this segment and bucket
  const activeBuckets = getActiveBuckets(queryId);

  if (
    activeBuckets == null ||
    activeBuckets.length === 0 ||
    buckets == null ||
    buckets.length === 0
  ) {
    return buckets;
  }

  for (let i = 0; i < buckets.length; i += 1) {
    const b = buckets[i];

    const foundBucket = activeBuckets.find((activeBucket) => {
      if (activeBucket.queryBucketKey != null) {
        return isSameBucketKey(b, activeBucket.queryBucketKey);
      }

      const foundIn = activeBucket.bucketKeys.find((k) =>
        isSameBucketKey(b, k)
      );
      return foundIn != null;
    });

    if (foundBucket != null) {
      b.queryIdentityActive = foundBucket.queryIdentity;
      break;
    }
  }

  return buckets;
};

/**
 * Gets buckets of an agg

 */
export const getBucketsOfAgg = (
  market: EsState['rawMarket'],
  agg: EsState['aggsUi'][0]
): Bucket[] => {
  // Check if there are some buckets for this aggregation...
  const key = agg?.esAggsBlockKey || '';
  const marketAgg = market?.aggregations?.[key] || [];
  const buckets = marketAgg?.buckets || [];

  // There may exist some buckets that didn't come on the market query
  // Check those on the segments
  const aggTemplate: FeatureTemplate = agg?.template || {};
  const templateSegments = aggTemplate?.segments || [];
  const segments = (agg?.segments || []).concat(templateSegments);

  // Cache the buckets for later use
  const aggBuckets = buckets.map((bucket) => {
    const label = convertBucketToLabel(bucket, segments);

    const possibleKeys = [label.key];
    const possibleLabels = getDefinedValues([label.value, ...possibleKeys]);

    return {
      key: possibleKeys[0],
      count: bucket.doc_count || 0,
      label: possibleLabels[0]
    };
  });

  // Hydrate with template segments...
  // We need to join the display ones if there weren't
  for (const currentSegment of segments) {
    const keys = getBucketKeys([currentSegment]);

    // Check if exists already...
    const found = aggBuckets.find((bucket) => {
      return keys.find((key) => isSameBucketKey(bucket, key));
    });

    if (found) {
      continue;
    }

    const bucket = {
      count: 0,
      key: keys[0],
      label: currentSegment?.label || keys[0]
    };

    // Cache it
    aggBuckets.push(bucket);
  }

  // Sort and set the active state
  const queryId = agg?.queryTemplateId ?? '';

  return setBucketsActiveState(sortBuckets(aggBuckets, segments), queryId);
};

/**
 * Updates aggs ui
 */
export const updateAggsUi = (
  state: EsState,
  newAggregations: EsState['aggsUi']
) => {
  const newTotal =
    state.rawMarket.hits == null ? 0 : state.rawMarket.hits.total;
  const oldAggregations = state.oldAggsUi ?? [];

  return getDefinedValues(
    (newAggregations ?? []).map((aggregation) => {
      const newAggregation = deepClone(aggregation);

      // Check if we have more data to look for the buckets
      const buckets = getBucketsOfAgg(state.rawMarket, newAggregation);
      newAggregation.buckets = buckets ?? [];
      newAggregation.globalTotal = newTotal;

      const hasActive =
        buckets.filter((bucket) => bucket.queryIdentityActive != null).length >
        0;

      if (!hasActive) {
        return newAggregation;
      }

      // Modify the buckets count to be the old one
      let oldAggregration = oldAggregations.find(
        (aggregation) => aggregation.identity === newAggregation.identity
      );
      oldAggregration =
        oldAggregration == null
          ? (({} as any) as typeof newAggregation)
          : oldAggregration;

      const oldBuckets =
        oldAggregration == null || oldAggregration.buckets == null
          ? []
          : oldAggregration.buckets;

      // Cache the global total with the old ones so the
      // Percentages stay the same
      newAggregation.globalTotal =
        oldAggregration.globalTotal == null && oldAggregration.globalTotal !== 0
          ? newAggregation.globalTotal
          : oldAggregration.globalTotal;

      // Set the buckets counts with the old ones
      newAggregation.buckets = buckets.map((newBucket) => {
        const oldBucket = oldBuckets.find((b) => isSameBucketKey(b, newBucket));

        // Change the count with the old one
        if (oldBucket != null) {
          newBucket.count =
            oldBucket.count == null && oldBucket.count !== 0
              ? newBucket.count
              : oldBucket.count;
        }

        return newBucket;
      });

      return newAggregation;
    })
  );
};

/**
 * Requests an update state
 */
const requestUpdateMarket = (
  store: ActionContext<any, any>,
  raw: ReturnType<EsQuery['getRaw']>
) => {
  return new Promise((resolve, reject) => {
    const url = tokenify('/market');

    axios
      .post(url, { json: JSON.stringify(raw) })
      .then((response) => {
        const data = response.data == null ? {} : response.data;
        const promises = [
          store.dispatch('setRawMarket', data),
          store.dispatch(
            'updateHits',
            data.hits == null ? { hits: [] } : data.hits
          )
        ];

        // Fill rates depend on the raw market... make sure we update it
        // The market response will help setting up the values
        return Promise.all(promises)
          .then(() => storeApi.state.actions.updateFillRates())
          .then(() => resolve(data));
      })
      .catch(reject);
  });
};

/**
 * Method to actually update the state
 * @param {Store} store
 * @param {{ force: boolean }} payload
 */
export const updateMarket = (store: ActionContext<any, any>) => {
  const startTime = new Date();
  const nowUtc = `${startTime.getUTCHours()}:${startTime.getUTCMinutes()}:${startTime.getUTCSeconds()}`;
  console.log('[request "/market"][#1] Requesting market -', nowUtc);

  return Promise.resolve().then(() => {
    const raw = getQ().get('raw', {});

    // Save the old raw so we can diff later
    store.commit('setOldRaw', raw);

    console.log(
      '[request "/market"][#2] Setting the backend request -',
      `${new Date().getTime() - startTime.getTime()}ms`
    );

    // Time to actually save the new state
    return store
      .dispatch('setLoading', { value: true })
      .then(() => requestUpdateMarket(store, raw))
      .then((data) => {
        console.log(
          '[request "/market"][#3] Request concluded -',
          `${new Date().getTime() - startTime.getTime()}ms`
        );

        return data;
      })
      .then(() => {
        // DEV: after an update
        return store.dispatch('setLoading', { value: false });
      })
      .catch(prodError);
  });
};
