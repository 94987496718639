import { MutationTree } from 'vuex';
import { rulesetsState } from './state';
import { Ruleset } from '../../../API/models/ruleset/ruleset';
import { PublicUser } from '../../../API/models/user/user';
import { storeApi } from '../../index';
import { getQueryBlock } from '../../es-query/utils/getQueryBlock';
import pick from '../../../utils/pick';
import { getQ } from '../state/es/service';
import { IParsedQueryBlockGroup } from '../../../API/models/queryUI/queryBlockGroup';
import { RulesetResource } from '../../../API/clients/explorer/explorer.client';
import { ModelInterface } from '../../../API/models/interfaceGetters/modelInterface';

const mutations = <T extends MutationTree<typeof rulesetsState>>(
  mutationTree: T
) => mutationTree;

export const rulesetsMutations = mutations({
  /**
   * Set list of rulesets to be used from the state.
   */
  SET_RULESETS(state, payload: Ruleset[]) {
    state.rulesets = payload;
  },
  SET_REPORT_RULESETS(state, payload: Ruleset[]) {
    state.reportRulesets = payload;
  },
  SET_REPORT_RULESETS_OWNERS(state, payload: PublicUser[]) {
    state.reportRulesetsOwners = payload;
  },
  /**
   * Update or replace a ruleset in the state.
   */
  SET_RULESET(
    state,
    payload: {
      ruleset: Ruleset;
      node: IParsedQueryBlockGroup;
    }
  ) {
    if (!payload.node.identity) {
      throw new Error('SET_RULESET payload is missing identity on node');
    }

    const inStore = state.reportRulesets.find(
      (ruleset) => ruleset.id === payload.ruleset.id
    );

    if (inStore) {
      Object.assign(
        state.reportRulesets[state.reportRulesets.indexOf(inStore)],
        payload
      );
    } else {
      state.reportRulesets.push(payload.ruleset);
    }

    const { ruleset } = payload;

    if (ruleset.attributes.deleted_at) {
      return;
    }

    const { queryUi } = storeApi.state.es.state;
    const targetQueryBlock = getQueryBlock(queryUi, payload.node.identity);

    if (!targetQueryBlock) {
      throw new Error('Could not find targetQueryBlock');
    }

    if (targetQueryBlock.type !== 'query-block-group') {
      throw new Error('targetQueryBlock is not a query group');
    }

    targetQueryBlock.template = {
      ...pick(ruleset.attributes, ['description', 'title']),
      rulesetId: payload.ruleset.id,
      userId: ruleset.relationships.user.data.id
    };

    const esManager = getQ();

    esManager.update('query', {
      identity: payload.node.identity,
      config: {
        template: targetQueryBlock.template
      }
    });
  },
  /**
   * Set all owners in the store.
   */
  SET_OWNERS(state, payload: PublicUser[]) {
    state.owners = payload;
  },
  SET_RULESETS_META(
    state,
    payload: ModelInterface<typeof RulesetResource['get']['response']>['meta']
  ) {
    state.ruleSetsMeta = payload || ({} as any);
  },
  /**
   * Add or update a owner
   */
  ADD_OWNER(state, user: PublicUser) {
    const inStore = state.owners.find((item) => item.id === user.id);

    if (inStore) {
      Object.assign(state.owners[state.owners.indexOf(inStore)], user);
    } else {
      state.owners.push(user);
    }
  }
});
