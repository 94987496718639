
















import TheMarketBuilderQueryDebugger from './TheMarketBuilderQueryDebugger.vue';
import Vue from 'vue';

const TheMarketBuilderDebugger = Vue.extend({
  name: 'TheMarketBuilderDebugger',
  components: {
    TheMarketBuilderQueryDebugger
  },
  data() {
    return {
      showDebugger: false
    };
  },
  methods: {
    toggleDebugger() {
      this.showDebugger = !this.showDebugger;
    }
  }
});

export default TheMarketBuilderDebugger;
