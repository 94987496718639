import * as t from 'io-ts';
import pick from '../../../utils/pick';
import { ResourceType } from '../../clients/resourceTypes.enum';

/**
 * Create a schema for a resource that follows the JSON api specification.
 */
export function jsonApiResource<
  T extends t.Any,
  HideID extends boolean = false,
  Rel extends t.Any = any,
  Meta extends t.Any = any,
  RT extends ResourceType = any
>(options: {
  /**
   * Type to give to the resource.
   */
  type: RT;
  /**
   * for POST requests models. Hides the ID of the resource from the Schema.
   */
  hideId?: HideID;
  /**
   * Attributes Schema to give to the resource.
   */
  attributes: T;
  /**
   * Relationships of the resource.
   */
  relationships?: Rel;
  /**
   * Metadata associated to the resource (not the request).
   */
  meta?: Meta;
}) {
  const base = {
    /**
     * Type of the resource
     */
    type: t.literal(options.type),
    /**
     * ID of the resource in number format.
     *
     * newer entries have higher id
     */
    id: t.string,
    /**
     * Attributes of the resource
     */
    attributes: options.attributes
  };

  let result: any = base;

  if (options.hideId) {
    result = pick(base, ['attributes', 'type']);
  }

  type Base = HideID extends true ? Omit<typeof base, 'id'> : typeof base;

  if (options.relationships) {
    result.relationships = options.relationships;
  }

  type CheckRelationships<T extends Base> = T &
    (Rel extends undefined ? Record<string, any> : { relationships: Rel });

  if (options.meta) {
    result.meta = options.meta;
  }

  type CheckMeta<T extends Base> = T &
    (Meta extends undefined ? Record<string, any> : { meta: Meta });

  return result as CheckMeta<CheckRelationships<Base>>;
}
