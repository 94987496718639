import { find, assign, concat, isArray } from 'lodash';
import AggsBlock from './aggs-block';
import {
  AggsStateTree,
  FeatureRawTree,
  FeatureTemplate,
  Label,
  AggsDataUpdateAgg,
  AggsDataUpdateConfig
} from './types';
import TemplatesManager from './templates-manager';

export type AggsBlockTree = {
  agg: AggsBlock;
  children: AggsBlockTree[];
};

export default class AggsBlockManager {
  private aggsCollection: AggsBlockTree[] = [];

  /**
   * Interpolates a collection of raw aggs
   * @param {AggsRawTree[]} aggsRawArray
   * @returns {{ [key:string]: { [key:string]: any } }}
   */
  private interpolateRawAggs(aggsRawArray: FeatureRawTree[] = []) {
    return aggsRawArray.reduce((memo, current) => {
      const k = Object.keys(current);

      k.forEach((kk) => {
        if (kk === 'aggs') {
          memo[kk] = this.interpolateRawAggs(current[kk]);
          /* TODO: Was that condition intended?
          if (isArray) {
            memo[kk] = this.interpolateRawAggs(current[kk]);
          } else {
            memo[kk] = current[kk];
          }
          */
        } else {
          memo[kk] = current[kk];
          if (memo[kk].aggs && isArray(memo[kk].aggs)) {
            memo[kk].aggs = this.interpolateRawAggs(memo[kk].aggs);
          }
        }
      });

      return memo;
    }, {});
  }

  /**
   * Converts a collection of aggs to "state"
   * @param {AggsBlockTree[]} aggsArray
   * @returns {AggsStateTree[]}
   */
  private getConvertedAggsBlocksToState(
    // TODO: Proper type aggsArray: AggsBlockTree[]
    aggsArray: any[] = []
  ): AggsStateTree[] {
    return aggsArray.reduce((memo, current) => {
      if (current.agg.isEmpty()) {
        return memo;
      }

      const aggAllData = current.agg.get() as AggsStateTree;
      const aggData = {
        esAggsBlockKey: aggAllData.esAggsBlockKey,
        templateId: aggAllData.template.id,
        queryInputs: aggAllData.queryInputs,
        segments: aggAllData.segments
      };

      return concat(memo, aggData);
    }, []);
  }

  /**
   * Converts a collection of aggs to "raw"
   * @param {AggsBlockTree[]} aggsArray
   * @returns {FeatureRawTree[]}
   */
  private getConvertedAggsBlocksToRaw(
    // TODO: Proper type aggsArray: AggsBlockTree[]
    aggsArray: any[] = []
  ): FeatureRawTree[] {
    return aggsArray.reduce((memo, current) => {
      if (current.agg.isEmpty()) {
        return memo;
      }

      const result = current.agg.get('raw') as FeatureRawTree;
      return concat(memo, result);
    }, []);
  }

  /**
   * Gets a collection of aggs that has no empties
   * @param {AggsBlockTree[]} aggsArray
   * @returns {AggsBlockTree[]}
   */
  private getNonEmptyAggsBlocks(
    // TODO: Proper type aggsArray: AggsBlockTree[]
    aggsArray: any[] = []
  ): AggsBlockTree[] {
    return aggsArray.reduce((memo, current) => {
      if (current.agg.isEmpty()) {
        return memo;
      }

      return concat(memo, assign({}, current));
    }, []);
  }

  /**
   * Gets the aggs blocks registered as per request type
   * @param {string} type
   * @param {boolean} interpolateRaw
   * @returns {AggsStateTree[]|AggsRawTree[]|AggsBlockTree[]}
   */
  public get(type?: ''): AggsBlockTree[];
  public get(type: 'raw'): FeatureRawTree[];
  public get(type: 'raw', interpolateRaw: true): FeatureRawTree;
  public get(type: 'state'): AggsStateTree[];
  public get(
    type: 'raw' | 'state' | '' = '',
    interpolateRaw = false
  ): FeatureRawTree | AggsStateTree[] | AggsBlockTree[] {
    if (type === 'raw') {
      const rawData = this.getConvertedAggsBlocksToRaw(this.aggsCollection);
      return interpolateRaw ? this.interpolateRawAggs(rawData) : rawData;
    }

    if (type === 'state') {
      return this.getConvertedAggsBlocksToState(this.aggsCollection);
    }

    return this.getNonEmptyAggsBlocks(this.aggsCollection);
  }

  /**
   * Finds a collection by identity
   * @param {string} identity
   * @returns {AggsBlockTree[]}
   */
  // TODO: Leave | undefined type?
  private findCollection(identity = ''): AggsBlockTree | undefined {
    return find(this.aggsCollection, (aggItem: AggsBlockTree) => {
      const props = aggItem.agg.get() as AggsStateTree;
      return props.identity === identity;
    });
  }

  /**
   * Adds a raw to a collection of aggs
   * @param {AggsStateTree[]} aggs
   * @param {{ templates?: TemplatesManager; }} params
   * @returns {AggsBlock[]}
   */
  private addState(
    aggs: AggsStateTree[] = [],
    params: {
      templates?: TemplatesManager;
    } = {}
  ): AggsBlock[] {
    if (
      aggs.length === 0 ||
      params.templates == null ||
      params.templates.get == null
    ) {
      return [];
    }

    const aggsBlocks: AggsBlock[] = [];

    // Iterate each agg on the raw so we can convert
    for (let i = 0; i < aggs.length; i += 1) {
      const aggData = aggs[i];

      let newAgg: AggsBlock;

      const feature = params.templates.get('aggs', {
        templateId: aggData.esAggsBlockKey
      }) as FeatureTemplate | null;
      if (feature != null) {
        // Set a new block and cache it on the parent overall collection
        newAgg = new AggsBlock(feature, aggData);

        this.aggsCollection.push({
          agg: newAgg,
          children: []
        });
        aggsBlocks.push(newAgg);
      }
    }

    return aggsBlocks;
  }

  /**
   * Adds an AggsBlock to a parent collection
   * @param {AggsStateTree[]|FeatureTemplate} aggsData
   * @param {{ [key: string]: any }} params
   * @returns {AggsBlock|AggsBlock[]}
   */
  public add(
    aggsData: AggsStateTree[],
    params: { type: 'state'; templates?: TemplatesManager }
  ): AggsBlock[];
  public add(
    aggsData: FeatureTemplate,
    params?: { addToTop?: boolean }
  ): AggsBlock;
  public add(
    aggsData: AggsStateTree[] | FeatureTemplate,
    params: {
      type?: '' | 'state';
      templates?: TemplatesManager;
      addToTop?: boolean;
    } = {}
  ) {
    if (params.type === 'state') {
      return this.addState(aggsData as AggsStateTree[], params);
    }

    const newAgg = new AggsBlock(aggsData as FeatureTemplate);
    const agg = {
      agg: newAgg,
      children: []
    };

    if (params.addToTop) {
      this.aggsCollection = ([agg] as AggsBlockTree[]).concat(
        this.aggsCollection
      );
    } else {
      this.aggsCollection.push(agg);
    }

    return newAgg;
  }

  /**
   * Updates the AggsBlock collection with new labels
   * @param {Label[]} labels
   * @returns {AggsBlock[]}
   */
  private updateLabels(labels: Label[] = []) {
    if (labels == null || labels.length === 0) {
      return [];
    }

    return this.aggsCollection.map((block) => {
      block.agg.update(labels, { type: 'labels' });

      return block;
    });
  }

  /**
   * Updates the AggsBlock collection with a new order of identities
   * @param {string[]} order
   * @returns {AggsBlock[]}
   */
  private updateOrder(order: string[]) {
    if (order == null || order.length === 0) {
      return;
    }

    const newCollection: AggsBlockTree[] = [];

    // Iterate the order to try and find the right agg
    for (let i = 0; i < order.length; i += 1) {
      const id = order[i];
      const found = this.aggsCollection.find(
        (block) => (block.agg.get() as AggsStateTree).identity === id
      );
      if (found != null) {
        newCollection.push(found);
      }
    }

    this.aggsCollection = newCollection;
  }

  /**
   * Updates an AggsBlock from a parent collection
   * @param {AggsBlockTree|Label[]} aggsData
   * @param {{ parentIdentity?: string; identity?: string; type?: string; }} params
   * @returns {AggsBlock[]}
   */
  public update(aggsData: Label[], params: { type: 'labels' }): AggsBlockTree[];
  public update(
    aggsData: null | undefined | void,
    params: { type: 'order'; order?: string[] }
  ): void | null;
  public update(
    aggsData: {
      agg?: AggsDataUpdateAgg;
      config?: AggsDataUpdateConfig;
    },
    params: {
      parentIdentity?: string;
      identity?: string;
    }
  ): void | null | AggsBlock;
  public update(
    aggsData:
      | Label[]
      | {
          agg?: AggsDataUpdateAgg;
          config?: AggsDataUpdateConfig;
        }
      | null
      | undefined
      | void,
    params: {
      parentIdentity?: string;
      identity?: string;
      order?: string[];
      type?: '' | 'order' | 'labels';
    } = {}
  ) {
    if (params.type === 'labels') {
      return this.updateLabels(aggsData as Label[]);
    }

    if (params.type === 'order') {
      if (params.order == null) {
        return null;
      }

      return this.updateOrder(params.order);
    }

    const identity =
      params.parentIdentity != null && params.parentIdentity.length > 0
        ? params.parentIdentity
        : params.identity;
    if (identity == null || identity.length === 0) {
      return [];
    }

    const toUpdate = this.findCollection(identity);
    if (toUpdate == null || toUpdate.agg == null) {
      return [];
    }

    const aggUpdate = aggsData as {
      agg: AggsDataUpdateAgg;
      config: AggsDataUpdateConfig;
    };

    if (aggUpdate.agg == null) {
      return null;
    }

    return toUpdate.agg.update(aggUpdate.agg, aggUpdate.config);
  }

  /**
   * Removes all the objects that are empty from the collection
   */
  private cleanup() {
    this.aggsCollection = this.aggsCollection.filter(
      (aggObject) => !aggObject.agg.isEmpty()
    );
  }

  /**
   * Destroys an AggsBlock from a parent collection
   * @param {{ [key: string]: any }} params
   */
  public destroy(
    params: {
      parentIdentity?: string;
      identity?: string;
      all?: boolean;
    } = {}
  ) {
    const identity =
      params.parentIdentity != null && params.parentIdentity.length > 0
        ? params.parentIdentity
        : params.identity;

    if (identity != null && identity.length > 0) {
      const aggObject = this.findCollection(identity);

      if (aggObject != null) {
        if (aggObject.agg != null && aggObject.agg.destroy != null) {
          aggObject.agg.destroy();
        }

        this.cleanup();
      }

      return;
    }

    if (params.all) {
      this.aggsCollection = [];
    }
  }
}
