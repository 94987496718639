import * as t from 'io-ts';
import pick from '../../../utils/pick';
import Model from '../../classes/model';
import { ResourceType } from '../../clients/resourceTypes.enum';
import { jsonApiResource } from '../../jsonApi/models/resource';
import { timestampSchema } from '../generic/timestamp';
import { ModelInterface } from '../interfaceGetters/modelInterface';

export const RuleCategoryAttributesSchema = {
  /**
   * Name of the category to show in the front-end
   */
  name: t.string,
  /**
   * Icon that represents the category
   */
  icon_url: t.string,
  /**
   * Descripion of the category to show on hover
   */
  description: t.string,
  /**
   * Art with icon of the category.
   */
  preview_url: t.string,
  ...pick(timestampSchema, ['created_at', 'updated_at'])
};

export const RuleCategoryMetaSchema = {
  matching_rule_entries: t.number,
  total_rule_entries: t.number
};

export const RuleCategorySchema = jsonApiResource({
  type: ResourceType.explorer_rule_category,
  attributes: t.interface(RuleCategoryAttributesSchema),
  meta: t.interface(RuleCategoryMetaSchema)
});

/**
 * A rule category is a simple category to associate to a rule and perform some filtering and grouping.
 *
 * OBS: They do not apply to rule sets.
 */
export const RuleCategoryModel = new Model(t.interface(RuleCategorySchema));

export type RuleCategory = ModelInterface<typeof RuleCategoryModel>;
