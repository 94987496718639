import { request } from '../sdk/request';

export const downloadFile = (url: string, filename: string) => {
  return request({
    url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; application/octet-stream'
    },
    responseType: 'blob'
  }).then((response): any => {
    const contentTypeArr = (response.headers['content-type'] as string).split(
      '/'
    );
    const contentType =
      contentTypeArr.length > 1 ? contentTypeArr[1] : contentTypeArr[0];

    let filenameToBe = `${filename}.${contentType}`;

    const upperHeader = response.headers['X-Intricately-Filename'];
    const lowerHeader = response.headers['x-intricately-filename'];

    if (upperHeader != null && upperHeader.length > 0) {
      filenameToBe = upperHeader;
    } else if (lowerHeader != null && lowerHeader.length > 0) {
      filenameToBe = lowerHeader;
    }

    const blob = new Blob([response.data as any], {
      type: response.headers['content-type'] as string
    });
    const fileURL = window.URL.createObjectURL(blob);
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', filenameToBe);

    document.body.appendChild(fileLink);
    fileLink.click();

    return null;
  });
};
