import { IParsedQueryBlockGroup } from '../../../../API/models/queryUI/queryBlockGroup';
import {
  FeatureTemplate,
  Hit,
  Hits,
  AggsStateTree,
  Bucket
} from '../../../es-query/libs/types';
import { IParsedOldRule } from '../../query-templates/actions';

export type EsState = {
  isLoading: boolean;

  oldRaw: {
    // TODO: need to set this right
    [key: string]: any;
  };
  raw: {
    // TODO: need to set this right
    [key: string]: any;
  };
  rawMarket: {
    // TODO: need to set this right
    [key: string]: any;
  };

  oldAggsUi: (AggsStateTree & {
    buckets?: Bucket[];
    globalTotal?: number;
  })[];
  aggsUi: (AggsStateTree & {
    buckets?: Bucket[];
    globalTotal?: number;
  })[];

  queryTemplates: IParsedOldRule[];
  featureTemplates: FeatureTemplate[];

  queryUi: IParsedQueryBlockGroup;

  previews: Hit[];
  hits: Hits;
};

export const getDefaultState = (): EsState => ({
  isLoading: true,

  oldRaw: {},
  raw: {},
  rawMarket: {},

  // These are generated from the es-query instance
  oldAggsUi: [],
  aggsUi: [],
  queryTemplates: [],
  featureTemplates: [],
  queryUi: {
    type: 'query-block-group'
  },
  previews: [],
  hits: {}
});
