










import Vue from 'vue';

/**
 * The Nav component arranges NavItem's in a vertical stack to be used for navigation in the Rule Selector Modal. Use it to wrap your UIRuleSelectorModalNavItem's so they're arranged properly.
 */
const UIRuleSelectorModalNav = Vue.extend({
  name: 'UIRuleSelectorModalNav'
});

export default UIRuleSelectorModalNav;
