

























































import { FillRate } from '../store/modules/state/state';
import { Hit } from '../store/es-query/libs/types';
import { UITooltip, UIHelpIcon } from '@intricately/ui-lib';
import UIProgressBar from './UIProgressBar.vue';
import Vue from 'vue';
import { storeApi } from '../store/index';
import Logger from '../logger/Logger.class';

const { prodError } = new Logger('UIFillRates');

const UIFillRates = Vue.extend({
  name: 'UIFillRates',
  components: {
    UIProgressBar,
    UITooltip,
    UIHelpIcon
  },
  data() {
    return {
      searchKeyword: ''
    };
  },
  computed: {
    title(): string {
      return 'Fill Rates';
    },
    rates(): FillRate[] {
      const storeRates: FillRate[] = storeApi.state.getters.fillRates;
      let rates = storeRates.map((rate) => {
        return { ...rate };
      });

      if (this.searchKeyword != null && this.searchKeyword.length > 0) {
        const regex = new RegExp(this.searchKeyword, 'i');
        rates = rates.filter(
          (rate) => regex.test(rate.label) || regex.test(rate.id)
        );
      }

      return rates;
    },
    companies(): Hit[] {
      return storeApi.state.es.getters.previews;
    }
  },
  methods: {
    getRateTooltip(rate: FillRate): string {
      return rate == null || rate.description == null ? '' : rate.description;
    },
    toggleStar(rate: FillRate): void {
      rate.starred = !rate.starred;

      const storeRates = storeApi.state.getters.fillRates
        .map((storeRate) => {
          if (rate.id === storeRate.id) {
            storeRate.starred = rate.starred;
          }

          return storeRate;
        })
        .sort((a) => (!a.starred ? 1 : -1));

      // Save the changes
      storeApi.state.actions
        .updateFillRates(storeRates)
        .then(() => storeApi.state.actions.queryState({}))
        .catch(prodError);
    }
  }
});

export default UIFillRates;
