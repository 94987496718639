import _ from 'lodash';
import { AppSettingsState } from './state';

const valueById = (settings: any[], id: string, field: string) => {
  const object = _.find(settings, { id });
  return _.get(object, field, '');
};

export const title = (state: AppSettingsState) =>
  valueById(state.settings, 'title', 'value');

export const marketBuilderTitle = (state: AppSettingsState) =>
  valueById(state.settings, 'builder.title', 'value');

export const marketBuilderTooltip = (state: AppSettingsState) =>
  valueById(state.settings, 'builder.title', 'tooltip');

export const marketSegmenterTitle = (state: AppSettingsState) =>
  valueById(state.settings, 'segmenter.title', 'value');

export const marketSegmenterTooltip = (state: AppSettingsState) =>
  valueById(state.settings, 'segmenter.title', 'tooltip');

export const segmentSelectorTitle = (state: AppSettingsState) =>
  valueById(state.settings, 'segment-selector.title', 'value');

export const segmentSelectorTooltip = (state: AppSettingsState) =>
  valueById(state.settings, 'segment-selector.title', 'tooltip');

export const marketPreviewTitle = (state: AppSettingsState) =>
  valueById(state.settings, 'market-preview.title', 'value');

export const marketPreviewTooltip = (state: AppSettingsState) =>
  valueById(state.settings, 'market-preview.title', 'tooltip');

export const loading = (state: AppSettingsState) => state.loading;

export const settingsAreReady = (state: AppSettingsState) =>
  state.settingsAreReady;

export const authUserId = (state: AppSettingsState) => state.authUserId;
export const authUser = (state: AppSettingsState) => state.authUser;
