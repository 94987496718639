import _ from 'lodash';
import { FeatureSegment, Input } from '../libs/types';
import { convertAggSegmentToLabel } from './labels';
import { ensureArray } from '../../../utils/ensureArray';
import { getDefinedValues } from '../../../utils/getDefinedValues';
import { Enum } from '../../../utils/enum';

/**
 * Gets possible keys for a data
 */
const getPossibleKeys = (data: any): any[] => {
  const dataArr = Array.isArray(data) ? data : [data];
  const keys: any[] = [];

  for (let i = 0; i < dataArr.length; i += 1) {
    const single = dataArr[i];
    if (single == null) {
      continue;
    }

    if (typeof single === 'object') {
      if (single.key != null) {
        keys.push(single.key);
        keys.push(`${single.key}`);
      }

      if (single.bucket_key != null) {
        keys.push(single.bucket_key);
        keys.push(`${single.bucket_key}`);
      }
    } else {
      keys.push(single);
      keys.push(`${single}`);
    }
  }

  return keys.filter((v) => v != null);
};

/**
 * Checks if it is the same bucket key
 */
export const isSameBucketKey = (dataA: any, dataB: any): boolean => {
  if (dataA == null || dataB == null) {
    return false;
  }

  const keysA: any[] = getPossibleKeys(dataA);
  const keysB: any[] = getPossibleKeys(dataB);

  for (let i = 0; i < keysA.length; i += 1) {
    const singleA = keysA[i];

    for (let c = 0; c < keysB.length; c += 1) {
      const singleB = keysB[c];

      if (singleA === singleB) {
        return true;
      }
    }
  }

  return false;
};

/**
 * Retrieves buckets keys from inputs
 */
export const getBucketKeys = (data: (FeatureSegment | Input)[]): string[] => {
  // These will be organized by last resort first
  let bucketKeys: string[] = [];

  if (!data) {
    return bucketKeys;
  }

  // For later usage...
  let checkMinMaxFromTo = false;
  const minMaxFromToKey: Pick<FeatureSegment, 'min' | 'max'> = {};

  // Iterate each input
  for (const currentInput of data) {
    if (!currentInput) {
      continue;
    }

    const rangeKeys = {
      start: new Enum(['min', 'from']),
      end: new Enum(['max', 'to'])
    };

    if ('min' in currentInput || 'max' in currentInput) {
      // We might have data coming in differently
      minMaxFromToKey.min =
        currentInput.min || currentInput.from || minMaxFromToKey.min;
      minMaxFromToKey.max =
        currentInput.max || currentInput.to || minMaxFromToKey.max;
      if (minMaxFromToKey.min || minMaxFromToKey.max) {
        checkMinMaxFromTo = true;
      }
    }

    //TODO: Investigate this key.
    if ((currentInput as any).key) {
      bucketKeys.push((currentInput as any).key);
    }

    if (currentInput.bucket_key) {
      bucketKeys.push(currentInput.bucket_key);
    }

    if ('query_block_key' in currentInput) {
      const queryKey = currentInput.query_block_key;
      /* eslint-disable no-case-declarations, no-loop-func */
      if (
        currentInput.type === 'integer' &&
        typeof currentInput.value !== 'object'
      ) {
        if (rangeKeys.start.has(currentInput.type)) {
          checkMinMaxFromTo = true;
          minMaxFromToKey.min = currentInput.value;
        }

        if (rangeKeys.end.has(currentInput.type)) {
          checkMinMaxFromTo = true;
          minMaxFromToKey.max = currentInput.value;
        }
      } else if (typeof currentInput.value === 'object') {
        const values = getDefinedValues(
          ensureArray(currentInput.value).map(
            (inputValue) =>
              (typeof inputValue === 'object' && inputValue !== null
                ? inputValue.value
                : inputValue) + ''
          )
        );

        bucketKeys = bucketKeys.concat(values);
        if (rangeKeys.start.has(queryKey + '')) {
          checkMinMaxFromTo = true;
          [minMaxFromToKey.min] = values;
        }

        if (rangeKeys.end.has(queryKey + '')) {
          checkMinMaxFromTo = true;
          [minMaxFromToKey.max] = values;
        }
      }
    }
  }

  // Check if we have min-max or from-to
  if (checkMinMaxFromTo) {
    const key1 = convertAggSegmentToLabel(minMaxFromToKey).key;
    const key2 = convertAggSegmentToLabel(minMaxFromToKey, [], true).key;

    if (key1 != null) {
      bucketKeys.push(key1);
    }
    if (key2 != null && key1 !== key2) {
      bucketKeys.push(key2);
    }
  }

  // The 0 at the beginning would mean that could be
  // A minimum not present so * is also possible
  for (let i = 0; i < bucketKeys.length; i += 1) {
    if (bucketKeys[i] == null) {
      continue;
    }

    const key = `${bucketKeys[i]}`;

    if (key.indexOf('0-') === 0) {
      bucketKeys.push(key.replace('0-', '*-'));
    }

    if (key.indexOf('0.0-') === 0) {
      bucketKeys.push(key.replace('0.0-', '*-'));
    }
  }

  return bucketKeys
    .filter((bucketKey) => {
      if (
        bucketKey == null ||
        (typeof bucketKey === 'string' && bucketKey.length === 0)
      ) {
        return false;
      }

      return true;
    })
    .map((k) => `${k}`);
};
