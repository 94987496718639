




















import Vue from 'vue';

export default Vue.extend({
  props: {
    type: {
      type: String,
      default: 'a'
    },
    hasBorder: {
      type: Boolean,
      default: false
    },
    borderWidth: {
      type: String,
      default: '1'
    },
    borderColor: {
      type: String,
      default: 'transparent'
    },
    color: {
      type: String,
      default: '#32cd32'
    },
    value: {
      type: Number,
      default: 0
    },
    setLabel: {
      type: Boolean,
      default: true
    },
    isDecimals: {
      type: Boolean,
      default: true
    },
    maxValue: {
      type: Number,
      default: 100
    }
  },
  computed: {
    parsedValue(): number {
      // eslint-disable-next-line no-restricted-globals
      if (this.value == null || isNaN(this.value)) {
        return 0;
      }

      const v = this.isDecimals
        ? Math.round(this.value * 10) / 10
        : Math.round(this.value);
      // eslint-disable-next-line no-restricted-globals
      return isNaN(v) ? 0 : v;
    },
    parsedWidthValue(): number {
      // eslint-disable-next-line no-restricted-globals
      if (this.value == null || isNaN(this.value) || this.maxValue == null) {
        return 0;
      }

      const rawValue = (this.value * 100) / this.maxValue;
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(rawValue)) {
        return 0;
      }

      const v = Math.round(rawValue);
      return rawValue > 0 && rawValue < 1 ? 1 : v;
    }
  }
});
