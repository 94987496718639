






import Vue from 'vue';
import { storeApi } from '../store/index';
import Logger from '../logger/Logger.class';

const { prodError } = new Logger('TheMarketExplorerPage');

const TheMarketExplorerPage = Vue.extend({
  async created() {
    const stateId = storeApi.state.getters.id;

    if (stateId != null && stateId.length > 0) {
      await this.changeState(stateId).catch(prodError);
      return;
    }

    // Being at this stage means we have no id and we need to request one
    // Lets set the loading right away so that the user can't interact
    await this.createState().catch(prodError);
  },
  methods: {
    /**
     * Adds default features
     */
    addDefaultFeatures(): Promise<any> {
      const defaultFeatures = storeApi.state.es.getters.featureTemplates
        .filter((feature) => feature.include_feature_in_segmenter_by_default)
        .sort((featureA, featureB) => {
          if (
            typeof featureA.display_order === 'number' &&
            typeof featureB.display_order === 'number'
          ) {
            return featureA.display_order > featureB.display_order ? 1 : -1;
          }

          return -1;
        });

      // DEV: we don't want to update because we still have to add the query defaults
      return storeApi.state.es.actions.addAggs({
        aggs: defaultFeatures,
        dontUpdate: true
      });
    },

    /**
     * Adds default queries
     */
    addDefaultQueries(): Promise<any> {
      const defaultQueries = storeApi.state.es.getters.queryTemplates
        .filter((query) => query.include_feature_in_builder_by_default)
        .sort((queryA, queryB) => {
          return queryA.display_order! > queryB.display_order! ? 1 : -1;
        });

      // Since order matters we want to keep it in sync
      let p = Promise.resolve();

      for (let i = 0; i < defaultQueries.length; i += 1) {
        const query = defaultQueries[i];

        p = p.then(() =>
          storeApi.state.es.actions.addQuery({
            query
          })
        );
      }

      return p;
    },

    /**
     * Changes to "View"
     */
    goToView(stateId: string): void {
      if (stateId != null && stateId.length > 0) {
        this.$router
          .push({
            name: 'report',
            params: { id: stateId, dontFetch: true } as { [key: string]: any }
          })
          .catch(prodError);
      }
    },

    /**
     * Creates a state
     */
    async createState(): Promise<any> {
      // TODO: find a way to do this more safely without the any

      // Being at this stage means we have no id and we need to request one
      // Lets set the loading right away so that the user can't interact
      await storeApi.state.actions.setLoading({ value: true });
      await storeApi.state.actions.reset();
      await this.addDefaultFeatures();
      await this.addDefaultQueries();
      await storeApi.state.es.actions.updateEsState();
      await storeApi.state.es.actions.saveState({ dontSaveNextTime: 3000 });
      const newStateId = await storeApi.state.getters.id;
      await this.goToView(newStateId);
      await storeApi.state.actions.setLoading({ value: false });
    },

    /**
     * Change to a state id
     */
    async changeState(stateId: string): Promise<any> {
      await storeApi.state.actions.updateId(stateId);
      await storeApi.state.actions.fetchState();
      await this.goToView(stateId);
    }
  }
});

export default TheMarketExplorerPage;
