import * as t from 'io-ts';
import { timestampSchema } from '../generic/timestamp';
import pick from '../../../utils/pick';
import { jsonApiResource } from '../../jsonApi/models/resource';
import { ResourceType } from '../../clients/resourceTypes.enum';
import Model from '../../classes/model';
import { ModelInterface } from '../interfaceGetters/modelInterface';

/**
 * Public attributes of an user that any other user can get.
 */
export const publicUserAttributesSchema = {
  email: t.string,
  first_name: t.string,
  last_name: t.string,
  name: t.string,
  ...pick(timestampSchema, ['created_at', 'updated_at'])
};

/**
 * User resource from a JSON API with public values
 */
export const publicJsonApiUserModel = new Model(
  t.interface(
    jsonApiResource({
      attributes: t.interface(publicUserAttributesSchema),
      type: ResourceType.user
    })
  )
);

export type PublicUser = ModelInterface<typeof publicJsonApiUserModel>;
