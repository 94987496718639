
















































































































































import Vue from 'vue';
import { ReportsState } from '../../store/modules/reports/state';
import UITextInput from '../UITextInput.vue';
import UIRadio from '../UIRadio.vue';
import UISelectDropdown from '../UISelectDropdown.vue';
import formatNumber from '../../filters/format-number';
import { storeApi } from '../../store/index';
import Logger from '../../logger/Logger.class';

const { prodError } = new Logger('Model');

type ExplorerReportTargetTypes = 'csv' | 'xlsx';
interface IExplorerReportTarget {
  id: ExplorerReportTargetTypes;
  label: string;
}

export default Vue.extend({
  components: {
    UIRadio,
    UISelectDropdown,
    UITextInput
  },
  data() {
    return {
      reportType: 'report' as 'report' | 'enrichment',
      reportName: '',
      selectedReportTarget: 'xlsx',
      selectedReportFieldId: '',

      enrichmentSelection: null as null | string | undefined,
      selectedEnrichmentTarget: 'xlsx',
      selectedEnrichmentFieldId: '',

      reportTargets: [
        { id: 'csv', label: 'CSV' },
        { id: 'xlsx', label: 'Excel' }
      ] as Array<IExplorerReportTarget>,

      hasSubmitted: false
    };
  },
  computed: {
    totalCompanies(): number {
      return storeApi.state.es.getters.hits.total || 0;
    },
    reportNameErr(): string {
      if (this.reportErr.length > 0) {
        return '';
      }

      if (this.reportName == null || this.reportName.length === 0) {
        return 'Please name your report';
      }

      return '';
    },
    reportErr(): string {
      if (this.totalCompanies > 500000) {
        return 'Reports must have fewer than 500,000 records';
      }

      return '';
    },
    enrichmentOptions(): { value: string; label: string }[] {
      const enrichments: ReportsState['userEnrichments'] =
        storeApi.reports.getters.userEnrichments;

      return enrichments.map((e) => {
        let label = e.attributes.name as string;
        if (e.attributes.row_count != null) {
          const count = e.attributes.row_count;
          const countLabel =
            count === 1 ? '1 record' : `${formatNumber(count)} records`;

          label = `${label} (${countLabel})`;
        }

        return {
          label,
          value: `${e.id == null ? '' : e.id}`,
          checked: this.enrichmentSelection === `${e.id == null ? '' : e.id}`
        };
      });
    },
    reportFieldSetOptions(): { value: string; label: string }[] {
      const sets: ReportsState['reportFieldSets'] =
        storeApi.reports.getters.reportFieldSets;

      return sets.map((s) => {
        const value = `${s.id == null ? '' : s.id}`;
        const isChecked = `${this.selectedReportFieldId}` === value;

        return { value, label: s.name, checked: isChecked };
      });
    },
    enrichmentFieldSetOptions(): { value: string; label: string }[] {
      const sets: ReportsState['reportFieldSets'] =
        storeApi.reports.getters.reportFieldSets;

      return sets.map((s) => {
        const value = `${s.id == null ? '' : s.id}`;
        const isChecked = `${this.selectedEnrichmentFieldId}` === value;

        return { value, label: s.name, checked: isChecked };
      });
    },
    hasTooManyRecords(): boolean {
      return this.reportType === 'report' && this.totalCompanies > 500000;
    },
    totalRecords(): string {
      const total = this.totalCompanies;

      if (total > 0) {
        return `${formatNumber(total)}`;
      }

      return `${total}`;
    }
  },
  mounted() {
    this.loadEnrichments();
    this.loadReportFieldsSet();
  },
  methods: {
    defaultReportFieldSets(): void {
      const reportSets = this.reportFieldSetOptions;
      if (
        this.selectedReportFieldId == null ||
        this.selectedReportFieldId === ''
      ) {
        this.selectedReportFieldId = `${reportSets[0].value}`;
      }

      const enrSets = this.enrichmentFieldSetOptions;
      if (
        this.selectedEnrichmentFieldId == null ||
        this.selectedEnrichmentFieldId === ''
      ) {
        this.selectedEnrichmentFieldId = `${enrSets[0].value}`;
      }
    },
    onReportFieldSetSelect(
      data: { value: string; label: string; checked: boolean }[]
    ): void {
      if (data == null || data[0] == null) {
        this.selectedReportFieldId = '';
        return;
      }

      this.selectedReportFieldId = data[0].value;
    },
    onEnrichmentFieldSetSelect(
      data: { value: string; label: string; checked: boolean }[]
    ): void {
      if (data == null || data[0] == null) {
        this.selectedEnrichmentFieldId = '';
        return;
      }

      this.selectedEnrichmentFieldId = data[0].value;
    },
    onEnrichmentSelect(
      data: { value: string; label: string; checked: boolean }[]
    ): void {
      if (data == null || data[0] == null) {
        this.enrichmentSelection = null;
        return;
      }

      this.enrichmentSelection = data[0].value;
    },
    onRequestReport(isEnrichment = false): void {
      if (
        !isEnrichment &&
        (this.reportName == null || this.reportName === '')
      ) {
        return;
      }

      if (isEnrichment && this.enrichmentSelection == null) {
        return;
      }

      const reqObj = isEnrichment
        ? {
            stateId: storeApi.state.getters.id,
            fieldId: this.selectedEnrichmentFieldId,
            enrichmentId: this.enrichmentSelection,
            format: this.selectedReportTarget
          }
        : {
            stateId: storeApi.state.getters.id,
            name: this.reportName,
            fieldId: this.selectedReportFieldId,
            format: this.selectedReportTarget
          };

      if (this.hasTooManyRecords) {
        this.$toast(
          'To download a market segment, it must have under 500k records',
          'error'
        );
        return;
      }

      storeApi.reports.actions
        .createReport(reqObj)
        .then(() => {
          this.hasSubmitted = true;
          this.$toast(
            "Great! We'll notify you when the report is ready.",
            'success',
            { timeout: 3000 }
          );

          this.reportName = '';
          this.enrichmentSelection = null;

          return '';
        })
        .catch(() => {
          this.$toast(
            'There was an error when requesting your report',
            'error'
          );

          return '';
        });
    },
    loadReportFieldsSet(): void {
      // Don't refetch if we already have data
      if (
        this.reportFieldSetOptions != null &&
        this.reportFieldSetOptions.length > 0
      ) {
        this.defaultReportFieldSets();
        return;
      }
      storeApi.reports.actions
        .fetchReportFieldSets()
        .then((data) => {
          this.defaultReportFieldSets();
          return data;
        })
        .catch((err) => {
          this.$toast(
            'There was an error when requesting your report',
            'error'
          );

          return err;
        });
    },
    loadEnrichments(): void {
      // Don't refetch if we already have data
      if (this.enrichmentOptions != null && this.enrichmentOptions.length > 0) {
        return;
      }

      storeApi.reports.actions.fetchEnrichments().catch(prodError);
    }
  }
});
