// Taken from here https://jsfiddle.net/Linusborg/Lx49LaL8/

export default {
  // TODO: needs typing
  bind(el: any, binding: any, vNode: any) {
    if (typeof binding.value !== 'function') {
      const compName = vNode.context.name;
      let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
      if (compName) {
        warn += `Found in component '${compName}'`;
      }
      // Disable eslint for console warn because in this context
      // The warning is needed
      // eslint-disable-next-line
      console.warn(warn);
    }
    const { bubble } = binding.modifiers;
    const handler = (e) => {
      if ((bubble || !el.contains(e.target)) && el !== e.target) {
        binding.value(e);
      }
    };
    el.myCustomVueClickOutside = handler;

    document.addEventListener('click', handler);
  },
  // TODO: needs typing
  unbind(el: any) {
    document.removeEventListener('click', el.myCustomVueClickOutside);
    el.myCustomVueClickOutside = null;
  }
};
