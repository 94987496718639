















import Vue from 'vue';

const TheLoginPage = Vue.extend({
  name: 'TheLoginPage'
});

export default TheLoginPage;
