import { ActionContext } from 'vuex';
import { getUsersList, getUser } from '../../../sdk/modules/users';
import { IUser } from './state';

/**
 * Fetches users list from team
 * @param {Store} store
 * @param {{teamId: string}} params
 */
export const fetchUsersList = async (store: ActionContext<any, any>) => {
  const data = await getUsersList();
  store.commit(
    'setUsersList',
    data.data.map((user) => {
      return {
        id: user.id,
        name: user.attributes.name,
        email: user.attributes.email,
        created_at: user.attributes.created_at
      } as IUser;
    })
  );

  return data.data;
};

/**
 * Fetches user
 * @param {Store} store
 * @param {{userId: string}} params
 */
export const fetchUser = async (
  store: ActionContext<any, any>,
  params: { userId: string }
) => {
  const data = await getUser(params);
  store.commit('addUser', data.user);
  return data.user;
};
