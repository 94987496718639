import { GetterTree } from 'vuex';
import { rulesetsState } from './state';
import { parseResource } from '../../../API/jsonApi/parsers/parseResource';
import { Ruleset } from '../../../API/models/ruleset/ruleset';

const getters = <
  T extends GetterTree<typeof rulesetsState, Record<string, any>>
>(
  tree: T
) => tree;

export const rulesetsGetters = getters({
  /**
   * Flattened rule sets with included relationships.
   */
  rulesets(state) {
    return state.rulesets.map((ruleset) => {
      const parsedRuleset = parseResource(ruleset, { user: state.owners });

      if (!parsedRuleset.user) {
        throw new Error('Rule set has no user');
      }

      return {
        ...parsedRuleset,
        user: parseResource(parsedRuleset.user)
      };
    });
  },
  /**
   * Convert a ruleset to a flattened rule set with mapped relationships.
   */
  flattenedRuleset: (state) => (ruleset: Ruleset) => {
    return parseResource(ruleset, {
      user: [...state.owners, ...state.reportRulesetsOwners]
    });
  },
  /**
   * Flattened rule sets present in the report with included relationships.
   */
  reportRulesets(state) {
    return state.reportRulesets.map((ruleset) => {
      const parsedRuleset = parseResource(ruleset, {
        user: state.reportRulesetsOwners
      });

      parsedRuleset.user.id;

      return {
        ...parsedRuleset,
        user: parseResource(parsedRuleset.user)
      };
    });
  },
  /**
   * Gets an already fetched ruleset by id
   */
  rulesetById: (state) => (id: string) => {
    return [...state.rulesets, ...state.reportRulesets].find(
      (ruleset) => ruleset.id === id
    );
  }
});
