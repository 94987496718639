





















































































import Vue, { PropOptions } from 'vue';
import _ from 'lodash';
import { deepClone } from '../../utils/object';
import UITextInput from '../UITextInput.vue';
import UINumericTextInput from '../UINumericTextInput.vue';
import UISelectDropdownAsync from '../UISelectDropdownAsync.vue';
import { State } from '../../store/modules/state/state';
import {
  FeatureTemplate,
  FeatureSegment
} from '../../store/es-query/libs/types';
import { storeApi } from '../../store/index';

const TheFeatureRangesManager = Vue.extend({
  name: 'TheFeatureRangesManager',
  components: {
    UITextInput,
    UINumericTextInput,
    UISelectDropdownAsync
  },
  props: {
    feature: {
      type: Object,
      default: () => ({})
    } as PropOptions<Partial<FeatureTemplate>>,
    ranges: {
      type: Array,
      default: () => []
    } as PropOptions<Range[]>,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      debounceUpdateRangesTimer: null as any,
      debounceUpdateListTimer: null as any,

      // DEV: two lines of "MOMOMOMO" should be 16 but "Medium ($10k-$100k)" is over
      maxTextLength: 20,
      maxNumLength: 9,
      originalRanges: [] as Partial<FeatureSegment>[],
      myFeature: {} as Partial<FeatureTemplate>,
      myRanges: [] as Partial<FeatureSegment>[],
      listValues: [] as any[]
    };
  },
  computed: {
    lastQueryOrAggAddedId(): State['lastQueryOrAggAddedId'] {
      return storeApi.state.getters.lastQueryOrAggAddedId;
    },
    isAutosuggest(): boolean {
      return (
        this.myFeature.queryInputs[0].possible_values.type === 'autosuggest'
      );
    }
  },

  watch: {
    listValues() {
      this.debouncedUpdateList();
    },

    feature: {
      deep: true,
      handler(val) {
        const newFeature = deepClone(val);
        this.myFeature = newFeature;
      }
    },

    ranges: {
      deep: true,
      handler(val) {
        const newRanges = deepClone(val == null ? [] : val);
        this.originalRanges = newRanges;
      }
    }
  },
  created() {
    // Handle feature
    this.myFeature = deepClone(this.feature);

    // Handle ranges
    const rangesRaw = (this.ranges == null ? [] : this.ranges) as any[];
    const newRanges = deepClone(rangesRaw);
    this.originalRanges = newRanges;

    let ranges: Partial<FeatureSegment>[] = [
      { from: undefined, to: undefined }
    ];
    if (rangesRaw.length) {
      ranges = this.originalRanges;
    }

    this.myRanges = ranges;
  },
  methods: {
    /**
     * Method to be used with async dropdowns
     * @param {string} keyword
     */
    async fetchDropdownKeyword(keyword: string) {
      const asyncEndpoint = this.myFeature.queryInputs[0].possible_values
        .api_endpoint;
      const value = storeApi.marketInputs.getters.cachedList(asyncEndpoint);

      if (!value) {
        return undefined;
      }

      return value.filter((v) => v.label.toLowerCase().indexOf(keyword) > -1);
    },

    /**
     * Method to be used with async autosuggest dropdowns
     * @param {string} keyword
     */
    async fetchDropdownKeywordAutosuggest(keyword: string) {
      const asyncEndpoint = this.myFeature.queryInputs[0].possible_values
        .api_endpoint;

      return storeApi.marketInputs.actions.fetchAutosuggestList({
        endpoint: asyncEndpoint,
        prefix: keyword
      });
    },

    /**
     * Handles the change to the multiple select values
     */
    onDropdownSelect(newValues: any[]) {
      this.listValues = newValues;
    },

    addRange() {
      if (!this.disabled) {
        this.myRanges = _.concat(this.myRanges, {
          min: undefined,
          max: undefined,
          label: ''
        });
        this.debouncedUpdateRanges();
      }
    },
    removeRange(index: number) {
      if (!this.disabled) {
        this.myRanges = _.filter(
          this.myRanges,
          (range, myIndex) => myIndex !== index
        );

        // Make sure we always have one range at least
        if (this.myRanges.length === 0) {
          this.addRange();
        }

        this.debouncedUpdateRanges();
      }
    },
    updateRanges(ranges: any[]) {
      this.$emit('update', ranges);
    },
    debouncedUpdateRanges() {
      if (this.debounceUpdateRangesTimer != null) {
        clearTimeout(this.debounceUpdateRangesTimer);
      }

      this.debounceUpdateRangesTimer = setTimeout(() => {
        this.debounceUpdateRangesTimer = null;
        this.updateRanges(this.myRanges);
      }, 200);
    },
    debouncedUpdateList() {
      if (this.debounceUpdateListTimer != null) {
        clearTimeout(this.debounceUpdateListTimer);
      }

      this.debounceUpdateListTimer = setTimeout(() => {
        this.debounceUpdateListTimer = null;
        this.updateRanges(this.listValues);
      }, 200);
    }
  }
});

export default TheFeatureRangesManager;
