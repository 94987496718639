import es from './es';
import * as mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';
import { getDefaultState } from './state';

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  actions,
  getters,

  modules: {
    es
  }
};
