import { FeaturesTemplatesState } from './state';

/**
 * Sets the templates on the store
 * @param {FeaturesTemplatesState} state
 * @param {FeatureTemplate[]} payload
 */
export const setFeatures = (
  state: FeaturesTemplatesState,
  payload: typeof state['templates']
) => {
  state.templates = payload;
};

/**
 * Sets the templates as ready
 * @param {FeaturesTemplatesState} state
 */
export const featuresAreReady = (state: FeaturesTemplatesState) => {
  state.templatesAreReady = true;
};

/**
 * Sets the templates as not ready
 * @param {FeaturesTemplatesState} state
 */
export const featuresAreNotReady = (state: FeaturesTemplatesState) => {
  state.templatesAreReady = false;
};
