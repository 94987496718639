




























import Vue from 'vue';

export default Vue.extend({
  props: {
    title: {
      type: String,
      default: ''
    },
    fullBody: {
      type: Boolean,
      default: false
    },
    centerTitle: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    (this.$refs['closeModal'] as HTMLElement).focus();
  },
  methods: {
    dismiss() {
      this.$emit('dismiss');
      // TODO: $modal module declaration
      this.$hideModal();
    }
  }
});
