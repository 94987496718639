import { Module } from 'vuex';
import { rulesetsState } from './state';
import { rulesetsMutations } from './mutations';
import { rulesetsActions } from './actions';
import { rulesetsGetters } from './getters';

const storeModule = <
  T extends Module<typeof rulesetsState, Record<string, any>>
>(
  options: T
) => options;

export const rulesetModule = storeModule({
  namespaced: true,
  state: rulesetsState,
  mutations: rulesetsMutations,
  actions: rulesetsActions,
  getters: rulesetsGetters
});
