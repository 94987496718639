
























































import { deepClone } from '../../utils/object';
import { State } from '../../store/modules/state/state';
import { EsState } from '../../store/modules/state/es/state';
import Vue from 'vue';
import { storeApi } from '../../store/index';
import Logger from '../../logger/Logger.class';

const showTypes = ['state', 'market-query', 'company-query'] as const;

const { prodError } = new Logger('TheMarketBuilderQueryDebugger');

const TheMarketBuilderQueryDebugger = Vue.extend({
  data() {
    return {
      showState: true,
      showMarketQuery: false,
      showCompanyQuery: false,
      showMarketQueryResult: false
    };
  },
  computed: {
    isProduction(): boolean {
      return storeApi.state.getters.isProduction;
    },
    isReqProduction(): boolean {
      return storeApi.state.getters.isReqProduction;
    },
    state(): State['oldState'] {
      return storeApi.state.getters.oldState;
    },
    marketQuery(): EsState['raw'] {
      return storeApi.state.es.getters.raw;
    },
    companyQuery(): string {
      const raw = deepClone(this.marketQuery);
      delete raw.aggs;

      return JSON.stringify(raw, null, 2);
    },
    marketQueryResult(): string {
      const result = storeApi.state.es.getters.rawMarket;
      return JSON.stringify(result, null, 2);
    }
  },
  methods: {
    show(type: typeof showTypes[number]) {
      this.showState = type === 'state';
      this.showMarketQuery = type === 'market-query';
      this.showCompanyQuery = type === 'company-query';
      this.showMarketQueryResult = !showTypes.includes(type);
    },
    toggleReqProduction(type: string) {
      let value = !this.isReqProduction;

      if (type != null) {
        if (type === 'staging') {
          value = false;
        } else if (type === 'production') {
          value = true;
        }
      }

      storeApi.state.actions.setIsReqProduction({ value }).catch(prodError);
    }
  }
});

export default TheMarketBuilderQueryDebugger;
