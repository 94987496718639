import { UsersState, IUser } from './state';

export const setUsersList = (
  state: UsersState,
  payload: typeof state['usersList']
) => {
  state.usersList = payload;
};

export const addUser = (state: UsersState, payload: IUser) => {
  state.usersList = (state.usersList == null ? [] : state.usersList).filter(
    (u) => u.id !== payload.id
  );
};
