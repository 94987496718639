import * as t from 'io-ts';
import pick from '../../../utils/pick';
import Model from '../../classes/model';
import { ResourceType } from '../../clients/resourceTypes.enum';
import { jsonApiResource } from '../../jsonApi/models/resource';
import { timestampSchema } from '../generic/timestamp';
import { queryUiInputModel } from '../queryUI/queryInput';
import { ModelInterface } from '../interfaceGetters/modelInterface';
import { requiredJsonApiRelationshipSchema } from '../../jsonApi/models/relationship';

export const RuleAttributes = {
  /**
   * Name of the rule.
   */
  title: t.string,
  /**
   * Short description of the rule regarding what it represents.
   */
  description: t.string,
  /**
   * If the rule should be mounted by default when the explorer is loaded.
   */
  default: t.boolean,
  /**
   * Query and related inputs for the rule.
   */
  query_template: t.interface({
    /**
     * Inputs that this rule accepts
     */
    inputs: t.array(queryUiInputModel.base),
    /**
     * Query used in the elastic search
     * @example
     *  {
     *    "terms": {
     *       "industry.intricately_name": "industry_names"
     *    }
     *  }
     */
    elasticsearch_template: t.record(t.string, t.any)
  }),
  ...pick(timestampSchema, ['created_at', 'updated_at'])
};

export const RuleRelationshipsSchema = {
  category: t.interface(
    requiredJsonApiRelationshipSchema(ResourceType.explorer_rule_category)
  )
};

export const RuleSchema = jsonApiResource({
  type: ResourceType.explorer_rule,
  attributes: t.interface(RuleAttributes),
  relationships: t.interface(RuleRelationshipsSchema),
  meta: t.partial({
    match: t.boolean
  })
});

export const RuleModel = new Model(t.interface(RuleSchema));

export type Rule = ModelInterface<typeof RuleModel>;
