import * as t from 'io-ts';
import Model from '../../classes/model';
import { rulesetAttributesSchema } from '../ruleset/ruleset';

// Cannot use pick helper here because of the circularity :/
type SharedRuleSetAttributes = Pick<
  typeof rulesetAttributesSchema,
  'title' | 'description'
>;

// This is done to keep the jsDocs and reference intact
const shared: SharedRuleSetAttributes = {
  title: t.string,
  description: t.string
};

export const ruleSetTemplateSchema = {
  ...shared,
  userId: t.string,
  rulesetId: t.string
};

export const ruleSetTemplateModel = new Model(
  t.interface(ruleSetTemplateSchema)
);
