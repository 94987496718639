import { RulesetModel } from '../../../API/models/ruleset/ruleset';
import { publicJsonApiUserModel } from '../../../API/models/user/user';
import { ModelInterface } from '../../../API/models/interfaceGetters/modelInterface';
import { RulesetResource } from '../../../API/clients/explorer/explorer.client';

export const rulesetsState = {
  /**
   * Ruleset list that the user has access to.
   */
  rulesets: [] as ModelInterface<typeof RulesetModel>[],
  /**
   * List of users that are owners of rulesets
   */
  owners: [] as ModelInterface<typeof publicJsonApiUserModel>[],
  /**
   * Rulesets related to the current report
   */
  reportRulesets: [] as ModelInterface<typeof RulesetModel>[],
  /**
   * Users related to the current report ruleset.
   */
  reportRulesetsOwners: [] as ModelInterface<typeof publicJsonApiUserModel>[],
  ruleSetsMeta: {} as NonNullable<
    ModelInterface<typeof RulesetResource['get']['response']>['meta']
  >
};
