import { ActionTree } from 'vuex';
import { rulesetsState } from './state';
import { explorerApi } from '../../../API/clients/explorer/explorer.client';
import { ModelInterface } from '../../../API/models/interfaceGetters/modelInterface';
import { Ruleset } from '../../../API/models/ruleset/ruleset';
import { rulesetPostPayloadModel } from '../../../API/models/ruleset/ruleset.post';
import { ResourceType } from '../../../API/clients/resourceTypes.enum';
import { storeApi } from '../..';
import { getQ } from '../state/es/service';
import { IParsedQueryBlockGroup } from '../../../API/models/queryUI/queryBlockGroup';
import pick from '../../../utils/pick';
import { IQueryBlock } from '../../../API/models/queryUI/queryBlock';

type PostPayload = ModelInterface<typeof rulesetPostPayloadModel>;

const actions = <
  T extends ActionTree<typeof rulesetsState, Record<string, any>>
>(
  actionTree: T
) => actionTree;

export type RuleSetUpdateOptions = {
  id: string;
  attributes?: Partial<PostPayload['attributes']>;
} & {
  queryBlockGroup: IParsedQueryBlockGroup;
};

export type RulesetCreateOptions = {
  userId: string;
  queryBlockGroup: IParsedQueryBlockGroup;
} & Omit<PostPayload['attributes'], 'query_template'>;

export const rulesetsActions = actions({
  /**
   * Fetch the list of rulesets the user has access to.
   */
  async fetchRulesets(
    _,
    payload?: {
      search?: string;
      page?: number;
      pageSize?: number;
      user?: string;
    }
  ) {
    const res = await explorerApi.get('ruleset', {
      include: ['user'],
      pageSize: payload?.pageSize || 20,
      page: payload?.page || 1,
      sort: ['matching', 'title'],

      filters: {
        name: payload?.search || undefined,
        user_id: payload?.user ? [payload.user] : undefined,
        deleted: 'false'
      }
    });

    storeApi.rulesets.mutations.SET_RULESETS(res.data);

    if ('included' in res && res.included) {
      const owners = res.included.filter(
        (item) => item.type === ResourceType.user
      );
      storeApi.rulesets.mutations.SET_OWNERS(owners);
    }

    if (res.meta) {
      storeApi.rulesets.mutations.SET_RULESETS_META(res.meta);
    }

    return res.data;
  },
  /**
   * Fetch and store all rule sets related to the current report.
   */
  async fetchReportRulesets() {
    const { queryUi } = storeApi.state.es.state;

    const rulesetIds = new Set<string>();

    function getRulesetIds(target: IParsedQueryBlockGroup | IQueryBlock) {
      if ('template' in target && target.template) {
        rulesetIds.add(target.template.rulesetId);
      }

      if ('children' in target && target.children) {
        for (const child of target.children) {
          getRulesetIds(child);
        }
      }
    }

    getRulesetIds(queryUi);

    const res = await explorerApi.get('ruleset', {
      filters: {
        id: [...rulesetIds]
      },
      include: ['user']
    });

    const { mutations } = storeApi.rulesets;

    mutations.SET_REPORT_RULESETS(res.data);

    if ('included' in res && res.included) {
      mutations.SET_REPORT_RULESETS_OWNERS(res.included);
    }
  },
  /**
   * Update the attributes of an existing ruleset.
   */
  async updateRuleset(_ctx, options: RuleSetUpdateOptions) {
    let ruleset = storeApi.rulesets.getters.rulesetById(options.id);

    if (!ruleset) {
      const { data } = await explorerApi.get('ruleset', {
        filters: {
          id: [options.id]
        }
      });

      [ruleset] = data;

      if (!ruleset) throw new Error('Ruleset to update does not exist!');
    }

    const payload = {
      ...ruleset.attributes,
      ...options.attributes,
      query_template: options.queryBlockGroup
    };

    const { data } = await explorerApi.patch('ruleset', {
      ...ruleset,
      attributes: payload
    });

    storeApi.rulesets.mutations.SET_RULESET({
      ruleset: data,
      node: options.queryBlockGroup
    });

    await storeApi.state.es.actions.updateEsState();
    await storeApi.state.es.actions.saveState();
  },
  /**
   * Create a new ruleset and add it to the query ui.
   */
  async createRuleset(__ctx, options: RulesetCreateOptions): Promise<Ruleset> {
    if (!options.queryBlockGroup.identity) {
      throw new Error(
        'Cannot create rule set. Expected query block group to have identity'
      );
    }

    const attributes = {
      ...pick(options, ['description', 'title', 'visibility']),
      query_template: options.queryBlockGroup
    };

    const { data: ruleset } = await explorerApi.post('ruleset', {
      userId: options.userId,
      attributes
    });

    storeApi.rulesets.mutations.SET_RULESET({
      node: options.queryBlockGroup,
      ruleset
    });

    const esManager = getQ();
    esManager.update('query', {
      identity: options.queryBlockGroup.identity,
      config: {
        template: {
          ...pick(options, ['description', 'title', 'userId']),
          rulesetId: ruleset.id
        }
      }
    });

    await storeApi.state.es.actions.updateEsState();
    await storeApi.state.es.actions.saveState();

    return ruleset;
  },
  /**
   * Save a ruleset. Pass attributes for creating a new one or a ruleset to update it.
   */
  async saveRuleset(
    __ctx,
    options: RulesetCreateOptions | RuleSetUpdateOptions
  ) {
    const node = options.queryBlockGroup;
    const { actions, getters } = storeApi.rulesets;

    if ('userId' in options) {
      await actions.createRuleset(options);
      return;
    }

    if (node && 'template' in node && node.template) {
      const ruleset = getters.rulesetById(node.template.rulesetId);
      if (ruleset?.attributes.deleted_at) {
        const { userId } = node.template;
        delete node.template;
        await actions.createRuleset({
          userId,
          queryBlockGroup: node,
          ...ruleset.attributes
        });
        return;
      }
    }

    if ('id' in options) {
      await actions.updateRuleset(options);
    }
  },
  /**
   * Delete a ruleset of given ID and removes the related node from the queryUI
   */
  async deleteRuleset(_ctx, node: IParsedQueryBlockGroup) {
    if (!node.template) throw new Error('Expected node to have a template');
    if (!node.identity) throw new Error('Expected node to have an identity');

    const { data: ruleset } = await explorerApi.delete('ruleset', {
      id: node.template.rulesetId
    });

    storeApi.rulesets.mutations.SET_RULESET({
      ruleset,
      node
    });

    const esManager = getQ();
    esManager.update('query', {
      identity: node.identity,
      config: {
        // TODO: better way to unset template
        template: {
          description: '',
          title: '',
          userId: '',
          rulesetId: ''
        }
      }
    });

    await storeApi.state.es.actions.updateEsState();
    await storeApi.state.es.actions.saveState();
  }
});
