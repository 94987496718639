



































import TheMarketSegmenter from '../components/market-segmenter/TheMarketSegmenter.vue';
import TheMarketBuilder from '../components/market-builder/TheMarketBuilder.vue';
import UIFillRates from '../components/UIFillRates.vue';
import TheSegmentPreview from '../components/segment-preview/TheSegmentPreview.vue';
import TheMarketBuilderDebugger from '../components/market-builder-debugger/TheMarketBuilderDebugger.vue';
import Vue from 'vue';
import { storeApi } from '../store';
import Logger from '../logger/Logger.class';

const { prodError } = new Logger('TheMarketExplorerPageView');

const TheMarketExplorerPageView = Vue.extend({
  components: {
    TheMarketSegmenter,
    TheMarketBuilder,
    UIFillRates,
    TheSegmentPreview,
    TheMarketBuilderDebugger
  },
  data() {
    return {
      oldId: ''
    };
  },
  computed: {
    stateId(): string {
      return storeApi.state.getters.id;
    },
    hasMarket(): boolean {
      return storeApi.state.es.getters.hasMarket;
    }
  },
  watch: {
    stateId(id: string, oldId: string): void {
      const paramId = this.$route.params.id;

      if (id !== oldId && id.length > 0 && id !== paramId) {
        this.$router.push({ name: 'report', params: { id } }).catch(prodError);
      }
    }
  },
  async created(): Promise<any> {
    const paramId = this.$route.params.id;

    if (paramId == null || paramId.length === 0) {
      // `$router.replace` so there's no easy going back to the presumed bad URL
      this.$router.replace({ name: 'home' }).catch(prodError);

      return;
    }

    // No need to set a new fetch because the id is the same
    if (paramId === this.oldId) {
      return;
    }

    // Cache the id so we can later know if this is changing
    this.oldId = paramId;

    storeApi.state.actions.setLoading({ value: true }).catch(prodError);

    return storeApi.state.actions
      .setLoading({ value: true })
      .then(() => storeApi.state.actions.updateId(paramId))
      .then(() => {
        // TODO: find a way to do this more safely without the any
        if (this.$route.params.dontFetch) {
          return null;
        }

        storeApi.state.actions.fetchState().catch(prodError);

        return null;
      })
      .then(() => {
        // We want to force to unfocus anything at this point because we fetch a new state
        // It makes no sense to have anything focused
        setTimeout(() => {
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }
        }, 1000);

        // We can now request the extras, we still do it async though
        // DEV: we set them here in favor of IN-5908
        this.hydrateAppSettings().catch(prodError);

        return null;
      })
      .catch(() => {
        this.$toast(
          "Oops! We couldn't find anything at that URL. Please try another",
          'error',
          {
            timeout: 10000
          }
        );

        storeApi.state.actions.reset().catch(prodError);

        // `$router.replace` so there's no easy going back to the presumed bad URL
        this.$router.replace({ name: 'home' }).catch(prodError);
      })
      .then(() => storeApi.state.actions.setLoading({ value: false }));
  },
  methods: {
    async hydrateAppSettings(): Promise<any> {
      const settingsAreReady = storeApi.appSettings.getters.settingsAreReady;

      // No need to go further if already in
      if (settingsAreReady) {
        return null;
      }

      const queries = storeApi.queryTemplates.getters.elasticsearchTemplates;

      return Promise.all([
        storeApi.appSettings.actions.fetchAppSettings(),
        storeApi.marketInputs.actions.fetchAllList(queries)
      ]).catch(prodError);
    }
  }
});

export default TheMarketExplorerPageView;
