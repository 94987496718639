import pick from './pick';

/**
 * Omit some keys from a object
 */
export function omit<T extends Record<string, any>, K extends keyof T>(
  from: T,
  keys: K[]
): Omit<T, K> {
  return pick(
    from,
    Object.keys(from).filter(
      (key) => !keys.includes(key as any)
    ) as (keyof Omit<T, K>)[]
  );
}
