/**
 * Safely applies parameters to a base url or path.
 */
export function applyParameters(base: string, newParams?: URLSearchParams) {
  const [path, oldParams] = base.split('?');
  const newParamsString = newParams?.toString();

  const params = [oldParams, newParamsString].join(
    oldParams && newParamsString ? '&' : ''
  );
  const normalizedPath = path.replace(/\/$/, '');

  return normalizedPath + (params ? '?' + params : '');
}
