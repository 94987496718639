import _ from 'lodash';
import * as Sentry from '@sentry/browser';
import Cookie from 'js-cookie';
import Vue from 'vue';
import Router from 'vue-router';
import TheMarketExplorerPage from '../pages/TheMarketExplorerPage.vue';
import TheMarketExplorerPageView from '../pages/TheMarketExplorerPageView.vue';
import TheMarketExplorerDownloadView from '../pages/TheMarketExplorerDownloadView.vue';
import TheLoginPage from '../pages/TheLoginPage.vue';
import { storeApi } from '../store/index';
import { isQInitialized } from '../store/modules/state/es/service';
import Logger from '../logger/Logger.class';

Vue.use(Router);

const { prodError } = new Logger('Router');

/**
 * Sets the redirect for the login
 */
export const redirectToDashLogin = () => {
  (window as any).location =
    process.env.DASH_URL || 'https://my.intricately.com/login';
};

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: TheMarketExplorerPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/report/:id',
      name: 'report',
      component: TheMarketExplorerPageView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: TheLoginPage,
      meta: {
        requiresNonAuth: true
      }
    },
    {
      path: '/downloads/:reportId',
      name: 'download',
      component: TheMarketExplorerDownloadView,
      meta: {
        requiresAuth: true
      }
    }
  ]
});

interface IParsedSession {
  user_id?: string;
  token?: string;
}

router.beforeEach(async (destination, _origin, next) => {
  const session = Cookie.get('session');

  if (session) {
    const sessionObject: IParsedSession = JSON.parse(session);
    const userId = sessionObject.user_id || '';

    try {
      await storeApi.appSettings.actions.setAuthUserId(userId);
      const user = await storeApi.users.actions.fetchUser({ userId });
      await storeApi.appSettings.actions.setAuthUser(user);
      /**
       * Set Sentry user with current user id.
       */
      Sentry.configureScope((scope) => {
        scope.setUser({ id: userId });
      });
    } catch (e) {
      prodError(e);
    }
  }

  if (destination.meta?.requiresAuth) {
    if (session) return next();
    return redirectToDashLogin();
  }

  if (destination.meta?.requiresNonAuth) {
    return next(session ? '/' : '');
  }

  return next();
});

router.beforeResolve(async (destination, origin, next) => {
  if (destination.meta?.requiresAuth) {
    if (destination.name) {
      storeApi.appSettings.actions.isLoading().catch(prodError);
    }

    if (isQInitialized()) {
      next();
    } else {
      try {
        const [queries, features] = await Promise.all([
          storeApi.queryTemplates.actions.fetchTemplates(),
          storeApi.featureSegments.actions.fetchFeatures(),
          storeApi.rulesets.actions.fetchRulesets()
        ]);

        storeApi.state.es.actions
          .initialize({
            queries,
            features
          })
          .catch(prodError);

        return next();
      } catch (e) {
        prodError(e);
        redirectToDashLogin();
      }
    }
  } else {
    redirectToDashLogin();
  }
});

router.afterEach(() => {
  storeApi.appSettings.actions.isDoneLoading().catch(prodError);
});

export default router;
