import _ from 'lodash';

/**
 * Flattens data into one array
 * @param {*} data
 * @returns {*[]}
 */
export const flattenData = <T, C>(data: T) => {
  let newArr: C[] = [];

  if (_.isArray(data)) {
    const arr = (data as any) as any[];

    for (let i = 0; i < arr.length; i += 1) {
      newArr = newArr.concat(flattenData(arr[i]));
    }
  } else if (data != null) {
    newArr.push((data as any) as C);
  }

  return newArr;
};

/**
 * Sorts object keys
 * @param {*} data
 * @returns {*}
 */
export const sortObjKeys = <T>(data: T): T => {
  if (data == null || typeof data !== 'object') {
    return data;
  }

  if (_.isArray(data)) {
    const arr = (data as any) as any[];
    return (arr.map((obj) => sortObjKeys(obj)) as any) as T;
  }

  const newObj: T = {} as any;

  const keys = Object.keys(data).sort((a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    newObj[key] = data[key];
  }

  return newObj;
};

/**
 * Deep clones data
 * @param {T} data
 * @returns {T}
 */
export const deepClone = <T>(data: T): T => {
  if (data == null) {
    return data;
  }
  if (typeof data !== 'object') {
    return data;
  }

  if (_.isArray(data)) {
    const arr = (data as any) as any[];
    return (arr.map((single) => deepClone(single)) as any) as T;
  }

  const obj: T = {} as any;
  const keys = Object.keys(data);

  // It must be a general object
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    obj[key] = deepClone(data[key]);
  }

  return obj;
};

/**
 * Deep diff between two object, using "stringify"
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {boolean}       Return if an object is different from another
 */
export const deepEqualByString = <T, C>(object: T, base: C): boolean => {
  if (object == null && base == null) {
    return true;
  }

  if (object == null && base != null) {
    return false;
  }

  if (object != null && base == null) {
    return false;
  }

  const sorted1 = sortObjKeys(object);
  const sorted2 = sortObjKeys(base);

  const string1 = JSON.stringify(sorted1);
  const string2 = JSON.stringify(sorted2);

  const obj1Str = (string1 == null ? '' : string1).replace(/ /g, '');
  const obj2Str = (string2 == null ? '' : string2).replace(/ /g, '');

  return obj1Str === obj2Str;
};

/**
 * Deep equal between two object
 * @param  {*} data1
 * @param  {*} data2
 * @param  {Boolean} bypassByString
 * @param  {Boolean} ignorePrivates
 * @param  {Boolean} bypassEmptyArrays
 * @param  {string[]} ignoreKeys
 * @return {Boolean}
 */
export const deepEqual = <T, C>(
  data1: T,
  data2: C,
  bypassByString = false,
  ignorePrivates = false,
  bypassEmptyArrays = false,
  ignoreKeys: string[] = []
): boolean => {
  // This is the hardest case, if this works, we should be fine
  if (!bypassByString && deepEqualByString(data1, data2)) {
    return true;
  }

  // Empty array vs null should be considered the same
  if (bypassEmptyArrays) {
    const arr1 = (data1 as any) as any[];
    const arr2 = (data2 as any) as any[];

    if (_.isArray(arr1) && arr1.length === 0 && arr2 == null) {
      return true;
    }
    if (_.isArray(arr2) && arr2.length === 0 && arr1 == null) {
      return true;
    }
  }

  const isData1Nulled =
    data1 == null || (typeof data1 === 'string' && data1 === '');
  const isData2Nulled =
    data2 == null || (typeof data2 === 'string' && data2 === '');

  // Check null values
  if (isData1Nulled === !isData2Nulled) {
    return false;
  }
  if (isData1Nulled && isData1Nulled === isData2Nulled) {
    return true;
  }

  // It must be a string / boolean / number then
  if (typeof data1 !== 'object') {
    return (data1 as any) === (data2 as any);
  }

  // This will make sure data don't have observables methods and so on
  const data1Parsed: T = JSON.parse(JSON.stringify(data1));
  const data2Parsed: C = JSON.parse(JSON.stringify(data2));

  if (_.isArray(data1Parsed)) {
    const arr1 = (data1Parsed as any) as any[];
    const arr2 = (data2Parsed as any) as any[];

    // Obj isn't an array or is different in length so we know it is different already
    if (!_.isArray(arr2) || arr1.length !== arr2.length) {
      return false;
    }

    const equalFound = arr1.filter((checkObj1) => {
      const found = arr2.filter((checkObj2) =>
        deepEqual(
          checkObj1,
          checkObj2,
          true,
          ignorePrivates,
          bypassEmptyArrays,
          ignoreKeys
        )
      );

      return found.length > 0;
    });

    return equalFound.length === arr1.length;
  }

  // Need to iterate both object keys because either might have new keys
  for (let c = 0; c < 2; c += 1) {
    const objA = c === 0 ? data1Parsed : data2Parsed;
    const objB = c === 0 ? data2Parsed : data1Parsed;
    const keys = Object.keys(objA);

    // Iterate per the data1
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];

      // Ignore keys that are meant to be private
      if (ignorePrivates && key[0] === '_') {
        continue;
      }

      // Ignore keys
      const foundIgnoreKey =
        ignoreKeys.filter((ignoreKey) => ignoreKey === key).length > 0;
      if (foundIgnoreKey) {
        continue;
      }

      // Check if the values are the same now
      const value1 = objA[key];
      const value2 = objB[key];
      const isEqual = deepEqual(
        value1,
        value2,
        true,
        ignorePrivates,
        bypassEmptyArrays,
        ignoreKeys
      );

      if (!isEqual) {
        return false;
      }
    }
  }

  // No return of false before? all must be good
  return true;
};
