/**
 * Simple number formatting
 * Assuming that thousand separator is comma
 * and decimal separator is point
 * @param {Number} number
 * @returns {String}
 */
export default function(number?: number) {
  if (typeof number === 'number') {
    const stringified = number.toString();
    if (stringified.indexOf('.') > -1) {
      const parts = stringified.split('.');
      const [left] = parts;
      const formattedLeft = left
        .split('')
        .reverse()
        .reduce((memo, current, index) => {
          if (index && index % 3 === 0) {
            memo = memo.concat(',', current);
          } else {
            memo = memo.concat(current);
          }
          return memo;
        }, [] as string[])
        .reverse()
        .join('');
      return `${formattedLeft}.${parts[1]}`;
    }

    return stringified
      .split('')
      .reverse()
      .reduce((memo, current, index) => {
        if (index && index % 3 === 0) {
          memo = memo.concat(',');
        }
        memo = memo.concat(current);
        return memo;
      }, [] as string[])
      .reverse()
      .join('');
  }

  return '';
}
