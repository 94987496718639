











import Vue from 'vue';

const UIBarLoader = Vue.extend({
  props: {
    size: {
      type: Number,
      default: 20
    }
  },
  computed: {
    style() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`
      };
    }
  }
});

export default UIBarLoader;
