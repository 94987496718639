import { keys, includes, isArray, isNil, isPlainObject } from 'lodash';

const stringifiedPath = (
  object: { [key: string]: any },
  key: string,
  prev?: any,
  index: any = null
): string => {
  if (isArray(object)) {
    const themAll = object.map((o, i) => stringifiedPath(o, key, prev, i));
    return themAll.filter(Boolean)[0];
  }
  if (isPlainObject(object)) {
    const k = keys(object);
    if (includes(k, key)) {
      if (prev) {
        if (!isNil(index)) {
          return `[${prev}][${index}][${key}]`;
        }
        return `${prev}["${key}"]`;
      }
      return key;
    }

    // eslint-disable-next-line
    const themAll = k.map((tk) =>
      stringifiedPath(
        object[tk],
        key,
        prev
          ? isNil(index)
            ? `${prev}["${tk}"]`
            : `${prev}[${index}]["${tk}"]`
          : tk
      )
    );
    return themAll.filter(Boolean)[0];
  }
  return '';
};

export default stringifiedPath;
