/**
 * Clean up decimal
 * @param {Number} num
 * @returns {String}
 */
function cleanUpDecimal(num = 0) {
  const parsed = parseFloat(`${num}`)
    .toFixed(2)
    .toString();
  const split = parsed.split('.');
  const noZeroes = split[1].replace(/0/g, '');

  if (noZeroes.length === 0) {
    return split[0];
  }

  return parsed;
}

/**
 * Shorten number
 * @param {Number} number
 * @returns {String}
 */
const shorten = (number = 0) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(number) || number === null) {
    return '';
  }

  if (number >= 1000000000) {
    return `${cleanUpDecimal(number / 1000000000)}B`;
  }

  if (number >= 1000000) {
    return `${cleanUpDecimal(number / 1000000)}M`;
  }

  if (number >= 1000) {
    return `${cleanUpDecimal(number / 1000)}K`;
  }

  return number.toString();
};

export default shorten;
