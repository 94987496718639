import * as t from 'io-ts';
import { reporter } from 'io-ts-reporters';
import { isRight } from 'fp-ts/lib/Either';
import Logger from '../../logger/Logger.class';

const { prodError } = new Logger('Model');

/**
 * Simple io-ts model with a validator. This is used to safeguard against API changes and to provide a easy solution for type checking it.
 *
 * @example
 * import * as t from 'io-ts'
 *
 * new Model(t.interface({
 *    name: t.string()
 * }))
 *
 */
export default class Model<T extends t.Any = any> {
  public constructor(public base: T) {}

  /**
   * Validates the model. Throws error if failed.
   */
  public validate(target: any) {
    const result = this.base.decode(target);
    const isValid = isRight(result as any);
    const validationErrors = reporter(result);
    const throwValidationErrors =
      process.env.THROW_VALIDATION_ERRORS === 'true' &&
      process.env.NODE_ENV !== 'production';

    if (!isValid) {
      for (const error of validationErrors) {
        if (process.env.LOG_VALIDATION_ERRORS === 'true') {
          prodError(error);
        }
        if (throwValidationErrors) {
          throw new Error(error + ' at: \n' + JSON.stringify(target, null, 2));
        }
      }
    }

    return isValid;
  }
}
