import { set, get as getLodash, isArray } from 'lodash';
import {
  isGteInput,
  isLtInput,
  isTermInput,
  isTermsInput,
  getInputValue,
  getInputValidityFn,
  getInputValuePath,
  getInputValueArr
} from './input-utils';
import { deepClone } from '../../../utils/object';
import { Input, QueryEsTemplate, QueryRawTree } from './types';
import {
  IOldRule,
  IParsedOldRule
} from '../../modules/query-templates/actions';
import { IQueryBlock } from '../../../API/models/queryUI/queryBlock';

export default class QueryBlock {
  private myIdentity = '';
  private myQuery: {
    esTemplate: QueryEsTemplate;
    label: string;
    description: string;
    id: string;
    inputs: Input[];
    advertisement?: boolean;
  } = {
    id: '',
    esTemplate: {},
    label: '',
    description: '',
    inputs: []
  };
  private myInputs: Input[] = [];
  private myEsTemplate: QueryEsTemplate = {};
  private isFromBucket = false;
  private bucketKey: string | number | undefined;

  /**
   * Initializes the query block
   */
  public constructor(initTmpl?: IOldRule, initStateQuery?: IQueryBlock) {
    this.init(initTmpl, initStateQuery);
  }

  /**
   * Initializes the query block
   */
  private init(initTmpl?: Partial<IOldRule>, initStateQuery?: IQueryBlock) {
    const baseTmpl: Partial<IParsedOldRule> = initTmpl || { id: '' };

    this.myIdentity = `_${Math.random()
      .toString(36)
      .substr(2, 9)}`;
    this.myQuery = {
      esTemplate: baseTmpl.elasticsearch_template || {},
      label: baseTmpl.label || '',
      description: baseTmpl.description || '',
      id: baseTmpl.id || '',
      advertisement: baseTmpl.advertisement || false,
      inputs: baseTmpl.inputs || []
    };
    this.myEsTemplate = baseTmpl.elasticsearch_template || {};
    this.myInputs = this.convertInputData(
      baseTmpl.inputs,
      this.myEsTemplate,
      initStateQuery
    );
    // It will be used on the layout and to filter when saving state
    this.isFromBucket =
      initStateQuery == null ? false : (initStateQuery.isFromBucket as boolean);
    this.bucketKey =
      initStateQuery == null ? undefined : (initStateQuery.bucketKey as string);
  }

  /**
   * Converts an input data to something the query block can use
   * Also retrieves the value from the state query
   */
  private convertInputData(
    inputs: Input[] = [],
    template: QueryEsTemplate = {},
    stateQuery?: IQueryBlock
  ): Input[] {
    if (!inputs.length) {
      return [];
    }

    if (!stateQuery || !Object.keys(stateQuery).length) {
      return inputs.map((input) => ({
        ...input,
        query_block_key:
          input.query_block_key == null
            ? input.template_key
            : input.query_block_key,
        value: input.value || input.default || null
      }));
    }

    // Lets try and retrieve data from data
    return inputs.map((input) => {
      const validityFn = getInputValidityFn(input);
      const path = getInputValuePath(input, template);

      // Retrieve the right input value
      const inputKey =
        input.query_block_key == null
          ? input.template_key
          : input.query_block_key;
      const newInput = {
        ...input,
        query_block_key: inputKey,
        value: input.default != null ? input.default : null
      };

      if (path != null && path.length > 0 && stateQuery.data != null) {
        const newValue = getLodash(stateQuery.data, path, null);

        if (newValue != null || newValue === 0) {
          // Check if input is valid, otherwise remove the value
          if (!validityFn(newInput)) {
            newInput.value = newValue;
          }
        }
      }

      // Now that we set the query inputs as per template
      // We also want to check the query state for values
      if (stateQuery.inputs != null && stateQuery.inputs.length > 0) {
        const stateInputs = stateQuery.inputs.filter((sInput: Input) => {
          const keyA =
            sInput.query_block_key == null
              ? sInput.template_key
              : sInput.query_block_key;
          return keyA === newInput.query_block_key;
        });

        if (stateInputs[0] != null && stateInputs[0].value != null) {
          newInput.value = stateInputs[0].value;
        }
      }

      return newInput;
    });
  }

  /**
   * Destroys from bucket only
   * @returns {boolean} true in case it has been destroyed
   */
  private destroyFromBucket(): boolean {
    if (!this.isFromBucket) {
      return false;
    }

    this.myIdentity = '';
    this.myQuery = {
      id: '',
      esTemplate: {},
      label: '',
      description: '',
      inputs: []
    };
    this.myInputs = [];
    this.myEsTemplate = {};

    return true;
  }

  /**
   * Destroys an identity
   * @param {string} identity
   * @param {{ onlyFromBuckets?: boolean }} params
   * @returns {boolean} true in case it has been destroyed
   */
  public destroy(
    identity = '',
    params: {
      onlyFromBuckets?: boolean;
    } = {}
  ): boolean {
    if (params.onlyFromBuckets) {
      return this.destroyFromBucket();
    }

    const hasIdentity = identity != null && identity.length > 0;
    const isSelf = !hasIdentity || this.myIdentity === identity;

    // We need to make sure the identity is the same
    if (!isSelf) {
      return false;
    }

    this.myIdentity = '';
    this.myQuery = {
      id: '',
      esTemplate: {},
      label: '',
      description: '',
      inputs: []
    };
    this.myInputs = [];
    this.myEsTemplate = {};

    return true;
  }

  /**
   * Updates the query block
   * @param {QueryTemplate?} candidate
   * @param {{ replace?: boolean; removeIsFromBucket?: boolean; }} params
   * @returns {QueryBlock}
   */
  public update(
    candidate: Partial<IOldRule> | null | undefined,
    params: {
      query?: Partial<IOldRule>;
      replace?: boolean;
      removeIsFromBucket?: boolean;
      bucketKey?: string | number;
    } = {}
  ): IQueryBlock | QueryBlock {
    const newQuery = candidate != null ? candidate : params.query;

    if (params.removeIsFromBucket) {
      this.isFromBucket = false;
      this.bucketKey = undefined;
      return this;
    }

    const paramsKeys = Object.keys(params == null ? {} : params).filter(
      (k) => k !== 'bucketKey'
    );
    if ((paramsKeys.length === 0 || params.replace) && newQuery != null) {
      this.init(newQuery), { bucketKey: params.bucketKey };
      return this;
    }

    if (params.bucketKey != null) {
      this.bucketKey = params.bucketKey;
    }

    if (!params.replace && newQuery != null && newQuery.inputs != null) {
      this.myInputs = newQuery.inputs;
    }

    return this;
  }

  /**
   * Retrieves the block in "raw" format. A format that backend understands
   */
  private getRaw(
    params: {
      isntFromBuckets?: boolean;
    } = {}
  ): QueryRawTree | null {
    // We don't want to save when the query is from bucket
    if (this.isFromBucket && params.isntFromBuckets) {
      return null;
    }

    const newEsTemplate = deepClone(this.myQuery.esTemplate);
    let dontHaveRequiredInput = false;

    // Iterate each input and convert it
    for (
      let i = 0;
      i < (this.myInputs == null ? [] : this.myInputs).length;
      i += 1
    ) {
      const input = this.myInputs[i];

      const { required } = input;
      const valueToCheck = getInputValueArr(input);
      let path = getInputValuePath(input, this.myEsTemplate);

      // WORKAROUND to make dynamic traffic distribution filters work
      if (path.includes('traffic_distribution.(:region_code)')) {
        const regionCodeInput = this.myInputs.find(
          (input) => input.query_block_key == 'traffic_distribution_region_code'
        );

        if (regionCodeInput) {
          const regionCodeValue = getInputValueArr(regionCodeInput) as string;

          path = path.replace('(:region_code)', regionCodeValue?.toLowerCase());
          delete newEsTemplate.range['traffic_distribution.(:region_code)'];
        }
      }

      if (path.includes('traffic_distribution_countries.(:country_code)')) {
        const countryCodeInput = this.myInputs.find(
          (input) =>
            input.query_block_key == 'traffic_distribution_country_code'
        );

        if (countryCodeInput) {
          const countryCodeValue = getInputValueArr(countryCodeInput) as string;

          path = path.replace(
            '(:country_code)',
            countryCodeValue?.toUpperCase()
          );
          delete newEsTemplate.range[
            'traffic_distribution_countries.(:country_code)'
          ];
        }
      }
      // END OF WORKAROUND

      if (
        required &&
        (valueToCheck == null ||
          (isArray(valueToCheck) && (valueToCheck as any[]).length === 0) ||
          (typeof valueToCheck === 'string' && valueToCheck.length === 0))
      ) {
        dontHaveRequiredInput = true;
        break;
      }

      if (path && path.length > 0) {
        if (
          valueToCheck == null ||
          (isArray(valueToCheck) && (valueToCheck as any[]).length === 0) ||
          (typeof valueToCheck === 'string' && valueToCheck.length === 0)
        ) {
          set(newEsTemplate, path, undefined);
        } else {
          set(newEsTemplate, path, valueToCheck);
        }
      }
    }

    // Required input isn't set so lets ignore the query
    if (dontHaveRequiredInput) {
      return null;
    }

    return newEsTemplate;
  }

  /**
   * Retrieves the block in "state" format. A format that backend understands
   */
  private getState(): IQueryBlock | null {
    let dontHaveRequiredInput = false;

    // Check if there is a required input without data
    for (
      let i = 0;
      i < (this.myInputs == null ? [] : this.myInputs).length;
      i += 1
    ) {
      const { value: inputValue, required } = this.myInputs[i];
      const valueToCheck = getInputValue(inputValue);

      if (required && (valueToCheck == null || valueToCheck.length === 0)) {
        dontHaveRequiredInput = true;
        break;
      }
    }

    // Required input isn't set so lets ignore the query
    if (dontHaveRequiredInput) {
      return null;
    }

    const newInputs: Input[] = (this.myInputs == null ? [] : this.myInputs).map(
      (input) => {
        return {
          query_block_key: input.query_block_key,
          value: input.value
        };
      }
    );

    return {
      isFromBucket: this.isFromBucket,
      bucketKey: this.bucketKey,
      type: 'query-block',
      templateId: this.myQuery.id,
      inputs: newInputs
    };
  }

  /**
   * Gets the query block as per request type
   */
  public get(type?: '', params?: Record<string, any>): IQueryBlock;
  public get(
    type: 'raw',
    params: { isntFromBuckets?: boolean }
  ): QueryRawTree | null;
  public get(
    type: 'state',
    params: { isntFromBuckets?: boolean }
  ): IQueryBlock | null;
  public get(type: 'identity'): string;
  public get(
    type: 'raw' | 'state' | 'identity' | '' = '',
    params: {
      isntFromBuckets?: boolean;
    } = {}
  ) {
    if (type === 'raw') {
      return this.getRaw(params);
    }

    if (type === 'state') {
      return this.getState();
    }

    if (type === 'identity') {
      return this.myIdentity;
    }

    return {
      isFromBucket: this.isFromBucket,
      bucketKey: this.bucketKey,
      id: this.myQuery.id,
      label: this.myQuery.label,
      description: this.myQuery.description,
      advertisement: this.myQuery.advertisement,
      identity: this.myIdentity,
      inputs: this.myInputs || []
    };
  }

  /**
   * Checks if the query block is empty
   * @returns {boolean}
   */
  public isEmpty(): boolean {
    return (
      this.myIdentity === '' ||
      this.myQuery == null ||
      Object.keys(this.myQuery).length === 0
    );
  }

  /**
   * Checks if query block is valid
   */
  public isValid(): boolean {
    const inputs = this.myInputs;

    if (inputs == null || inputs.length === 0) {
      return true;
    }

    if (
      inputs.length <= 2 &&
      inputs.reduce(
        (memo, current) => memo && (isGteInput(current) || isLtInput(current)),
        true
      )
    ) {
      return inputs.reduce(
        // TODO: investigate later on this "any"
        (memo, current) => (memo || current.value != null) as any,
        false
      );
    }
    if (
      inputs.find((input: any) => isTermsInput(input)) &&
      !inputs.find((input: any) => isTermInput(input))
    ) {
      const theTermsInput = inputs.find((input: any) => isTermsInput(input));
      if (theTermsInput == null) {
        return false;
      }
      return isArray(theTermsInput.value) && theTermsInput.value.length > 0;
    }
    if (
      inputs.find((input: any) => isTermInput(input)) &&
      !inputs.find((input: any) => isTermsInput(input))
    ) {
      const theTermInput = inputs.find((input: any) => isTermInput(input));
      return theTermInput == null || Boolean(theTermInput.value);
    }
    if (
      inputs.find((input: any) => isTermInput(input)) &&
      inputs.find((input: any) => isTermsInput(input))
    ) {
      const theTermInput = inputs.find((input: any) => isTermInput(input));
      const theTermsInput = inputs.find((input: any) => isTermsInput(input));
      return (
        theTermInput != null &&
        theTermsInput != null &&
        isArray(theTermsInput.value) &&
        theTermsInput.value.length > 0 &&
        Boolean(theTermInput.value)
      );
    }

    return true;
  }
}
