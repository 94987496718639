import { render, staticRenderFns } from "./TheReportDownload.vue?vue&type=template&id=6204e83e&scoped=true&"
import script from "./TheReportDownload.vue?vue&type=script&lang=ts&"
export * from "./TheReportDownload.vue?vue&type=script&lang=ts&"
import style0 from "./TheReportDownload.vue?vue&type=style&index=0&id=6204e83e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6204e83e",
  null
  
)

export default component.exports