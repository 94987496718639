















import Vue from 'vue';
import { TheEditButton, UIIconTooltip } from '@intricately/ui-lib';

const TheRulesetTooltip = Vue.extend({
  name: 'TheRulesetTooltip',
  components: {
    TheEditButton,
    UIIconTooltip
  },
  props: {
    description: {
      type: String,
      required: true
    },
    author: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
});

export default TheRulesetTooltip;
