import Vue from 'vue';

export const EventBus = new Vue();

export const GlobalEvents = {
  showAppBanner: 'show-app-banner',
  hideAppBanner: 'hide-app-banner',
  showStickyHeader: 'show-sticky-header',
  hideStickyHeader: 'hide-sticky-header'
};
